<template>
	<div>
		<edit v-if="$route.name == 'editPCBA'"/>
		<create v-if="$route.name == 'createPCBA'"/>

		<e-table title="PCBAs" singularTitle="PCBA" :data="$store.getters.pcbas" :filter="filter" :columns="columns" @delete="deletePCBA" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/PCBA/Edit'
import Create from '@/components/PCBA/Create'
import Table from '@/components/Common/Table'

export default {
	name: 'PCBAs',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table
	},
	data: () => ({
		sortBy: 'id',
		sortOrder: 'DESC',
		filter: {
			name: {
				value: null,
				name: 'Name',
				property: 'name'
			},
			pcb: {
				value: null,
				name: 'PCB',
				property: 'pcb.name'
			},
			tag: {
				value: [],
				name: 'Tag'
			}
		},
		columns: [
			{
				name: 'id',
				label: 'ID'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'pcb',
				label: 'PCB',
				render: (val) => (val? val.name : '')
			}, {
				name: 'hWConfiguration',
				label: 'HW Configuration',
				render: (val) => (val? val.name : '')
			}, {
				name: 'description',
				label: 'Description'
			}, {
				name: 'tags',
				label: 'Tags',
				sortable: false
			}
		]
	}),
	async created() {
		try {
			await this.$store.dispatch('loadPCBAs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadPCBs')
			await this.$store.dispatch('loadHWConfigurations')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deletePCBA(id) {
			try {
				await this.$apiService.pcba.delete(id)
				await this.$store.dispatch('loadPCBAs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
