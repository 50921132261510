<template>
	<md-table v-model="filteredData" :md-sort.sync="sortBy" :md-sort-order.sync="sortOrder" :md-sort-fn="tableSort">
		<md-table-toolbar v-if="filter || title">
			<div class="md-toolbar-row">
				<div class="md-title">{{title}}</div>
				<div class="md-toolbar-section-end">
					<md-button v-for="action in tableActions" :key="action.icon" :class="'md-icon-button ' + action.class" @click="action.handler()"
					:disabled="(action.disabled? action.disabled(): false)">
						<md-icon>{{action.icon}}</md-icon>
						<md-tooltip md-direction="top">{{action.tooltip}}</md-tooltip>
					</md-button>
				</div>
				<div v-if="!readonly && currentUser.roles.includes('admin')" class="md-toolbar-section-end">
					<md-button @click="showCreateDialog" class="md-icon-button md-primary">
						<md-icon>add</md-icon>
					</md-button>
				</div>
			</div>
			<table-filter v-if="filter" :data="data" :filter="filter" @applyFilter="applyFilter"/>
		</md-table-toolbar>

		<md-table-empty-state
			:md-label="`No ${title} found`"
			:md-description="`Try a different search term or create a new ${singularTitle}.`">
			<md-button v-if="!readonly && currentUser.roles.includes('admin')" class="md-primary md-raised" @click="showCreateDialog">Create new {{singularTitle}}</md-button>
		</md-table-empty-state>

		<md-table-row slot="md-table-row" slot-scope="{ item }">
			<md-table-cell v-for="col in columns" :key="col.label" :md-label="col.label" :md-sort-by="columnSortable(col)" :md-numeric="col.name === 'id'">
				<div v-if="col.name == 'tags'">
					<md-chip v-for="tag in item.tags" :key="`t${tag.id}`" v-bind:class="{'md-primary': filter.tag.value.includes(tag.tag)}" >{{tag.tag}}</md-chip>
				</div>
				<div v-else-if="col.customTags">
					<md-chip v-for="tag in col.render(item)" :key="`t${tag.id}`" v-bind:class="{'md-primary': filter.tag.value.includes(tag.tag)}" >{{tag.tag}}</md-chip>
				</div>
				<div v-else-if="col.name == 'commit'">
					<router-link :to="{name: 'commitdetails', params: { commit : item[col.name] }}">{{ formatCommit(item[col.name])}}</router-link>
				</div>
				<div v-else>
					<span v-if="col.render">
						{{ col.render(item[col.name], item) }}
					</span>
					<span v-else-if="col.renderHtml" v-html="col.renderHtml(item[col.name], item)"/>
					<span v-else>{{ item[col.name] }}</span>
				</div>
			</md-table-cell>
			<md-table-cell md-label="Actions" v-if="editable || deletable || customActions.length > 0" style="white-space: nowrap;">
				<md-button v-if="editable && currentUser.roles.includes('admin')" class="md-icon-button" @click="showEditDialog(item)">
					<md-icon>edit</md-icon>
					<md-tooltip md-direction="top">Edit</md-tooltip>
				</md-button>
				<md-button v-for="action in customActions" :key="action.icon" :class="'md-icon-button ' + action.class" @click="action.handler(item)"
				:disabled="(action.disabled? action.disabled(item): false)">
					<md-icon>{{action.icon}}</md-icon>
					<md-tooltip md-direction="top">{{action.tooltip}}</md-tooltip>
				</md-button>
				<md-button v-if="deletable && currentUser.roles.includes('admin')" class="md-icon-button md-accent" @click="$emit('delete', item.id)">
					<md-icon>delete</md-icon>
					<md-tooltip md-direction="top">Delete</md-tooltip>
				</md-button>
			</md-table-cell>
		</md-table-row>
	</md-table>
</template>

<script>
import TableFilter from './TableFilter.vue'
import { Version } from '@/lib/Version'
import { getLocalUser } from '@/lib/auth'
import formatCommit from '@/mixins/formatting'

export default {
	name: 'Table',
	components: { 'table-filter': TableFilter },
	mixins: [formatCommit],
	props: {
		title: String,
		singularTitle: String,
		readonly: {
			type: Boolean,
			default: false
		},
		editable: {
			type: Boolean,
			default: true
		},
		deletable: {
			type: Boolean,
			default: true
		},
		columns: Array,
		filter: Object,
		customActions: {
			type: Array,
			default: () => []
		},
		data: {
			type: Array
		},
		tableActions: {
			type: Array,
			default: () => []
		},
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'desc',
			filteredData: []
		}
	},
	created() {
		this.filteredData = this.data
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.filteredData = this.data
			}
		},
		sortBy(val) {
			this.$emit('setSortBy', val)
		},
		sortOrder(val) {
			this.$emit('setSortOrder', val.toUpperCase())
		}
	},
	computed: {
		currentUser() {
			return getLocalUser()
		}
	},
	methods: {
		tableSort(value) {
			return value.sort((a, b) => {
				if (this.sortBy === 'version') {
					if (this.sortOrder === 'desc') {
						return (new Version(a.version).greater(new Version(b.version))? -1 : 1)
					} else {
						return (new Version(a.version).greater(new Version(b.version))? 1 : -1)
					}
				}
				let ao
				let bo
				try {
					ao = this.sortBy.split('.').reduce((o, i) => o[i], a)
					bo = this.sortBy.split('.').reduce((o, i) => o[i], b)
				} catch (e) {
					return -1
				}

				if (this.sortOrder === 'desc') {
					if (typeof ao === 'number') {
						return bo - ao
					}
					return ao.localeCompare(bo)
				}

				if (typeof ao === 'number') {
					return ao - bo
				}
				return bo.localeCompare(ao)
			})
		},
		applyFilter(d) {
			this.filteredData = d
		},
		columnSortable(col) {
			if ('sortable' in col && col.sortable) {
				return col.sortable
			}

			if (!('sortable' in col)) {
				return col.name
			}

			if ('sortable' in col && !col.sortable) {
				return ''
			}

			return ''
		},
		showEditDialog(item) {
			if (this.$listeners && this.$listeners.showEditDialog) {
				this.$emit('showEditDialog', item)
				return
			}

			this.$router.push({ name: `edit${this.singularTitle.replace(' ', '')}`, params: { eid: item.id } })
		},
		showCreateDialog() {
			if (this.$listeners && this.$listeners.showCreateDialog) {
				this.$emit('showCreateDialog')
				return
			}

			this.$router.push({ name: `create${this.singularTitle.replace(' ', '')}` })
		}
	}
}
</script>

<style scoped>
	.md-title {
		font-size: 1.7rem;
	}
</style>
