<template>
	<div>
		<create v-if="$route.name == 'createNamespace'"/>
		<edit v-if="$route.name == 'editNamespace'"/>

		<e-table title="Namespaces" singularTitle="Namespace" :data="$store.getters.namespaces" :filter="filter" :columns="columns" @delete="deleteNamespace" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}" :customActions="customActions"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Create from '@/components/Namespace/Create.vue'
import Edit from '@/components/Namespace/Edit.vue'
import config from '@/../config'
import { getLocalUser } from '@/lib/auth'

export default {
	name: 'Namespaces',
	components: {
		'e-table': Table,
		'create': Create,
		'edit': Edit
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			apiUrl: config.API_URL,
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'repository',
					label: 'Repository'
				}
			],
			customActions: [{
				icon: 'data_object',
				tooltip: 'Download PUEP files',
				handler: (namespace) => {
					const token = getLocalUser().token
					window.location.href = `${this.apiUrl}/puep/manifestGenerator/by-user?namespace=${namespace.name}&access_token=${token}`
				}
			}]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadNamespaces', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteNamespace(id) {
			try {
				await this.$apiService.namespace.delete(id)
				await this.$store.dispatch('loadNamespaces', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>