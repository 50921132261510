<template>
	<div style="margin-left: 24px; margin-top: 16px">
		<span class="md-headline">Actions</span>
		<div style="margin: 0 auto; width: 65%">
			<md-card style="">
				<md-card-expand md-alignment="space-between">
					<md-card-actions md-alignment="space-between">
						<div>
							<span class="md-subheading">Trigger Tester Build</span>
						</div>

						<md-card-expand-trigger>
							<md-button class="md-icon-button">
								<md-icon>keyboard_arrow_down</md-icon>
							</md-button>
						</md-card-expand-trigger>
					</md-card-actions>

					<md-card-expand-content>
						<md-card-content>
							<div class="md-layout md-gutter">
								<div class="md-layout-item md-size-70">
									<md-autocomplete v-model="selectedRelease" :md-options="releaseOptions" :md-fuzzy-search="false" required>
										<label>Release</label>
										<template slot="md-autocomplete-item" slot-scope="{ item, term }">
											<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
										</template>
										<template slot="md-autocomplete-empty">
											Not found!
										</template>
									</md-autocomplete>
								</div>
								<div class="md-layout-item">
									<md-button style="margin-top: 20px" :disabled="!release" @click="triggerTesterBuild">Trigger</md-button>
								</div>
							</div>
						</md-card-content>
					</md-card-expand-content>
				</md-card-expand>
			</md-card>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Actions',
	data: function() {
		return {
			releaseOptions: [],
			selectedRelease: null,
			release: null
		}
	},
	watch: {
		selectedRelease(val) {
			if (!val) {
				this.release = null
				return
			}
			let match = val.match(/\(([^)]+)\)$/);
			if (!match || match.length < 2) {
				this.release = null
				return
			}
			let id = parseInt(match[1])
			if (id) {
				this.release = this.$store.getters.getReleaseById(id)
				return
			}
			this.release = null
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadReleases', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			this.releaseOptions = []
			for (let release of this.$store.getters.releases) {
				let code = ' '
				if (release.code) {
					code = ` (${release.code}) `
				}
				this.releaseOptions.push(`${release.skuTargetGroupsRaw.join(', ')} ${release.version}${code}(${release.id})`)
			}
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async triggerTesterBuild() {
			try {
				await this.$apiService.triggerTesterBuild(this.release)
				this.$snackbar.showMessage('Build triggered')
				this.release = null
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
<style scoped>
	.md-headline {
		font-size: 1.7rem;
	}
</style>
