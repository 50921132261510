<template>
	<md-card>
		<md-card-header>
			<h2 class="md-subheading">Commit notes</h2>
		</md-card-header>
		<md-progress-bar md-mode="indeterminate" v-if="saving" />

		<md-card-content>
			<div v-if="loadingCommitNotes">Loading</div>
			<div v-else>
				<md-tabs v-if="commitNotes">
					<md-tab id="tab-hanges" md-label="Changes">
						<div class="md-layout md-gutter" v-if="editing">
							<div class="md-layout-item">
								<p>Preview</p>
								<hr />
								<div v-html="markdown(changeNotes)" />
							</div>
							<div class="md-layout-item">
								<p>Edit (markdown syntax)</p>
								<hr />
								<md-field class="md-layout-item">
									<md-textarea v-model="changeNotes"></md-textarea>
								</md-field>
							</div>
						</div>
						<div v-else v-html="markdown(commitNotes.changeNotes)" />
					</md-tab>

					<md-tab id="tab-testNotes" md-label="Test Notes">
						<div class="md-layout md-gutter" v-if="editing">
							<div class="md-layout-item">
								<p>Preview</p>
								<hr />
								<div v-html="markdown(testNotes)" />
							</div>
							<div class="md-layout-item">
								<p>Edit (markdown syntax)</p>
								<hr />
								<md-field class="md-layout-item">
									<md-textarea v-model="testNotes"></md-textarea>
								</md-field>
							</div>
						</div>
						<div v-else v-html="markdown(commitNotes.testNotes)" />
					</md-tab>

					<md-tab id="tab-releaseNotes" md-label="Release Notes">
						<div class="md-layout md-gutter" v-if="editing">
							<div class="md-layout-item">
								<p>Preview</p>
								<hr />
								<div v-html="markdown(releaseNotes)" />
							</div>
							<div class="md-layout-item">
								<p>Edit (markdown syntax)</p>
								<hr />
								<md-field class="md-layout-item">
									<md-textarea v-model="releaseNotes"></md-textarea>
								</md-field>
							</div>
						</div>
						<div v-else v-html="markdown(commitNotes.releaseNotes)" />
					</md-tab>
				</md-tabs>
				<div v-else>No notes found</div>
			</div>
		</md-card-content>
		<md-card-actions v-if="allowedToEdit && commitNotes">
			<md-button v-if="!editing" @click="startEdit">Edit</md-button>
			<md-button v-else @click="cancelEdit">Cancel</md-button>
			<md-button v-if="editing" @click="saveCommitNotes" :disabled="clean || saving" class="md-primary">Save</md-button>
		</md-card-actions>
		<md-card-actions v-if="allowedToEdit && hasNoNotes">
			<md-button @click="createEmptyNotes">Create</md-button>
		</md-card-actions>
	</md-card>
</template>



<script>
import { getLocalUser } from '@/lib/auth'
import markdown from '@/mixins/formatting'

export default {
	name: 'CommitNotes',
	props: ['commit'],
	mixins: [markdown],
	data: function () {
		return {
			editing: false,
			saving: false,
			hasNoNotes: false,
			changeNotes: '',
			testNotes: '',
			releaseNotes: '',
		}
	},
	watch: {
		'this.$store.state.commitNotes': { handler: () => {} },
	},
	computed: {
		loadingCommitNotes() {
			if (this.hasNoNotes) {
				return false
			}
			return this.$store.getters.commitNotes.length === 0
		},
		commitNotes() {
			return this.$store.getters.commitNotesForCommit(this.commit)
		},
		allowedToEdit() {
			return getLocalUser().roles.includes('admin')
		},
		clean() {
			const n = this.commitNotes
			return this.changeNotes === (n.changeNotes ?? '') && this.testNotes === (n.testNotes ?? '') && this.releaseNotes === (n.releaseNotes ?? '')
		},
	},
	async created() {
		try {
			await this.$store.dispatch('getCommitNote', this.commit)
		} catch (e) {
			if (e['message'] && e['message'].toLowerCase().startsWith('not found')) {
				this.$snackbar.showMessage('No notes for this commit')
				this.hasNoNotes = true
			} else {
				this.$snackbar.showMessage(e)
			}
		}
	},
	methods: {
		startEdit() {
			console.log('Start edit')
			const note = this.commitNotes
			this.changeNotes = note.changeNotes ?? ''
			this.testNotes = note.testNotes ?? ''
			this.releaseNotes = note.releaseNotes ?? ''
			this.editing = true
		},
		cancelEdit() {
			this.editing = false
		},
		async createEmptyNotes() {
			this.saving = true
			const data = {
				commit: this.commit,
				changeNotes: '',
				testNotes: '',
				releaseNotes: '',
			}
			try {
				const updated = await this.$apiService.commitNotes.create(data)
				this.$store.commit('setCommitNotes', updated)
				this.hasNoNotes = false
				this.startEdit()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.saving = false
		},
		async saveCommitNotes() {
			this.saving = true
			const data = {
				changeNotes: this.changeNotes,
				testNotes: this.testNotes,
				releaseNotes: this.releaseNotes,
			}

			try {
				const updated = await this.$apiService.commitNotes.update(this.commit, data)
				this.editing = false
				this.$store.commit('setCommitNotes', updated)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.saving = false
		},
	},
}
</script>
