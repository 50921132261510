<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item to="namespaces">Namespaces</md-list-item>
				<md-list-item to="pipelineTrigger">Pipeline Trigger</md-list-item>
				<md-list-item to="lrdToken">Token</md-list-item>
				<md-list-item to="consumers">Consumers</md-list-item>
				<md-list-item to="programmingTypes">Programming Types</md-list-item>
				<md-list-item to="manifestFields">Manifest Fields</md-list-item>
				<md-list-item to="requests">Requests</md-list-item>
				<md-list-item to="requestFields">Request Fields</md-list-item>
				<md-list-item to="actions">Actions</md-list-item>
				<md-list-item to="artifact-receivers">Artifact Receivers</md-list-item>
			</md-list>
		</md-app-drawer>
		<md-app-content>
			<router-view />
		</md-app-content>
	</md-app>
</template>

<script>
import Toolbar from '@/components/Common/Toolbar.vue'
export default {
	name: 'SettingsHome',
	components: { toolbar: Toolbar }
}
</script>

<style scoped>
.hidden {
	visibility: hidden;
}
</style>