<template>
	<div style="width: 100%">
		<md-card v-for="(condition, i) of conditions" :key="condition.id" style="margin-bottom: 10px">
			<md-card-expand>
				<md-card-actions md-alignment="space-between">
					<md-card-expand-trigger>
						<md-button style="text-transform: none" @click.native="activeCondition = condition.id">Request: {{condition.request.name}}</md-button>
					</md-card-expand-trigger>

					<div>
						<md-switch v-model="condition.active" class="md-primary" @change="activateCondition(i, ...arguments)"></md-switch>
						<md-button class="md-icon-button md-accent" style="margin-top: 6px" @click="$emit('deleteCondition', i)">
							<md-icon>delete</md-icon>
							<md-tooltip md-direction="top">Delete</md-tooltip>
						</md-button>
					</div>
				</md-card-actions>

				<md-card-expand-content>
					<md-card-content>
						<editor v-if="condition.id === activeCondition" :requestId="condition.request.id" :condition="condition.condition" @setShowCondJsError="setShowCondJsError(condition.id, ...arguments)" @setCondition="setCondition(condition, ...arguments)"/>
						<div class="md-layout">
							<div class="md-layout-item md-size-85"/>
							<div class="md-layout-item">
								<md-button type="submit" class="md-primary" :disabled="showCondJsError[condition.id]" @click="$emit('saveCondition', condition, i)">Save</md-button>
							</div>
						</div>
					</md-card-content>
				</md-card-expand-content>
			</md-card-expand>
		</md-card>
		<md-card v-if="consumers.length > 0">
			<md-card-expand>
				<md-card-actions md-alignment="space-between">
					<div>
						<md-button style="text-transform: none" disabled>New condition</md-button>
					</div>

					<md-card-expand-trigger>
						<md-button ref="openNewCondition" class="md-icon-button">
							<md-icon>add</md-icon>
							<md-tooltip md-direction="top">Add condition</md-tooltip>
						</md-button>
					</md-card-expand-trigger>
				</md-card-actions>

				<md-card-expand-content>
					<md-card-content>
						<div class="md-layout">
							<div class="md-layout-item">
								<md-field>
									<label for="requestId">Request</label>
									<md-select v-model="newRequestId" name="requestId" id="requestId">
										<md-option v-for="i in requests" :key="i.id" :value="i.id">{{i.name}}</md-option>
									</md-select>
								</md-field>
							</div>
						</div>
						<editor :requestId="newRequestId" @setShowCondJsError="setNewShowCondJsError" @setCondition="setNewCondition"/>
						<div class="md-layout">
							<div class="md-layout-item md-size-85"/>
							<div class="md-layout-item">
								<md-button type="submit" class="md-primary" :disabled="showNewCondJsError || newRequestId  === null" @click="addCondition">Add</md-button>
							</div>
						</div>
					</md-card-content>
				</md-card-expand-content>
			</md-card-expand>
		</md-card>
	</div>
</template>

<script>
import Editor from '../Condition/Editor.vue'

export default {
	name: 'ConditionsTab',
	props: ['consumers', 'conditions'],
	components: { editor: Editor },
	data: function() {
		return {
			showCondJsError: {},
			showNewCondJsError: false,
			newRequestId: null,
			newCondition: '',
			activeCondition: ''
		}
	},
	computed: {
		requests() {
			const tmp = []
			this.consumers.forEach((c) => {
				if (!c.requests) {
					c.requests = this.$store.getters.requests.filter((r) => r.consumers.map((c) => c.id).includes(c.id))
				}
				c.requests.forEach((r) => {
					if (!tmp.map((t) => t.id).includes(r.id)) {
						tmp.push(r)
					}
				})
			})
			return tmp
		}
	},
	methods: {
		setShowCondJsError(id, val) {
			this.showCondJsError[id] = val
			this.showCondJsError = {...this.showCondJsError}
		},
		setCondition(condition, val) {
			condition.condition = val
		},
		setNewShowCondJsError(val) {
			this.showNewCondJsError = val
		},
		setNewCondition(val) {
			this.newCondition = val
		},
		addCondition() {
			this.$emit('addCondition', this.newRequestId, this.newCondition)
			this.newRequestId = null
			this.newCondition = ''
			const addNewConditionButton = this.$refs.openNewCondition.$el
			addNewConditionButton.click()
		},
		activateCondition(i, active) {
			this.$emit('activateCondition', i, active)
		}
	}
}
</script>
