<template>
	<div>
		<edit v-if="$route.name == 'editProgrammingType'"/>
		<e-table title="Programming Types" singularTitle="Programming Type" :data="$store.getters.programmingTypes" :filter="programmingType.filter" :columns="programmingType.columns"
		:customActions="programmingType.customActions" :readonly="true" :editable="true" :deletable="false" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Edit from '@/components/ProgrammingType/Edit.vue'

export default {
	name: 'ProgrammingType',
	components: {
		'e-table': Table,
		'edit': Edit
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			programmingType: {
				columns: [
					{
						name: 'id',
						label: 'ID'
					}, {
						name: 'name',
						label: 'Name'
					}, {
						name: 'description',
						label: 'Description'
					}, {
						name: 'manifestFields',
						label: 'Manifest Fields',
						render: (mFs) => mFs.map((mF) => mF.name).join(', ')
					}
				]
			}
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadProgrammingTypes', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadManifestFields')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	}
}
</script>