<template>
	<md-dialog :md-active="$route.name == 'editPCBA'" v-if="pcba" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label>PCB</label>
						<md-input name="name" id="name" v-model="pcba.pcb.name" readonly required/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="pcba.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="pcba.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label>HW Configuration</label>
						<md-select v-model="pcba.hWConfiguration.id" name="hWC" id="hWC">
							<md-option v-for="hW in $store.getters.hWConfigurations" :key="hW.id" :value="hW.id">{{hW.name}}</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field :class="getValidationClass('bom')">
						<label for="bom">BOM</label>
						<md-input name="bom" id="bom" v-model="pcba.bom" required />
						<span class="md-error" v-if="!$v.pcba.bom.required">BOM is required</span>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item md-size-80">
					<md-field>
						<label for="attachments">Attachments</label>
						<md-file id="attachmentInput" v-model="attachmentInput" multiple/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-button class="md-icon-button" style="margin-top: 10px" :disabled="attachmentInput === null" @click="addAttachments()">
						<md-icon>add</md-icon>
					</md-button>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item" v-for="attachment of attachments" :key="attachment.id">
					<table>
						<tr>
							<td><a :href="`${apiUrl}/attachment/${attachment.file}`" class="md-list-item-text">{{attachment.name}}</a></td>
							<td>
								<md-button class="md-icon-button md-accent" @click="deleteAttachment(attachment.id)">
									<md-icon>delete</md-icon>
								</md-button>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<md-chips v-model="pcba.tags" md-placeholder="Tags"></md-chips>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<dialog-link-table :links="pcba.links"/>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending || $v.$invalid">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'
import config from '@/../config'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		pcba: null,
		sending: false,
		attachments: [],
		attachmentInput: null,
		apiUrl: config.API_URL,
	}),
	mixins: [debounce, validationMixin],
	watch: {
		pcba: {
			deep: true,
			async handler() {
				await this.validatePCBA()
			}
		}
	},
	validations: {
		pcba: {
			bom: {
				required
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadPCBs')
		this.pcba = this.$store.getters.getPcbaById(this.$route.params.eid)
		this.pcba.tags = this.pcba.tags.map((tag) => tag.tag)
		if (!this.pcba.hWConfiguration) {
			this.pcba.hWConfiguration = { id: null }
		}
		this.validatePCBA = this.debounce(this.validatePCBA)
		this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcba', id: this.pcba.id })
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.pcba[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async updatePCBA() {
			try {
				const tagIds = await this.$apiService.createTagsIfNotExist(this.pcba.tags)
				await this.$apiService.pcba.update(this.pcba.id, {
					description: this.pcba.description,
					tagIds,
					hWConfigurationId: this.pcba.hWConfiguration.id,
					links: this.pcba.links,
					bom: this.pcba.bom
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validatePCBA() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updatePCBA()
			}
		},
		async deleteAttachment(id) {
			this.sending = true
			try {
				await this.$apiService.attachment.delete(id)
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcba', id: this.pcba.id })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async addAttachments() {
			this.sending = true
			try {
				let i
				for (i = 0; i < document.querySelector('#attachmentInput').files.length; i++) {
					const data = new FormData()
					data.append('name', document.querySelector('#attachmentInput').files[i].name)
					data.append('data', document.querySelector('#attachmentInput').files[i])
					await this.$apiService.attachment.createAttachment({ name: 'pcba', id: this.pcba.id }, data)
				}
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcba', id: this.pcba.id })
				this.attachmentInput = null
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async close() {
			try {
				await this.$store.dispatch('loadPCBAs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
