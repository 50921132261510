<template>
	<div>
		<h1 class="md-title">
			<div>Artifact Receivers</div>
		</h1>

		<md-tabs @md-changed="changeTab" :md-active-tab="activeTab">
			<md-tab v-for="track in tracks" :id="track" :md-label="track" :key="track">
				<md-table v-model="filteredData" :md-sort.sync="sortBy" :md-sort-order.sync="sortOrder">
					<md-table-empty-state :md-label="`No artifact receivers found`" :md-description="`Try a different track or create one.`"> </md-table-empty-state>

					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell key="name" md-label="Name" md-sort-by="name" @click="get(item)">
							<router-link :to="{ name: 'CommitForReceiversDetails', params: { receiver: item.name } }">
								{{ item.name }}
							</router-link>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</md-tab>
		</md-tabs>
	</div>
</template>


<script>
import { trackNames, Track } from '@/@types/database-enums'

export default {
	name: 'CommitForReceivers',
	data: function () {
		return {
			sortBy: 'name',
			sortOrder: 'asc',
			activeTab: Track.dev,
			filteredData: [],
			showCreate: false,
			showConfirmDelete: false,
			id: null,
			name: null,
			sending: false,
		}
	},
	computed: {
		receivers: function () {
			return this.$store.getters.artifactReceivers
		},
		tracks() {
			return trackNames
		},
	},
	watch: {
		receivers: {
			handler() {
				this.applyFilter()
			},
			deep: true,
		},
		activeTab: {
			handler() {
				this.applyFilter()
			},
		},
	},
	async created() {
		try {
			// TODO: fix pagination
			await this.$store.dispatch('loadArtifactReceivers', { all: true })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		changeTab(tab) {
			this.activeTab = tab
		},
		applyFilter() {
			this.filteredData = this.receivers.filter(x => x.track === this.activeTab)
		},

		async get(receiver) {
			try {
				const commits = await this.$apiService.commit.getForArtifactReceiver(receiver, { all: true })
				console.log(commits)
			} catch (e) {
				console.error(e)
			}
		},
	},
}
</script>
