import Fuse from 'fuse.js'
import { ITag } from "@/@types/interfaces"

function getNestedField(field: string, object: any): any {
	return field.split('.').reduce((prev, curr) => prev[curr], object)
}

function getTags(a: any): any {
	const tags = [...a.sWVariant.tags, ...a.hWConfiguration.tags, ...a.deliveryVariant.tags]
	return tags.reduce((prev, curr) => {
		if (!prev.map((t: any) => t.id).includes(curr.id)) {
			prev.push(curr)
		}
		return prev
	}, [])
}

export default class {
	static install(Vue: any, options?: any[]): void { //eslint-disable-line
		Vue.subStringSearch = function(field: string, pattern: string, data: any[]) {
			if (!pattern) {
				return data
			}

			return data.filter((s) => getNestedField(field, s).toLowerCase().includes(pattern.toLowerCase()))
		}

		Vue.searchByField = function(field: string, pattern: string, data: any[]) {
			if (!pattern) {
				return data
			}
			const options = {
				keys: [field]
			}
			const fues = new Fuse(data, options)
			data = fues.search(pattern).map(i => i.item)
			return data
		}

		Vue.searchByTag = function(tags: string[], data: any[]) {
			return data.filter((d) => tags.every((t) => {
				let tags: ITag[] = d.tags
				if (!tags) {
					tags = getTags(d)
				}
				return tags.map((tag: ITag) => tag.tag).includes(t)
			}))
		}

		Vue.searchByDate = function(field: string, date: Date, data: any[]) {
			if (!date) {
				return data
			}
			return data.filter((s) => getNestedField(field, s).split('T')[0] === date)
		}

		Vue.formatDate = function (input: string) {
			const date = new Date(input)
			return date.toLocaleDateString()
		}
	}
}