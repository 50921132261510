<template>
	<div>
		<md-dialog :md-active="$route.name == 'artifactDetails'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
			<md-dialog-title>
				Details
			</md-dialog-title>
			<md-dialog-content>
				<div class="md-layout">
					<div class="md-layout-item">
						<vue-json-pretty :data="artifact.metadata"> </vue-json-pretty>
					</div>
				</div>
				<md-dialog-actions>
					<md-button class="md-primary" @click="close()">Close</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
	name: 'Details',
	components: { 'vue-json-pretty': VueJsonPretty },
	data: function() {
		return {
			artifact: null
		}
	},
	async created() {
		this.artifact = this.$store.getters.getArtifactById(this.$route.params.eid)
	},
	methods: {
		close() {
			this.$router.back()
		}
	}
}
</script>
