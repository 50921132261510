<template>
	<md-dialog :md-active="showHistory" :md-click-outside-to-close="true" @md-clicked-outside="close()">
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<ul>
						<li v-for="(item, idx) in historyToShow" :key="idx">{{ item }}</li>
					</ul>
				</div>
			</div>
		</md-dialog-content>
		<md-dialog-actions>
			<md-button class="md-primary" @click="close()">Close</md-button>
		</md-dialog-actions>
	</md-dialog>
</template>



<script>
import Vue from 'vue'

export default {
	name: 'TrackHistory',
	props: ['showHistory', 'commit'],

	data: function () {
		return {
			historyToShow: [],
		}
	},

	async created() {
		try {
			const history = await this.$apiService.commit.getTrackHistory(this.commit)
			this.historyToShow = history
				.split('\n')
				.filter(x => x.trim().length > 0)
				.map(x => {
					const i = x.indexOf('-', 24)
					const date = x.substring(0, i).trim()
					const change = x.substring(i + 2).trim()

					if (date && change) {
						const d = new Date(date)
						if (d) {
							return Vue.formatDate(d) + ' - ' + change
						}
					}

					return x
				})
		} catch (e) {
			this.$snackbar.showMessage(e)
			close()
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
}
</script>
