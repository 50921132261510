<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createHWConfiguration'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
								<span class="md-error" v-if="!$v.name.noSpaces">Spaces are not allowed</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label>Manifest fields</label>
								<md-select v-model="manifestFields" name="manifestFields" id="manifestFields" multiple>
									<md-option v-for="field in $store.getters.manifestFields" :key="field.id" :value="field.id">{{field.name}}</md-option>
								</md-select>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
						<md-chips v-model="tags" md-placeholder="Tags"></md-chips>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
						<dialog-link-table :links="links"/>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateHWConfiguration()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Create',
	mixins: [validationMixin],
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		name: null,
		description: null,
		tags: [],
		links: [],
		manifestFields: [],
		sending: false,
	}),
	validations: {
		name: {
			required,
			noSpaces: (val) => {
				if (val) {
					return !val.includes(' ')
				} else {
					return false
				}
			}
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.description = null
			this.tags = []
			this.links = []
			this.manifestFields = []
			this.sending = false
		},
		async createHWConfiguration() {
			this.sending = true

			try {
				const tagIds = await this.$apiService.createTagsIfNotExist(this.tags)
				await this.$apiService.hWConfiguration.create({
					name: this.name,
					description: this.description,
					tagIds,
					links: this.links,
					namespaceId: parseInt(this.$route.params.id),
					manifestFieldIds: this.manifestFields
				})
				this.$snackbar.showMessage('HW Configuration saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateHWConfiguration() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createHWConfiguration()
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			try {
				await this.$store.dispatch('loadHWConfigurations', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
