<template>
	<div>
		<md-dialog :md-active="['deleteRelease', 'deleteHWConfiguration', 'deleteSWVariant', 'deleteDeliveryVariant'].includes($route.name)" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('onCancel')" @keydown.esc="$emit('onCancel')">
			<md-dialog-title>Are you sure you want to delete "{{ itemName }}"?</md-dialog-title>
			<md-dialog-content>
				<div class="md-layout">
					<div class="md-layout-item">
						Type the name of the item to confirm that you want to delete it.
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item">
						<md-field :class="getValidationClass('itemNameInput')">
							<md-input name="itemNameInput" id="itemNameInput" placeholder="Item name" v-model="itemNameInput"/>
							<span class="md-error" v-if="!$v.itemNameInput.same">Wrong input!</span>
						</md-field>
					</div>
				</div>
				<md-dialog-actions>
					<md-button class="md-primary" @click="$emit('onCancel')">Cancel</md-button>
					<md-button class="md-primary md-accent" @click="confirm" >Confirm</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
export default {
	name: 'ConfirmDelete',
	mixins: [validationMixin],
	props: ['itemName'],
	data () {
		return {
			itemNameInput: null
		}
	},
	validations () {
		return {
			itemNameInput: {
				same: (value) => value === this.itemName
			}
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		confirm() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				this.$emit('onConfirm')
			}
		}
	}
}
</script>