<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item>
					<md-menu md-sync-route md-direction="bottom-start" style="width: 100%" md-align-trigger>
						<md-button md-menu-trigger style="width: 100%">{{currentNamespace}}
							<md-icon>arrow_drop_down</md-icon>
						</md-button>
						<md-menu-content md-sync-route style="width: 100%">
							<md-menu-item v-for="namespace in $store.getters.namespaces" :key="namespace.id" :id="namespace.id.toString()" :to="`/namespace/${namespace.id.toString()}/`" @click="setCurrentNamespace(namespace.id)">{{namespace.name}}</md-menu-item>
						</md-menu-content>
					</md-menu>
				</md-list-item>
			</md-list>
			<md-divider/>
			<md-list>
				<md-list-item to="artifacts">Artifacts</md-list-item>
			</md-list>
			<md-divider/>
			<md-list>
				<md-list-item to="pcbs">PCBs</md-list-item>
				<md-list-item to="pcbas">PCBAs</md-list-item>
				<md-list-item to="hWConfigurations">HW Configurations</md-list-item>
				<md-list-item to="sWVariants">SW Variants</md-list-item>
				<md-list-item to="deliveryVariants">Delivery Variants</md-list-item>
			</md-list>
			<md-divider/>
			<!-- <md-divider v-if="$store.getters.localUser.role === 'admin'"/>
			<md-list v-if="$store.getters.localUser.role === 'admin'">
				<md-list-item to="test">Test</md-list-item>
			</md-list> -->
		</md-app-drawer>
		<md-app-content>
			<router-view />
		</md-app-content>
	</md-app>
</template>

<script>
import Toolbar from '@/components/Common/Toolbar.vue'
export default {
	name: 'NamespaceHome',
	data: () => {
		return {
			currentNamespace: ''
		}
	},
	components: { toolbar: Toolbar },
	async created() {
		try {
			await this.$store.dispatch('loadNamespaces')
			this.setCurrentNamespace(this.$router.currentRoute.params.id)
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	watch:{
		async $route(to, from) {
			if (to.params.id !== from.params.id) {
				this.loadRessources()
			}
		}
	},
	methods: {
		async loadRessources() {
			try {
				await this.$store.dispatch('loadHWConfigurations')
				await this.$store.dispatch('loadPCBs')
				await this.$store.dispatch('loadPCBAs')
				await this.$store.dispatch('loadHWConfigurations')
				await this.$store.dispatch('loadSWVariants')
				await this.$store.dispatch('loadDeliveryVariants')
				await this.$store.dispatch('loadManifestFields')
				await this.$store.dispatch('loadArtifacts')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		setCurrentNamespace(id) {
			const namespace = this.$store.getters.getNamespaceById(parseInt(id))
			this.currentNamespace = namespace?.name
		}
	}

}
</script>