<template>
	<div>
		<show-token v-if="$route.name == 'showReleaseToken'" :token="createdToken" @onClose="$router.push({ name: 'releases' })"/>
		<create-token v-if="$route.name == 'createReleaseToken'" @create="createToken"/>
		<download v-if="$route.name == 'downloadRelease'"/>
		<confirm-delete v-if="$route.name == 'deleteRelease'" @onCancel="$router.back()" @onConfirm="deleteRelease" :itemName="deleteItemName" />
		<create v-if="$route.name == 'createRelease'" @createToken="createToken"/>
		<edit v-if="['editRelease', 'editReleaseByVersion'].includes($route.name)"/>
		<confirm-disable-conditions v-if="$route.name == 'disableConditionsForRelease'" @onConfirm="deactivateConditions" @onCancel="$router.back()"/>
		<e-table title="Releases" singularTitle="Release" :data="$store.getters.releases" :filter="filter" :columns="columns" :editable="false" @delete="confirmDelete" :customActions="customActions" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Create from '@/components/Release/Create/Create.vue'
import Edit from '@/components/Release/Edit/Edit.vue'
import Download from '@/components/Release/Download.vue'
import CreateToken from '@/components/Common/CreateToken.vue'
import Table from '@/components/Common/Table'
import ShowToken from '@/components/Common/ShowToken'
import ConfirmDisableConditions from '@/components/Common/ConfirmDisableConditions.vue'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'
import Vue from 'vue'
import { getLocalUser } from '@/lib/auth'

export default {
	name: 'Releases',
	components: {
		'e-table': Table,
		create: Create,
		edit: Edit,
		'confirm-disable-conditions': ConfirmDisableConditions,
		'confirm-delete': ConfirmDelete,
		'download': Download,
		'create-token': CreateToken,
		'show-token': ShowToken
	},
	data: function() {
		return {
			selectedRelease: null,
			sortBy: 'id',
			sortOrder: 'DESC',
			deleteItemName: null,
			filter:{
				code: {
					name: 'Code',
					property: 'code',
					value: null
				},
				version: {
					name: 'Version',
					property: 'version',
					value: null
				},
				skuGroups: {
					name: 'Sku Groups',
					property: 'id',
					value: null,
					fn: (data, val) => {
						if (!val || val === '') {
							return data
						}
						return data.filter((release) => {
							const groups = this.getSkuTargetGroups(release.id)
							return groups.find((g) => g.toLowerCase().includes(val.toLowerCase()))
						})
					}
				},
				tag: {
					name: 'Tag',
					value: []
				}
			},
			columns: [
				{
					name: 'id',
					label: 'ID',
					renderHtml: (val, item) => {
						if (item.notes && item.notes !== '') {
							return val
						}
						return `<div class="md-badge-content">${val}<div style="margin-right: -20px" class="md-badge md-theme-default md-position-top md-dense">!</div></div>`
					}
				}, {
					name: 'skuTargetGroupsRaw',
					label: 'Sku Groups',
					render: (val) => (val? val.join(', '): ''),
					sortable: false
				}, {
					name: 'version',
					label: 'Version'
				}, {
					name: 'code',
					label: 'Code'
				}, {
					name: 'notes',
					label: 'Notes',
					renderHtml: (val) => {
						let ret = []
						const lines = val.split('\n')
						for (let l of lines.slice(0, 3)) {
							ret.push(`<span>${l}</span>`)
						}
						if (lines.length > 3) {
							ret.push('<span>...</span>')
						}
						return ret.join('<br/>')
					}
				}, {
					name: 'created',
					label: 'Created',
					render: (val) => Vue.formatDate(val)
				}, {
					name: 'consumers',
					label: 'Consumers',
					render: (val) => val.map((c) => c.name).join(', ')
				}, {
					name: 'conditions',
					label: 'Active Condition',
					renderHtml: (val) => {
						if (val) {
							return (val.find((c) => c.active)? '<div class="md-button-content"><i class="md-icon md-icon-font md-theme-default">local_shipping</i></div>': '')
						} else {
							return ''
						}
					}
				}, {
					name: 'tags',
					label: 'Tags',
					sortable: false
				}
			],
			customActions: [{
				icon: 'visibility',
				tooltip: 'Show details',
				disabled: () => !getLocalUser().roles.includes('admin'),
				handler: async (release) => {
					this.$router.push({name: 'editRelease', params: {eid: release.id}})
				}
			}, {
				icon: 'do_not_disturb_on',
				class: 'md-accent',
				tooltip: 'Deactivate conditions',
				disabled: (item) => {
					if (item.conditions) {
						return !item.conditions.find((c) => c.active)
					} else {
						return true
					}
				},
				handler: async (release) => {
					this.$router.push({name: 'disableConditionsForRelease', params: {eid: release.id}})
				}
			}, {
				icon: 'download',
				tooltip: 'Download',
				handler: async (release) => {
					this.$router.push({name: 'downloadRelease', params: {eid: release.id}})
				}
			}, {
				icon: 'token',
				tooltip: 'Create token',
				handler: async (release) => {
					this.$router.push({name: 'createReleaseToken', params: {eid: release.id}})
				}
			}],
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadReleases', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadConditions')
			await this.$store.dispatch('loadRequests')
			await this.$store.dispatch('loadSkuGroups')
			await this.$store.dispatch('loadSkuGroupParents')
			await this.$store.dispatch('loadRequestFields')
			await this.$store.dispatch('loadProgrammingTypes')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deactivateConditions(release) {
			try {
				for (let c of release.conditions) {
					await this.$apiService.condition.activate(c.id, false)
				}
				await this.$store.dispatch('loadReleases', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		},
		async copyToClipboard(text) {
			await navigator.clipboard.writeText(text)
		},
		async confirmDelete(id) {
			const release = await this.$store.getters.getReleaseById(id)
			this.deleteItemName = `${release.code} ${release.version}`
			this.$router.push({name: 'deleteRelease', params: {eid: id}})
		},
		async deleteRelease() {
			try {
				await this.$apiService.release.delete(this.$route.params.eid)
				await this.$store.dispatch('loadReleases', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		},
		getSkuTargetGroups(id) {
			const release = this.$store.getters.getReleaseById(id)
			const skuGroups = release.skuGroups.map((g) => g.name)
			const skuGroupParents = release.skuGroupParents.map((g) => g.name)
			return [...skuGroups, ...skuGroupParents]
		},
		async createToken(release, data) {
			try {
				const token = await this.$apiService.release.createToken(release, data)
				this.createdToken = token
				this.$router.push({name: 'showReleaseToken'})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
