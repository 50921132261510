<template>
	<div>
		<h1 class="md-title">
			<div>Commits</div>
		</h1>

		<md-table v-model="filteredData" :md-sort.sync="sortBy" :md-sort-order.sync="sortOrder">
			<md-table-toolbar>
				<div class="md-toolbar-row">
					<span style="font-size: 17px; padding-top: 5px; margin-right: 20px">Filter:</span>
					<md-field md-clearable class="filterInput" autocomplete="off">
						<md-input :placeholder="`Namespace...`" v-model="filter.namespaceName" />
					</md-field>
					<md-field md-clearable class="filterInput" autocomplete="off">
						<md-input :placeholder="`Version...`" v-model="filter.version" />
					</md-field>
					<md-field class="filterInput" autocomplete="off">
						<md-select :placeholder="`Track...`" v-model="filter.track">
							<md-option value=""></md-option>
							<md-option :value="item" v-for="item in tracks" :key="item">{{ item }}</md-option>
						</md-select>
					</md-field>
				</div>
			</md-table-toolbar>

			<md-table-empty-state :md-label="`No commits found`" :md-description="`Try a different search term.`"> </md-table-empty-state>

			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell key="lastUpdate" md-label="Last Update" md-sort-by="lastUpdate">
					{{ formatDate(item.lastUpdate) }}
				</md-table-cell>
				<md-table-cell key="commit" md-label="Commit" md-sort-by="commit">
					<router-link :to="{ name: 'commitdetails', params: { commit: item.commit } }">{{ formatCommit(item.commit) }}</router-link>
				</md-table-cell>
				<md-table-cell key="namespace" md-label="Namespace" md-sort-by="namespaceName">
					<router-link :to="{ name: 'artifacts', params: { id: item.namespaceId } }" :title="item.namespaceId">{{ item.namespaceName }}</router-link>
				</md-table-cell>
				<md-table-cell key="version" md-label="Version" md-sort-by="version">
					{{ item.version }}
				</md-table-cell>
				<md-table-cell key="track" md-label="Track" md-sort-by="track">
					{{ item.track }}
				</md-table-cell>
				<md-table-cell key="artifactCount" md-label="Artifacts" md-sort-by="artifactCount" :md-numeric="true">
					{{ item.artifactCount }}
				</md-table-cell>
				<md-table-cell key="releaseForCount" md-label="Released for" md-sort-by="releaseForCount" :md-numeric="true">
					{{ (item.releaseForCount || 0) > 0 ? item.releaseForCount : '' }}
				</md-table-cell>
			</md-table-row>
		</md-table>
	</div>
</template>



<script>
import Vue from 'vue'
import { trackNames } from '@/@types/database-enums'
import formatCommit from '@/mixins/formatting'

export default {
	name: 'CommitOverview',
	mixins: [formatCommit],
	data: function () {
		return {
			sortBy: 'lastUpdate',
			sortOrder: 'desc',
			filter: {
				namespaceName: null,
				version: null,
			},
			filteredData: [],
		}
	},
	computed: {
		commitData: function () {
			return this.$store.getters.commitOverview
		},
		tracks() {
			return trackNames
		},
	},
	watch: {
		commitData: {
			handler() {
				this.applyFilter()
			},
			deep: true,
		},
		filter: {
			deep: true,
			handler() {
				this.applyFilter()
			},
		},
	},
	async created() {
		try {
			// TODO: fix pagination
			await this.$store.dispatch('loadCommitOverview', { all: true })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		applyFilter() {
			let data = this.commitData
			if (this.filter.namespaceName?.length > 0) {
				const n = this.filter.namespaceName.toLowerCase()
				data = data.filter(item => item.namespaceName.toLowerCase().includes(n))
			}
			if (this.filter.version?.length > 0) {
				const v = this.filter.version.toLowerCase()
				data = data.filter(item => item.version.toLowerCase().includes(v))
			}
			if (this.filter.track?.length > 0) {
				data = data.filter(item => item.track === this.filter.track)
			}

			this.filteredData = data
		},
		formatDate(d) {
			return Vue.formatDate(d)
		},
	},
}
</script>
