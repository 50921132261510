import { marked } from "marked"
export default {
	methods: {
		formatCommit(c) {
			if (c.length > 8) {
				return c.substring(0, 8)
			} else {
				return c
			}
		},
		markdown(raw) {
			return marked.parse(raw)
		}
	},
}
