<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1" style="padding-left: 32px">
			<a href="/namespace" class="md-headline"><span >LR Distribution</span></a>
		</md-app-toolbar>
		<md-app-content>
			<form novalidate>
				<md-card class="upload-card md-layout-item md-size-50 md-small-size-100">
					<md-card-header style="text-align: center">
						<div class="md-title">Upload Artifact</div>
						<!-- <span class="md-headline">Under construction!</span><br/>
						<span class="md-headline">Please use upload script from a pipeline.</span><br/>
						<span class="md-body">You can create yourself an access token for this (type 'Pipeline')</span> -->
					</md-card-header>
					<md-card-content style="text-align: center">
						<md-divider/>
						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field :class="getValidationClass('namespaceId')">
									<label for="namespace">Namespace</label>
									<md-select v-model="namespaceId" id="namespace" required>
										<md-option v-for="namespace in $store.getters.namespaces" :key="namespace.id" :value="namespace.id">{{namespace.name}}</md-option>
									</md-select>
									<span class="md-error" v-if="!$v.namespaceId.required">Namespace is required</span>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field :class="getValidationClass('hWConfigurationId')">
									<label for="hWConfiguration">HW Configuration</label>
									<md-select v-model="hWConfigurationId" id="hWConfiguration" :disabled="namespaceId === null" required>
										<md-option v-for="hWConfiguration in $store.getters.hWConfigurations.filter(h => h.namespace.id === namespaceId)" :key="hWConfiguration.id" :value="hWConfiguration.id">{{hWConfiguration.name}}</md-option>
									</md-select>
									<span class="md-error" v-if="!$v.hWConfigurationId.required">HW Configuration is required</span>
								</md-field>
							</div>
						</div>
						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field :class="getValidationClass('sWVariantId')">
									<label for="sWVariant">SW Variant</label>
									<md-select v-model="sWVariantId" id="sWVariant" :disabled="namespaceId === null" required>
										<md-option v-for="sWVariant in $store.getters.sWVariants.filter(s => s.namespace.id === namespaceId)" :key="sWVariant.id" :value="sWVariant.id">{{sWVariant.name}}</md-option>
									</md-select>
									<span class="md-error" v-if="!$v.sWVariantId.required">SW Variant is required</span>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field :class="getValidationClass('deliveryVariantId')">
									<label for="deliveryVariant">Delivery Variant</label>
									<md-select v-model="deliveryVariantId" id="deliveryVariant" :disabled="namespaceId === null" required>
										<md-option v-for="deliveryVariant in $store.getters.deliveryVariants.filter(d => d.namespace.id === namespaceId)" :key="deliveryVariant.id" :value="deliveryVariant.id">{{deliveryVariant.name}}</md-option>
									</md-select>
									<span class="md-error" v-if="!$v.deliveryVariantId.required">Delivery Variant is required</span>
								</md-field>
							</div>
						</div>
						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field :class="getValidationClass('version')">
									<label for="version">Version</label>
									<md-input name="version" id="version" v-model="version" required/>
									<span class="md-error" v-if="!$v.version.versionInvalid">Version is not valid</span>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field :class="getValidationClass('commit')">
									<label for="commit">Commit</label>
									<md-input name="commit" id="commit" v-model="commit" required/>
									<span class="md-error" v-if="!$v.commit.required">Commit is required</span>
								</md-field>
							</div>
						</div>
						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field :class="getValidationClass('file')">
									<label for="file">File</label>
									<md-file id="fileInput" v-model="file" @md-change="inputFile" required/>
									<span class="md-error" v-if="!$v.file.required">File is required</span>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="manifestFile">Manifest file</label>
									<md-file id="fileManifest" v-model="manifestFile" @md-change="inputManifest" accept="json/*" />
								</md-field>
							</div>
						</div>
						<md-divider/>
						<div class="md-headline" style="margin-top: 10px">Manifest data</div>
						<div v-for="(mFs, i) in manifestFieldsTuple" :key="i" class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field v-if="mFs[0].type !== 'boolean'">
									<label :for="mFs[0].name">{{mFs[0].name}}</label>
									<md-input :name="mFs[0].name" :id="mFs[0].name" v-model="manifest[mFs[0].name]" :required="!mFs[0].optional" :type="(mFs[0].type === 'number'? 'number': 'text')"/>
								</md-field>
								<md-checkbox v-if="mFs[0].type === 'boolean'" v-model="manifest[mFs[0].name]">{{mFs[0].name}}</md-checkbox>
							</div>
							<div class="md-layout-item">
								<div v-if="mFs[1]">
									<md-field v-if="mFs[1].type !== 'boolean'">
										<label :for="mFs[1].name">{{mFs[1].name}}</label>
										<md-input :name="mFs[1].name" :id="mFs[1].name" v-model="manifest[mFs[1].name]" :required="!mFs[1].optional" :type="(mFs[1].type === 'number'? 'number': 'text')"/>
									</md-field>
									<md-checkbox v-if="mFs[1].type === 'boolean'" v-model="manifest[mFs[1].name]">{{mFs[1].name}}</md-checkbox>
								</div>
							</div>
						</div>
						<md-progress-bar md-mode="indeterminate" v-if="sending" />
						<md-card-actions>
							<md-button class="md-primary" @click="validate" :disabled="sending">Upload</md-button>
						</md-card-actions>
					</md-card-content>
				</md-card>
			</form>
		</md-app-content>
	</md-app>
</template>

<script>
import md5 from 'md5'
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

const versionInvalid = (input) => {
	if (input === '') {
		return false
	}

	const regex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:\+([0-9]+(?:\.[0-9-]+)*))?(?:\+[0-9]+)?$/
	const res = regex.exec(input)

	if (!res) {
		return false
	}
	return true
}

export default {
	name: 'Upload',
	mixins: [validationMixin],
	data: () => ({
		namespaceId: null,
		hWConfigurationId: null,
		sWVariantId: null,
		deliveryVariantId: null,
		hash: null,
		version: null,
		commit: null,
		file: null,
		manifestFile: null,
		sending: false,
		manifest: {},
		manifestFileInput: {},
		fileInput: null
	}),
	computed: {
		manifestFields() {
			const hWConfiguration = this.$store.getters.getHWConfigurationById(this.hWConfigurationId)
			const sWVariant = this.$store.getters.getSWVariantById(this.sWVariantId)
			const deliveryVariant = this.$store.getters.getDeliveryVariantById(this.deliveryVariantId)
			const mFs = []
			for (let mF of hWConfiguration?.manifestFields || []) {
				if (!mFs.find((i) => i.id === mF.id)) {
					mFs.push(mF)
				}
			}
			for (let mF of sWVariant?.manifestFields || []) {
				if (!mFs.find((i) => i.id === mF.id)) {
					mFs.push(mF)
				}
			}
			for (let mF of deliveryVariant?.manifestFields || []) {
				if (!mFs.find((i) => i.id === mF.id)) {
					mFs.push(mF)
				}
			}

			for (let mf of mFs) {
				if (mf.default) {
					this.manifest[mf.name] = mf.default
				} else {
					this.manifest[mf.name] = null
				}
			}
			this.manifest = { ...this.manifest, ...this.manifestFileInput}
			return mFs
		},
		manifestFieldsTuple() {
			return this.manifestFields.reduce((arr, item, i) => {
				if (i % 2 === 0) {
					arr.push([item])
				} else {
					arr[Math.floor(i/2)].push(item)
				}
				return arr
			}, [])
		}
	},
	validations: {
		namespaceId: {
			required
		},
		hWConfigurationId: {
			required
		},
		deliveryVariantId: {
			required
		},
		sWVariantId: {
			required
		},
		file: {
			required
		},
		commit: {
			required
		},
		version: {
			versionInvalid
		}
	},
	async created () {
		if (!window.FileReader) {
			this.$snackbar.showMessage('Your browser is not supported!')
		}

		try {
			await this.$store.dispatch('loadNamespaces')
			await this.$store.dispatch('loadHWConfigurations')
			await this.$store.dispatch('loadSWVariants')
			await this.$store.dispatch('loadDeliveryVariants')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async validate() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.upload()
			}
		},
		async upload() {
			this.sending = true
			try {
				const tManifest = Object.assign({}, this.manifest)
				for (let field of this.manifestFields) {
					const fieldName = field.name
					if (fieldName in tManifest) {
						const val = tManifest[fieldName]
						switch (field.type) {
							case 'number':
								tManifest[fieldName] = parseInt(val, 10) || null
								break
							case 'boolean': tManifest[fieldName] = !!val
								break
						}
						if (tManifest[fieldName] === null) {
							delete tManifest[fieldName]
						}
					}
				}
				const data = {}
				data.version = this.version
				data.commit = this.commit
				data.hash = this.hash
				data.sWVariantName = this.$store.getters.getSWVariantById(this.sWVariantId).name
				data.deliveryVariantName = this.$store.getters.getDeliveryVariantById(this.deliveryVariantId).name
				data.hWConfigurationName = this.$store.getters.getHWConfigurationById(this.hWConfigurationId).name
				data.namespaceName = this.$store.getters.getNamespaceById(this.namespaceId).name
				data.file = document.querySelector('#fileInput').files[0]
				data.fileName = this.file
				data.metadata = JSON.stringify(tManifest)
				await this.$apiService.artifact.create(data)
				this.clearForm()
				this.$snackbar.showMessage('Artifact uploaded')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		clearForm() {
			this.$v.$reset()
			this.hWConfigurationId = null
			this.sWVariantId = null
			this.deliveryVariantId = null
			this.hash = null
			this.version = null
			this.file = null
			this.commit = null
			this.manifestFile = null
			this.sending = false
			this.manifest = {}
			this.manifestFileInput = {}
			this.fileInput = null
		},
		inputFile() {
			const reader = new FileReader()
			reader.onload = async (e) => {
				this.fileInput = e.target.result
				this.hash = md5(new Uint8Array(this.fileInput))
			}
			const fileToRead = document.querySelector('#fileInput').files[0]
			if (!fileToRead) {
				return
			}
			reader.readAsArrayBuffer(fileToRead)
		},
		inputManifest() {
			const reader = new FileReader()
			reader.onload = async (e) => {
				try {
					this.manifestFileInput = JSON.parse(e.target.result)
				} catch (e) {
					this.$snackbar.showMessage('Manifest file not valid')
				}
			}
			const fileToRead = document.querySelector('#fileManifest').files[0]
			if (!fileToRead) {
				return
			}
			reader.readAsText(fileToRead)
		}
	}
}
</script>

<style scoped>
	.upload-card {
		margin: auto;
		margin-top: 5%;
	}
</style>