export enum Track {
	dev = 'Development',
	test = 'Testing',
	prod = 'Production',
	archived = 'Archived', // after production
	retired = 'Retired', // after fail in dev or test
}

export const trackNames: string[] = [Track.dev, Track.test, Track.prod, Track.archived, Track.retired]

export const allowedNextTracks: { [key in Track]: Track[] } = {
	[Track.dev]: [Track.test, Track.retired],
	[Track.test]: [Track.prod, Track.retired],
	[Track.prod]: [Track.archived],
	[Track.archived]: [],
	[Track.retired]: [],
}

export function trackChangeAllowed(oldTrack: Track, newTrack: Track) {
	return allowedNextTracks[oldTrack].includes(newTrack)
}
