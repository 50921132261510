<template>
	<div class="tree-node">
		<div class="tree-menu">
			<div :style="indent" :class="{ 'bold': ('skus' in node)}">- {{ node.name }}</div>
		</div>
		<tree-node v-for="c in node.children" :node="c" :key="c.id" :depth="depth + 1" />
	</div>
</template>

<script>
export default {
	props: [ 'node', 'depth' ],
	name: 'tree-node',
	computed: {
		indent() {
			return { transform: `translate(${this.depth * 30}px)` }
		}
	}
}
</script>

<style scoped>
.tree-menu {
	border-bottom: 1px solid #ccc;
}

.bold {
	font-weight: bold;
}
</style>