<template>
	<div>
		<md-dialog :md-active="$route.name == 'downloadArtifactsByCommit'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$router.back()" @keydown.esc="$router.back()">
			<md-dialog-title>Download by commit</md-dialog-title>
			<md-dialog-content>
				<div class="md-layout md-gutter">
					<div class="md-layout-item">
						<md-field>
							<label>Commit</label>
							<md-select v-model="commitToDownload" name="commit" id="commit">
								<md-option v-for="p in Array.from(new Set($store.getters.artifacts.map((item) => item.commit)))" :key="p" :value="p">{{p}}</md-option>
							</md-select>
						</md-field>
					</div>
				</div>
				<div class="md-layout md-gutter">
					<div class="md-layout-item">
						<div class="md-layout-item">
							<md-datepicker md-clearable md-immediately :md-model-type="String" v-model="commitDownloadTokenExpireDate">
								<label>Token expiration date</label>
							</md-datepicker>
						</div>
					</div>
				</div>
				<md-dialog-actions>
					<md-button type="submit" class="md-primary" @click="downloadByCommit()" :disabled="!commitToDownload" >Download</md-button>
					<md-button type="submit" class="md-primary" @click="createCommitToken()" :disabled="!commitDownloadTokenExpireDate || !commitToDownload" >Create token</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
		<md-dialog :md-active="$route.name == 'showDownloadToken'">
			<md-dialog-title>Token</md-dialog-title>
			<md-dialog-content>
				<table>
					<tr>
						<td><span class="LRDtoken">{{artifactToken}}</span></td>
						<td>
							<md-button class="md-icon-button" @click="copyToClipboard(artifactToken)">
								<md-icon>content_copy</md-icon>
								<md-tooltip md-direction="top">Copy</md-tooltip>
							</md-button>
						</td>
					</tr>
					<tr>
						<td><span class="LRDtoken"><a :href="`${origin}/download?token=${artifactToken}`">Direct Download</a></span></td>
						<td>
							<md-button class="md-icon-button" @click="copyToClipboard(`${origin}/download?token=${artifactToken}`)">
								<md-icon>content_copy</md-icon>
								<md-tooltip md-direction="top">Copy</md-tooltip>
							</md-button>
						</td>
					</tr>
				</table>
				<md-dialog-actions>
					<md-button class="md-primary" @click="$router.push({ name: 'artifacts', params: { id: $route.params.id } })">Close</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
		<artifact-details v-if="$route.name == 'artifactDetails'"/>
		<e-table title="Artifacts" singularTitle="Artifact" :data="$store.getters.artifacts" :filter="filter" :columns="columns" :readonly="true" :deletable="false" :editable="false" :customActions="customActions" :tableActions="tableActions" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Details from '@/components/Artifact/Details.vue'
import Vue from 'vue'

export default {
	name: 'Artifacts',
	components: { 'e-table': Table, 'artifact-details': Details },
	data: function() {
		return {
			commitToDownload: '',
			commitDownloadTokenExpireDate: null,
			sortBy: 'id',
			sortOrder: 'DESC',
			artifactToken: '',
			filter: {
				commit: {
					value: null,
					name: 'Commit',
					property: 'commit'
				},
				version: {
					value: null,
					name: 'Version',
					property: 'version'
				},
				sWVariant: {
					value: null,
					name: 'SW Variant',
					property: 'sWVariant.name'
				},
				deliveryVariant: {
					value: null,
					name: 'Delivery Variant',
					property: 'deliveryVariant.name'
				},
				hWConfiguration: {
					value: null,
					name: 'HW Configuration',
					property: 'hWConfiguration.name'
				},
				release: {
					value: null,
					name: 'Release',
					property: 'releases.name'
				},
				tag: {
					value: [],
					name: 'Tag'
				}
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'version',
					label: 'Version'
				}, {
					name: 'commit',
					label: 'Commit'
				}, {
					name: 'created',
					label: 'Created',
					render: (val) => Vue.formatDate(val)
				}, {
					name: 'uploadedBy',
					label: 'Uploaded by'
				}, {
					name: 'sWVariant',
					label: 'SW Variant',
					sortable: 'sWVariant.name',
					render: (val) => val.name
				}, {
					name: 'deliveryVariant',
					label: 'Delivery Variant',
					sortable: 'deliveryVariant.name',
					render: (val) => val.name
				}, {
					name: 'hWConfiguration',
					label: 'HW Configuration',
					sortable: 'hWConfiguration.name',
					render: (val) => val.name
				}, {
					name: 'releases',
					label: 'Releases',
					render: (val) => val.map((r) => r.version).join(', '),
					sortable: false
				}, {
					name: 'id',
					label: 'Tags',
					customTags: true,
					sortable: false,
					render: (a) => {
						const tags = [...a.sWVariant.tags, ...a.hWConfiguration.tags, ...a.deliveryVariant.tags]
						return tags.reduce((prev, curr) => {
							if (!prev.map((t) => t.id).includes(curr.id)) {
								prev.push(curr)
							}
							return prev
						}, [])
					}
				}
			],
			customActions: [{
				icon: 'visibility',
				tooltip: 'Show details',
				handler: (artifact) => {
					this.$router.push({ name: 'artifactDetails', params: { eid: artifact.id } })
				}
			}, {
				icon: 'download',
				tooltip: 'Download',
				handler: async (artifact) => window.location.href = `${this.origin}/download?token=${await this.$apiService.artifact.getOneTimeToken(artifact)}`
			}, {
				icon: 'code',
				tooltip: 'Go to source code',
				handler: async (artifact) => window.open(`${artifact.namespace.repository}/-/commit/${artifact.commit}`, '_blank')
			// }, {
			// 	icon: 'token',
			// 	tooltip: 'Create token',
			// 	handler: (artifact) => this.createToken(artifact)
			}],
			tableActions: [{
				icon: 'download',
				tooltip: 'Download by commit',
				handler: () => this.$router.push({ name: 'downloadArtifactsByCommit' })
			}]
		}
	},
	computed: {
		origin: function() {
			return window.location.origin
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadArtifacts', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async downloadByCommit() {
			const token = await this.$apiService.lrdToken.getCommitToken(this.commitToDownload, { oneTime: true })
			window.open(`${this.origin}/download?token=${token}`, '_blank')
			this.commitToDownload = null
			this.commitDownloadTokenExpireDate = null
			this.$router.back()
		},
		async createCommitToken() {
			this.artifactToken = await this.$apiService.lrdToken.getCommitToken(this.commitToDownload, { oneTime: false, expiresIn: this.commitDownloadTokenExpireDate })
			this.commitToDownload = null
			this.commitDownloadTokenExpireDate = null
			this.$router.push({ name: 'showDownloadToken' })
		},
		async createToken(artifact) {
			try {
				this.artifactToken = await this.$apiService.artifact.getOneTimeToken(artifact.id)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.push({ name: 'showDownloadToken' })
		},
		async copyToClipboard(text) {
			await navigator.clipboard.writeText(text)
		}
	}
}
</script>
