<template>
	<div>
		<form novalidate class="md-layout">
			<md-dialog :md-active="$route.name == 'createSkuTree'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Sku Group Parent name is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label for="parents">Parent</label>
								<md-select v-model="parentId" name="parentId" id="parentId">
									<md-option v-for="i in $store.getters.skuGroupParents" :key="i.id" :value="i.id">{{i.name}}</md-option>
								</md-select>
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateSkuGroupParent()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	data: () => ({
		name: null,
		parentId: null,
		sending: false,
	}),
	validations: {
		name: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.parentId = null
		},
		async addSkuGroupParent() {
			this.sending = true

			try {
				await this.$apiService.skuGroupParent.create({ name: this.name, skuGroupParentId: this.parentId })
				this.$snackbar.showMessage('Sku Group saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateSkuGroupParent() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.addSkuGroupParent()
			}
		},
		async close() {
			this.clearForm()
			this.sending = true
			try {
				await this.$store.dispatch('loadSkuGroupParents', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
			this.$router.back()
		},
	}
}
</script>
