import config from '@/../config'
import { UserRole } from '@/@types/interfaces'
import router from '../router'

function authHeader() {
	const user = JSON.parse(localStorage.getItem('user')!)

	if (user && user.token) {
		/* eslint-disable */
		return { Authorization: 'Bearer ' + user.token }
		/* eslint-enable */
	} else {
		return {}
	}
}

function isAuthenticated(): boolean {
	const user = JSON.parse(localStorage.getItem('user')!)
	if (!user || !user.token) {
		return false
	}
	const d = new Date()
	if ((d.getTime() - (user.exp * 1000)) > 0) {
		return false
	}
	return true
}

function authGuard(to: any, from: any, next: any) {
	if (to.query.token) {
		const storageUser: any = JSON.parse(atob(to.query.token.split('.')[1]))
		storageUser.token = to.query.token
		localStorage.setItem('user', JSON.stringify(storageUser))
		router.push('/')
		return
	}

	const user = JSON.parse(localStorage.getItem('user')!)
	if (!user) {
		window.location.href = config.AUTH_URL
	}
	if (user.roles.length === 0) {
		localStorage.removeItem('user')
		router.push('/forbidden')
		return
	}

	if (isAuthenticated()) {
		return next()
	}

	window.location.href = config.AUTH_URL
	return
}

function onlyAdmin(to: any, from: any, next: any) {
	const storageUser = JSON.parse(localStorage.getItem('user')!)
	if (storageUser && storageUser.roles.includes(UserRole.ADMIN)) {
		next()
		return
	}
	router.push('/forbidden')
	return
}

function getLocalUser() {
	return JSON.parse(localStorage.getItem('user')!)
}

export { authHeader, authGuard, onlyAdmin, getLocalUser }