<template>
	<md-dialog :md-active="$route.name == 'editManifestField'" v-if="manifestField" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="manifestField.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="manifestField.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-40">
					<md-field>
						<label>Type</label>
						<md-select v-model="manifestField.type" name="type" id="type" required>
							<md-option v-for="(field, id) in types" :key="id" :value="field.value">{{field.text}}</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-checkbox v-model="manifestField.array">Array</md-checkbox>
				</div>
				<div class="md-layout-item">
					<md-checkbox v-model="manifestField.optional">Optional</md-checkbox>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-60">
					<md-field>
						<label for="allowedValues">Allowed values (comma separated)</label>
						<md-input name="allowedValues" id="allowedValues" v-model="allowedValues" :disabled="manifestField.type === 'boolean' || manifestField.type === 'Version' || manifestField.array" />
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="default">Default</label>
						<md-input name="default" id="default" v-model="defaultVal" :disabled="manifestField.array" />
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { FieldType } from '@/@types/interfaces'
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false,
		origAllowedValues: [],
		manifestField: null,
		defaultVal: null,
		allowedValues: [],
		types: [
			{text: 'String', value: 'string'},
			{text: 'Number', value: 'number'},
			{text: 'Boolean', value: 'boolean'},
			{text: 'Version', value: 'Version'}
		]
	}),
	mixins: [debounce],
	watch: {
		manifestField: {
			deep: true,
			async handler() {
				await this.update()
			}
		},
		defaultVal: {
			deep: true,
			async handler() {
				await this.update()
			}
		},
		allowedValues: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadManifestFields')
		this.manifestField = this.$store.getters.getManifestFieldById(this.$route.params.eid)
		switch(this.manifestField.type) {
			case FieldType.string:
				this.defaultVal = this.manifestField.defaultString
				this.allowedValues = (this.manifestField.allowedValuesString? this.manifestField.allowedValuesString.join(',') : '')
				break
			case FieldType.number:
				this.defaultVal = this.manifestField.defaultNumber
				this.allowedValues = (this.manifestField.allowedValuesNumber? this.manifestField.allowedValuesNumber.join(',') : '')
				break
			case FieldType.version:
				this.defaultVal = this.manifestField.defaultVersion
				this.allowedValues = (this.manifestField.allowedValuesVersion? this.manifestField.allowedValuesVersion.join(',') : '')
				break
			case FieldType.boolean:
				this.defaultVal = this.manifestField.defaultBoolean
				this.allowedValues = (this.manifestField.allowedValuesBoolean? this.manifestField.allowedValuesBoolean.join(',') : '')
				break
		}
		this.origAllowedValues = this.allowedValues
		this.update = this.debounce(this.update)
	},
	methods: {
		async updateManifestField() {
			this.sending = true
			try {
				const payload = {
					description: this.manifestField.description,
					optional: this.manifestField.optional,
					array: this.manifestField.array,
					type: this.manifestField.type
				}

				switch(this.manifestField.type) {
					case FieldType.string:
						payload.defaultString = this.defaultVal
						payload.allowedValuesString = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.number:
						payload.defaultNumber = parseInt(this.defaultVal)
						payload.allowedValuesNumber = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => parseInt(val)): null)
						break
					case FieldType.version:
						payload.defaultVersion = this.defaultVal
						payload.allowedValuesVersion = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.boolean:
						payload.defaultBoolean = this.defaultVal
						payload.allowedValuesBoolean = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => val === 'true'): null)
						break
				}
				if (this.origAllowedValues.length > 0 && this.allowedValues.length === 0) {
					payload.allowedValuesString = null
					payload.allowedValuesNumber = null
					payload.allowedValuesVersion = null
					payload.allowedValuesBoolean = null
				}
				await this.$apiService.manifestField.update(this.manifestField.id, payload, true)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateManifestField()
		},
		async close() {
			try {
				await this.$store.dispatch('loadManifestFields', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
