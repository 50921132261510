function getSkuTree(skuGroupsInput: any[], skuGroupParents: any[]): any[] {
	const skuGroups: any[] = []
	skuGroupsInput.forEach((g) => {
		for (let parent of g.skuGroupParents) {
			skuGroups.push({
				origId: g.id,
				name: g.name,
				label: g.name,
				skus: g.skus,
				packageTripples: g.packageTripples,
				skuGroupParent: Object.assign(parent, {}),
				type: 'group'
			})
		}
	})
	const parents = skuGroupParents.map((g) => ({
		...g,
		origId: g.id,
		label: g.name,
		type: 'parent'
	}))
	let data = [...parents, ...skuGroups]
	data = data.map((d, id) => ({
		...d,
		id
	}))
	let roots = data.filter((parent) => !parent.skuGroupParent).map((parent) => ({
		...parent,
		label: parent.name
	}))
	let nodes = data.filter((parent) => parent.skuGroupParent)

	const addNodes = (node: any, nodes: any[]) => {
		if (node.type === 'group') {
			return nodes
		}
		node.children = nodes.filter((n) => n.skuGroupParent.id === node.origId)
		if (node.children.length === 0) {
			delete node.children
			return nodes
		}
		nodes = nodes.filter((n) => n.skuGroupParent.id !== node.origId)
		node.children.forEach((nn: any) => {
			nodes = addNodes(nn, nodes)
		})
		return nodes
	}

	roots.forEach((root) => {
		addNodes(root, nodes)
	})
	return roots
}

export { getSkuTree }