<template>
	<div>
		<div class="md-layout md-alignment-center-center">
			<div class="md-layout-item md-size-40">
				<md-field>
					<label for="consumerIds">Consumers</label>
					<md-select v-model="consumerIds" name="consumerIds" id="consumerIds" multiple @md-selected="onSelectConsumer">
						<md-option v-for="i in $store.getters.consumers" :key="i.id" :value="i.id">{{i.name}}</md-option>
					</md-select>
				</md-field>
			</div>
		</div>
		<div class="md-layout">
			<conditions :consumers="consumers" :conditions="conditions" @activateCondition="activateCondition" @deleteCondition="deleteCondition" @saveCondition="saveCondition" @addCondition="addCondition"/>
		</div>
	</div>
</template>
<script>
import Conditions from '../Conditions.vue'
export default {
	name: 'RolloutTab',
	props: ['consumers', 'release'],
	components: {
		'conditions': Conditions,
	},
	data: function() {
		return {
			consumerIds: [],
			conditions: []
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadConsumers')
			this.consumerIds = this.consumers.map((c) => c.id)
			this.conditions = this.release.conditions
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		onSelectConsumer() {
			this.$emit('setConsumers', this.consumerIds.map((c) => ({ id: c })))
		},
		async activateCondition(i, active) {
			try {
				this.conditions[i].active = active
				await this.$apiService.condition.activate(this.conditions[i].id, active)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async deleteCondition(i) {
			try {
				this.conditions.splice(i, 1)
				this.$apiService.condition.delete(this.conditions[i].id)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async saveCondition(condition, i) {
			try {
				this.conditions[i].condition = condition.condition
				await this.$apiService.condition.update(this.conditions[i].id, { condition: condition.condition })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async addCondition(requestId, condition) {
			try {
				const newCondition = await this.$apiService.condition.create({ requestId, condition, releaseId: this.release.id })
				this.conditions.push(newCondition)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
	}
}
</script>
