<template>
	<md-card>
		<md-card-header>
			<h2 class="md-subheading">Released for</h2>
		</md-card-header>
		<md-progress-bar md-mode="indeterminate" v-if="saving" />
		<md-progress-bar md-mode="query" v-if="loading" />

		<md-card-content>
			<div class="md-layout">
				<div class="md-layout-item" style="margin-bottom: 1rem" v-for="track in tracks" :key="track">
					<md-card style="height: 100%" :class="{ inactive: track != activeTrack }">
						<md-card-header>
							<h3 class="subheading">{{ track }}</h3>
						</md-card-header>
						<md-card-content>
							<md-list v-if="editing">
								<md-list-item v-for="receiver in receivers(track)" :key="receiver.id">
									<md-checkbox v-model="changedReceiverIds" :value="receiver.id">{{ receiver.name }}</md-checkbox>
								</md-list-item>
							</md-list>
							<md-list v-else>
								<md-list-item v-for="receiver in receivers(track)" :key="receiver.id">
									<md-icon v-if="receiver.check">check_box</md-icon>
									<md-icon v-else>check_box_outline_blank</md-icon>
									<span class="md-list-item-text">
										<router-link :to="{ name: 'CommitForReceiversDetails', params: { receiver: receiver.name } }">
											{{ receiver.name }}
										</router-link>
									</span>
								</md-list-item>
							</md-list>
						</md-card-content>
					</md-card>
				</div>
			</div>
		</md-card-content>
		<md-card-actions v-if="allowedToEdit">
			<md-button v-if="!editing" @click="startEdit">Edit</md-button>
			<md-button v-else @click="editing = false">Cancel</md-button>
			<md-button v-if="editing" @click="save" :disabled="saving" class="md-primary">Save</md-button>
		</md-card-actions>
	</md-card>
</template>



<script>
import { getLocalUser } from '@/lib/auth'
import { trackNames } from '@/@types/database-enums'

export default {
	name: 'ArtifactReceivers',
	props: ['commit', 'activeTrack'],
	data: function () {
		return {
			commitReceivers: [],
			changedReceiverIds: [],
			editing: false,
			loading: false,
			saving: false,
		}
	},
	watch: {
		'this.$store.state.artifactReceivers': { handler: () => {} },
	},
	computed: {
		allowedToEdit() {
			return getLocalUser().roles.includes('admin')
		},
		tracks() {
			const receivers = this.$store.state.artifactReceivers
			if (receivers.length !== 0) {
				return trackNames.filter(t => receivers.some(r => r.track === t))
			} else {
				return trackNames
			}
		},
	},
	async created() {
		if (this.$store.getters.artifactReceivers.length === 0) {
			this.loading = true
			try {
				await this.$store.dispatch('loadArtifactReceivers', { all: true })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.loading = false
		}
		await this.load()
	},
	methods: {
		receivers(track) {
			return this.$store.getters.artifactReceivers
				.filter(r => r.track === track)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(r => {
					const check = this.commitReceivers.some(cr => cr.id === r.id)
					return {
						id: r.id,
						name: r.name,
						check,
					}
				})
		},
		startEdit() {
			this.changedReceiverIds = this.commitReceivers.map(r => r.id)
			this.editing = true
		},
		async load() {
			this.loading = true
			try {
				this.commitReceivers = await this.$apiService.artifactReceiver.getForCommit(this.commit)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.loading = false
		},
		async save() {
			this.saving = true
			try {
				await this.$apiService.commit.update(this.commit, { artifactReceiverIds: this.changedReceiverIds })
				await this.load()
				this.editing = false
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.saving = false
		},
	},
}
</script>

<style>
.inactive {
	opacity: 0.5;
}
</style>
