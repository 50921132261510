<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item :to="{ name: 'CommitOverview' }" exact>Overview</md-list-item>
				<md-list-item v-if="$route.matched.some(({ name }) => name === 'commitdetails')" :to="{ name: 'commitdetails' }" exact event="">{{ commit }}</md-list-item>
			</md-list>
			<md-list>
				<md-list-item :to="{ name: 'CommitForReceivers' }" exact>For Receiver</md-list-item>
				<md-list-item v-if="$route.matched.some(({ name }) => name === 'CommitForReceiversDetails')" :to="{ name: 'CommitForReceiversDetails' }" exact event="">{{ receiver }}</md-list-item>
			</md-list>
		</md-app-drawer>
		<md-app-content>
			<router-view />
		</md-app-content>
	</md-app>
</template>


<script>
import Toolbar from '@/components/Common/Toolbar.vue'
import formatCommit from '@/mixins/formatting'

export default {
	name: 'CommitHome',
	mixins: [formatCommit],
	components: { toolbar: Toolbar },
	watch: {
		'this.$route.currentRoute': {
			handler() {},
		},
	},
	computed: {
		commit() {
			if (this.$route.matched.some(({ name }) => name === 'commitdetails') && this.$router.currentRoute?.params?.commit) {
				return this.formatCommit(this.$router.currentRoute.params.commit)
			}
			return 'Commit details'
		},
		receiver() {
			if (this.$route.matched.some(({ name }) => name === 'CommitForReceiversDetails') && this.$router.currentRoute?.params?.receiver) {
				return 'Commits for ' + this.$router.currentRoute.params.receiver
			}
			return 'Commits for receiver'
		},
	},
}
</script>
