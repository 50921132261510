<template>
	<div>
		<h1 class="md-title">
			<span v-if="showVersion || showNamespace" style="color: red">inconsistent commit - no clear version number</span>
			<span v-else>{{ commitFormattedName }}</span>
		</h1>
		<p class="md-caption" :title="commit">{{ formatCommit(commit) }}</p>
		<md-card v-if="commitData">
			<md-card-header>
				<h2 class="md-subheading">{{ commitData.track }} Track</h2>
			</md-card-header>
			<md-card-content>
				<md-content>
					<div class="md-layout">
						<div class="md-layout-item">
							<h3>Links</h3>
							<ul>
								<li v-if="namespace">
									<router-link :to="{ name: 'artifacts', params: { id: namespace.id } }">Go to artifacts in '{{ namespace.name }}'</router-link>
								</li>
								<li><a v-if="commitGitLink" :href="commitGitLink" target="_blank">Link to git commit</a></li>
							</ul>
						</div>
						<div class="md-layout-item">
							<h3>Actions</h3>
							<ul>
								<li><a v-if="commitData.artifacts && commitData.artifacts.length > 0" @click="downloadWholeCommit">Download all artifacts</a></li>
								<li v-if="allowedToEdit"><a @click="showHistory = true">Show History</a></li>
							</ul>
						</div>
					</div>
				</md-content>
				<md-card-actions v-if="allowedToEdit">
					<md-button @click="startTrackEdit">Change Track</md-button>
					<md-button @click="deleteCommit" v-if="canDelete" class="md-icon-button md-accent">
						<md-icon>delete</md-icon>
						<md-tooltip md-direction="top">Delete</md-tooltip>
					</md-button>
				</md-card-actions>
			</md-card-content>
		</md-card>
		<div v-else>Loading commit data</div>
		<br />
		<artifact-receivers v-if="commitData" :commit="commit" :activeTrack="commitData.track" />
		<br />
		<commit-notes :commit="commit" />
		<br />
		<md-card v-if="commitData">
			<md-card-content v-if="commitData.artifacts">
				<artifact-details v-if="showDetails && selectedArtifact !== null" :artifact="selectedArtifact" :showDetails="showDetails" @close="closeShowDetails" />
				<e-table
					title="Artifacts"
					singularTitle="Artifact"
					:data="commitData.artifacts"
					:filter="filter"
					:columns="visibleColumns"
					:readonly="true"
					:deletable="false"
					:editable="false"
					:customActions="customActions"
					@setSortBy="
						val => {
							sortBy = val
						}
					"
					@setSortOrder="
						val => {
							sortOrder = val
						}
					"
			/></md-card-content>
		</md-card>

		<md-dialog :md-active.sync="editTrack" :md-click-outside-to-close="false" :md-close-on-esc="false">
			<md-dialog-title> Edit Track for {{ commitFormattedName }} </md-dialog-title>
			<md-dialog-content>
				<md-field>
					<label>Track</label>
					<md-select v-model="newTrack">
						<md-option v-for="track in possibleTracks" :value="track" :key="track">{{ track }}</md-option>
					</md-select>
				</md-field>
			</md-dialog-content>
			<md-dialog-actions>
				<md-button class="md-primary" @click="cancelEditTrack">Cancel</md-button>
				<md-button @click="changeTrack" :disabled="trackClean">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<track-history v-if="allowedToEdit" :commit="commit" :showHistory="showHistory" @close="showHistory = false" />

		<md-dialog :md-active="showConfirmDelete" :md-click-outside-to-close="false" :md-close-on-esc="false" @md-clicked-outside="showConfirmDelete = false" @keydown.esc="showConfirmDelete = false">
			<md-dialog-title>
				Are you sure you want to delete commit <em>{{ formatCommit(commit) }} </em>?
			</md-dialog-title>
			<md-dialog-content>
				<div class="md-layout">
					<div class="md-layout-item">Type 'confirm' if want to delete it.</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item">
						<md-field :class="getValidationClass('itemNameInput')">
							<md-input name="itemNameInput" id="itemNameInput" placeholder="Item name" v-model="itemNameInput" />
							<span class="md-error" v-if="!$v.itemNameInput.confirmed">Wrong input!</span>
						</md-field>
					</div>
				</div>
				<md-dialog-actions>
					<md-button class="md-primary" @click="showConfirmDelete = false">Cancel</md-button>
					<md-button class="md-primary md-accent" @click="confirmDelete">Confirm</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>



<script>
import Table from '@/components/Common/Table'
import Details from '@/components/Artifact/Details.vue'
import { getLocalUser } from '@/lib/auth'
import config from '@/../config'
import Vue from 'vue'
import { allowedNextTracks, Track } from '@/@types/database-enums'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'
import CommitNotes from '@/components/Commit/CommitNotes.vue'
import TrackHistory from '@/components/Commit/TrackHistory.vue'
import ArtifactReceivers from '@/components/Commit/ArtifactReceivers.vue'
import formatCommit from '@/mixins/formatting'
import { validationMixin } from 'vuelidate'

export default {
	name: 'CommitDetails',
	mixins: [formatCommit, validationMixin],
	components: {
		'e-table': Table,
		'artifact-details': Details,
		'confirm-delete': ConfirmDelete,
		'commit-notes': CommitNotes,
		'track-history': TrackHistory,
		'artifact-receivers': ArtifactReceivers,
	},
	data: function () {
		return {
			apiUrl: config.API_URL,
			commit: '',
			commitFormattedName: '',
			namespace: null,
			showNamespace: false,
			showVersion: false,
			newTrack: null,
			editTrack: false,
			showHistory: false,
			showConfirmDelete: false,
			itemNameInput: null,
			filter: {
				hWConfiguration: {
					value: null,
					name: 'HW Configuration',
					property: 'hWConfiguration.name',
				},
				sWVariant: {
					value: null,
					name: 'SW Variant',
					property: 'sWVariant.name',
				},
				deliveryVariant: {
					value: null,
					name: 'Delivery Variant',
					property: 'deliveryVariant.name',
				},
				tag: {
					value: [],
					name: 'Tag',
				},
			},
			columns: [
				{
					name: 'created',
					label: 'Created',
					render: val => Vue.formatDate(val),
				},
				{
					name: 'hWConfiguration',
					label: 'HW Configuration',
					sortable: 'hWConfiguration.name',
					render: val => val.name,
				},
				{
					name: 'sWVariant',
					label: 'SW Variant',
					sortable: 'sWVariant.name',
					render: val => val.name,
				},
				{
					name: 'deliveryVariant',
					label: 'Delivery Variant',
					sortable: 'deliveryVariant.name',
					render: val => val.name,
				},
				{
					name: 'namespace',
					label: 'Namespace',
					sortable: 'namespace.name',
					renderHtml: val => `<span style="color: red">${val.name}</span>`,
					hidden: () => !this.showNamespace,
				},
				{
					name: 'version',
					label: 'Version',
					sortable: 'version',
					renderHtml: val => `<span style="color: red"> ${val}</span>`,
					hidden: () => !this.showVersion,
				},
				{
					name: 'tags',
					label: 'Tags',
					customTags: true,
					sortable: false,
					render: a => {
						const tags = [...a.sWVariant.tags, ...a.hWConfiguration.tags, ...a.deliveryVariant.tags]
						return tags.reduce((prev, curr) => {
							if (!prev.map(t => t.id).includes(curr.id)) {
								prev.push(curr)
							}
							return prev
						}, [])
					},
				},
			],
			customActions: [
				{
					icon: 'visibility',
					tooltip: 'Show details',
					handler: artifact => {
						this.selectedArtifact = artifact
						this.showDetails = true
					},
				},
				{
					icon: 'download',
					tooltip: 'Download',
					handler: async artifact => (window.location.href = `${this.origin}/download?token=${await this.$apiService.artifact.getOneTimeToken(artifact)}`),
				},
			],
			selectedArtifact: null,
			showDetails: false,
		}
	},
	validations() {
		return {
			itemNameInput: {
				confirmed: value => value === 'confirm',
			},
		}
	},
	watch: {
		'this.$store.state.commit': {
			handler: () => {
				this.setCommitFormattedName()
			},
		},
	},
	computed: {
		allowedToEdit() {
			return getLocalUser().roles.includes('admin')
		},
		commitData() {
			const c = this.$store.getters.commit(this.commit)
			return c
		},
		commitGitLink() {
			const c = this.commitData
			if (!c || !c.artifacts || c.artifacts.length === 0) {
				return null
			}
			const repo = c.artifacts[0].namespace.repository
			return `${repo}/-/commit/${this.commit}`
		},
		trackClean() {
			if (!this.newTrack || !this.commitData) {
				return true
			}
			return this.newTrack === this.commitData.track
		},
		possibleTracks() {
			if (!this.commitData || !this.commitData.track) {
				return []
			}

			return [this.commitData.track, ...allowedNextTracks[this.commitData.track]]
		},
		canDelete() {
			return this.commitData?.track === Track.retired
		},
		visibleColumns() {
			return this.columns.filter(x => !(x.hidden?.apply() ?? false))
		},
		origin: function () {
			return window.location.origin
		},
	},
	async created() {
		this.commit = this.$router.currentRoute.params.commit
		this.commitFormattedName = this.commit

		try {
			await this.$store.dispatch('getCommit', this.commit)
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
		this.setCommitFormattedName()
	},
	methods: {
		startTrackEdit() {
			this.editTrack = true
			this.newTrack = this.commitData.track
		},
		cancelEditTrack() {
			this.editTrack = false
		},
		async changeTrack() {
			if (!this.newTrack) {
				return
			}
			if (this.newTrack === this.commitData.track) {
				this.$snackbar.showMessage('Track did not change')
				return
			}
			this.saving = true
			const data = {
				track: this.newTrack,
			}
			try {
				const updated = await this.$apiService.commit.update(this.commit, data)
				this.editTrack = false
				this.$store.commit('setCommit', updated)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.saving = false
		},
		async deleteCommit() {
			this.showConfirmDelete = true
		},
		async confirmedDelete() {
			this.showConfirmDelete = false
			try {
				await this.$apiService.commit.delete(this.commit)
				await this.$store.dispatch('loadCommitOverview', { all: true })
				this.$snackbar.showMessage('Commit deleted')
				this.$router.push({ name: 'CommitOverview' })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		closeShowDetails() {
			this.selectedArtifact = null
			this.showDetails = false
		},
		async downloadWholeCommit() {
			const token = await this.$apiService.lrdToken.getCommitToken(this.commit, { oneTime: true })
			window.open(`${this.origin}/download?token=${token}`, '_blank')
		},
		setCommitFormattedName() {
			const c = this.commitData
			if (!c || !c.artifacts || c.artifacts.length === 0) {
				this.commitFormattedName = this.commit
			}
			const versions = c.artifacts.map(a => a.version)
			const namespaceNames = c.artifacts.map(a => a.namespace.name)
			// const namespaceID = c.artifacts.map(a => a.namespace.id)[0]

			const version = versions[0]
			const namespace = namespaceNames[0]

			if (!versions.every(v => v === version)) {
				console.error('Not all versions are the same')
				this.$snackbar.showMessage('Not all versions are the same')
				this.showVersion = true
			}
			if (!namespaceNames.every(n => n === namespace)) {
				console.error('Not all namespaces are the same')
				this.$snackbar.showMessage('Not all namespaces are the same')
				this.showNamespace = true
			} else {
				this.namespace = {
					id: c.artifacts[0].namespace.id,
					name: c.artifacts[0].namespace.name,
				}
			}

			if (!(this.showNamespace || this.showVersion)) {
				this.commitFormattedName = `${namespace} ${version}`
			}
		},
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty,
				}
			}
		},
		async confirmDelete() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.confirmedDelete()
			}
		},
	},
}
</script>
