<template>
	<md-dialog :md-active="$route.name == 'editConsumer'" v-if="consumer" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field :class="getValidationClass('name')">
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="consumer.name"/>
						<span class="md-error" v-if="!$v.consumer.name.required">Consumer name is required</span>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label>Programming Types</label>
						<md-select v-model="consumer.programmingTypes" name="programmingTypes" id="programmingTypes" multiple>
							<md-option v-for="type in $store.getters.programmingTypes" :key="type.id" :value="type.id">{{type.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	data: () => ({
		sending: false,
		consumer: null
	}),
	mixins: [debounce, validationMixin],
	watch: {
		consumer: {
			deep: true,
			async handler() {
				await this.validateConsumer()
			}
		}
	},
	validations: {
		consumer: {
			name: {
				required
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadConsumers')
		this.consumer = this.$store.getters.getConsumerById(this.$route.params.eid)
		this.consumer.programmingTypes = this.consumer.programmingTypes.map((t) => t.id)
		this.updateConsumer = this.debounce(this.updateConsumer)
	},
	methods: {
		async updateConsumer() {
			this.sending = true
			try {
				await this.$apiService.consumer.update(this.consumer.id, {
					name: this.consumer.name,
					programmingTypeIds: this.consumer.programmingTypes
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		getValidationClass(fieldName) {
			const field = this.$v.consumer[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async validateConsumer() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updateConsumer()
			}
		},
		async close() {
			try {
				await this.$store.dispatch('loadConsumers', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>