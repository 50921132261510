<template>
	<div>
		<md-dialog-alert
		:md-active.sync="showRessourceTokenToken"
		md-title="Ressource token"
		:md-content="selectedRessourceToken.token">
		</md-dialog-alert>
		<md-dialog :md-active.sync="showAccessTokenToken">
			<md-dialog-title>Token</md-dialog-title>
			<md-dialog-content>
				<table>
					<tr>
						<td><span class="LRDtoken">{{token}}</span></td>
						<td>
							<md-button class="md-icon-button" @click="copyToTokenToClipboard">
								<md-icon>content_copy</md-icon>
								<md-tooltip md-direction="top">Copy</md-tooltip>
							</md-button>
						</td>
					</tr>
				</table>
				<md-dialog-actions>
					<md-button class="md-primary" @click="showAccessTokenToken = false">Close</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
		<create-access-token v-if="$route.name == 'createAccessToken'" @close="closeCreate"/>
		<e-table title="Ressource Token" singularTitle="Ressource Token" :data="$store.getters.lrdToken" :columns="lrdToken.columns"
		@delete="deleteLrdToken" :readonly="true" :editable="false" :deleteable="true" @setSortBy="val => {lrdToken.sortBy = val}" @setSortOrder="val => {lrdToken.sortOrder = val}" :customActions="lrdToken.customActions"/>
		<e-table title="Access Token" singularTitle="Access Token" :data="$store.getters.accessToken" :columns="accessToken.columns" @delete="deleteAccessToken" :readonly="false" :editable="false" :deleteable="true" @setSortBy="val => {accessToken.sortBy = val}" @setSortOrder="val => {accessToken.sortOrder = val}"/>
		<div class="md-layout md-alignment-center-right">
			<div class="md-layout-item md-size-20" style="text-align: right;">
				<md-button @click="showLTToken">show LT Token</md-button>
			</div>
		</div>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import CreateAccessToken from '@/components/AccessToken/Create.vue'
import { AccessTokenType, LRDTokenType } from '@/@types/interfaces'
import Vue from 'vue'

export default {
	name: 'LrdToken',
	components: {
		'e-table': Table,
		'create-access-token': CreateAccessToken,
	},
	data: function() {
		return {
			selectedLrdToken: {},
			selectedAccessToken: {},
			selectedRessourceToken: {},
			showAccessTokenToken: false,
			showRessourceTokenToken: false,
			token: '',
			lrdToken: {
				sortBy: 'id',
				sortOrder: 'DESC',
				columns: [
					{
						name: 'id',
						label: 'ID'
					}, {
						name: 'description',
						label: 'Description'
					}, {
						name: 'count',
						label: 'Download count'
					}, {
						name: 'type',
						label: 'Type',
						render: (type) => {
							switch (type) {
								case LRDTokenType.SINGLE: return 'Single artifact'
								case LRDTokenType.MULTIPLE: return 'Multiple artifacts'
								case LRDTokenType.RELEASE: return 'Release'
								case LRDTokenType.COMMIT: return 'Commit'
							}
						}
					}, {
						name: 'expiresIn',
						label: 'ExpiresIn',
						render: (val) => {
							if (val) {
								return Vue.formatDate(val)
							} else {
								return 'One time'
							}
						}
					}, {
						name: 'release',
						label: 'Release',
						render: (val) => (val? val.version : '-')
					}, {
						name: 'user',
						label: 'User',
						render: (val) => (val? val.name : '-')
					}
				],
				customActions: [{
					icon: 'visibility',
					tooltip: 'Show token',
					handler: (token) => {
						this.selectedRessourceToken = Object.assign({}, token)
						this.showRessourceTokenToken = true
					}
				}]
			},
			accessToken: {
				sortBy: 'id',
				sortOrder: 'DESC',
				columns: [
					{
						name: 'id',
						label: 'ID'
					}, {
						name: 'name',
						label: 'Name'
					}, {
						name: 'description',
						label: 'Description'
					}, {
						name: 'type',
						label: 'Type',
						render: (type) => {
							switch (type) {
								case AccessTokenType.SERVICE: return 'Service'
								case AccessTokenType.PIPELINE: return 'Pipeline'
							}
						}
					}
				]
			}
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadLrdToken', { sortBy: this.lrdToken.sortBy, sortOrder: this.lrdToken.sortOrder })
			await this.$store.dispatch('loadAccessToken', { sortBy: this.accessToken.sortBy, sortOrder: this.accessToken.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteLrdToken(id) {
			try {
				await this.$apiService.lrdToken.delete(id)
				await this.$store.dispatch('loadLrdToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async deleteAccessToken(id) {
			try {
				await this.$apiService.accessToken.delete(id)
				await this.$store.dispatch('loadAccessToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async closeCreate(token) {
			this.$router.push({ name: 'lrdToken' })
			if (token) {
				this.token = token.token
				this.showAccessTokenToken = true
			}
			try {
				await this.$store.dispatch('loadAccessToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async copyToTokenToClipboard() {
			await navigator.clipboard.writeText(this.token)
		},
		async showLTToken() {
			const res = await this.$apiService.getLTToken()
			this.token = res.token
			this.showAccessTokenToken = true
		}
	}
}
</script>