<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createReleaseToken'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>
					Create token
				</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-radio v-model="oneTime" :value="false">Time based</md-radio>
						</div>
						<div class="md-layout-item">
							<md-radio v-model="oneTime" :value="true">One Time Token</md-radio>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-datepicker md-clearable md-immediately :md-model-type="String" v-model="expiresIn" v-if="!oneTime">
								<label>Expires In</label>
							</md-datepicker>
						</div>
					</div>
					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="create()" :disabled="sending || (expiresIn === null && !oneTime)">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
export default {
	name: 'CreateToken',
	data() {
		return {
			entity: null,
			description: null,
			oneTime: false,
			expiresIn: new Date().setDate(new Date().getDate() + 1),
			sending: false
		}
	},
	async mounted() {
		this.entity = this.$store.getters.getReleaseById(this.$route.params.eid)
	},
	methods: {
		close() {
			this.description = null
			this.oneTime = false
			this.expiresIn = null
			this.$router.back()
		},
		create() {
			this.$emit('create', this.entity, {
				oneTime: this.oneTime,
				description: this.description,
				expiresIn: this.expiresIn
			})
		}
	}
}
</script>