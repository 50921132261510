<template>
	<md-dialog :md-active="$route.name == 'editPCB'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="pcb.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="pcb.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field :class="getValidationClass('designSource')">
						<label for="designSource">Design source</label>
						<md-input name="designSource" id="designSource" v-model="pcb.designSource" required />
						<span class="md-error" v-if="!$v.pcb.designSource.required">Design source is required</span>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item md-size-80">
					<md-field>
						<label for="attachments">Attachments</label>
						<md-file id="attachmentInput" v-model="attachmentInput" multiple/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-button class="md-icon-button" style="margin-top: 10px" :disabled="attachmentInput === null" @click="addAttachments()">
						<md-icon>add</md-icon>
					</md-button>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item" v-for="attachment of attachments" :key="attachment.id">
					<table>
						<tr>
							<td><a :href="`${apiUrl}/attachment/${attachment.file}`" class="md-list-item-text">{{attachment.name}}</a></td>
							<td>
								<md-button class="md-icon-button md-accent" @click="deleteAttachment(attachment.id)">
									<md-icon>delete</md-icon>
								</md-button>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<md-chips v-model="pcb.tags" md-placeholder="Tags"></md-chips>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<dialog-link-table :links="pcb.links"/>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending || $v.$invalid">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'
import config from '@/../config'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		pcb: null,
		sending: false,
		attachments: [],
		attachmentInput: null,
		apiUrl: config.API_URL,
	}),
	mixins: [debounce, validationMixin],
	watch: {
		pcb: {
			deep: true,
			async handler() {
				await this.validatePCB()
			}
		}
	},
	validations: {
		pcb: {
			designSource: {
				required
			}
		}
	},
	async created() {
		this.pcb = this.$store.getters.getPcbById(this.$route.params.eid)
		this.pcb.tags = this.pcb.tags.map((tag) => tag.tag)
		this.validatePCB = this.debounce(this.validatePCB)
		this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcb', id: this.pcb.id })
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.pcb[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async updatePCB() {
			try {
				const tagIds = await this.$apiService.createTagsIfNotExist(this.pcb.tags)
				await this.$apiService.pcb.update(this.pcb.id, {
					description: this.pcb.description,
					tagIds,
					links: this.pcb.links,
					designSource: this.pcb.designSource
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validatePCB() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updatePCB()
			}
		},
		async deleteAttachment(id) {
			this.sending = true
			try {
				await this.$apiService.attachment.delete(id)
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcb', id: this.pcb.id })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async addAttachments() {
			this.sending = true
			try {
				let i
				for (i = 0; i < document.querySelector('#attachmentInput').files.length; i++) {
					const data = new FormData()
					data.append('name', document.querySelector('#attachmentInput').files[i].name)
					data.append('data', document.querySelector('#attachmentInput').files[i])
					await this.$apiService.attachment.createAttachment({ name: 'pcb', id: this.pcb.id }, data)
				}
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'pcb', id: this.pcb.id })
				this.attachmentInput = null
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async close() {
			try {
				await this.$store.dispatch('loadPCBs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
