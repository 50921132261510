<template>
	<div>
		<edit v-if="$route.name == 'editSkuGroup'"/>
		<create v-if="$route.name == 'createSkuGroup'"/>

		<e-table title="Sku Groups" singularTitle="Sku Group" :data="$store.getters.skuGroups" :filter="filter" :columns="columns" @delete="deleteSkuGroup" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Create from '@/components/SkuGroup/Create.vue'
import Edit from '@/components/SkuGroup/Edit.vue'

export default {
	name: 'SkuGroups',
	components: {
		'e-table': Table,
		'create': Create,
		'edit': Edit
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
				skus: {
					value: null,
					name: 'SKU',
					property: 'skus'
				},
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'skuGroupParents',
					label: 'Parents',
					render: (parents) => parents.map((p) => p.name).join(', ')
				}, {
					name: 'skus',
					label: 'SKUs',
					render: (skus) => (skus? skus.join(', ') : '')
				}
			]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadSkuGroups', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadSkuGroupParents')
			await this.$store.dispatch('loadProgrammingTypes')
			await this.$store.dispatch('loadNamespaces')
			await this.$store.dispatch('loadSWVariants')
			await this.$store.dispatch('loadHWConfigurations')
			await this.$store.dispatch('loadDeliveryVariants')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteSkuGroup(id) {
			try {
				await this.$apiService.skuGroup.delete(id)
				await this.$store.dispatch('loadSkuGroups', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async showSkuGroup(skuGroup) {
			this.selectedSkuGroup = Object.assign({}, skuGroup)
			this.showEdit = true
		}
	}
}
</script>