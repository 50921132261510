<template>
	<div>
		<md-dialog :md-active="['editRelease', 'editReleaseByVersion'].includes($route.name)" v-if="release" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
			<md-dialog-title>
				Details
			</md-dialog-title>
			<md-dialog-content>
				<md-tabs class="md-transparent">
					<md-tab id="information" md-label="Information">
						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field>
									<label for="skuGroups">Sku Groups</label>
									<md-input name="skuGroups" id="skuGroups" :value="release.skuTargetGroupsRaw" readonly/>
								</md-field>
							</div>
							<div class="md-layout-item md-size-30">
								<md-field>
									<label for="version">Version</label>
									<md-input name="version" id="version" v-model="release.version" disabled/>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="code">Code</label>
									<md-input name="code" id="code" v-model="release.code"/>
								</md-field>
							</div>
						</div>
						<div class="md-layout">
							<div class="md-layout-item">
								<md-field :class="getInfoValidationClass('notes')">
									<label for="notes">Notes</label>
									<md-textarea v-model="release.notes" required></md-textarea>
									<span class="md-error" v-if="!$v.release.notes.required">Notes are required</span>
								</md-field>
							</div>
						</div>
						<div class="md-layout">
							<div class="md-layout-item md-size-80">
								<md-field>
									<label for="attachments">Attachments</label>
									<md-file id="attachmentInput" v-model="attachmentInput" multiple/>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-button class="md-icon-button" style="margin-top: 10px" :disabled="attachmentInput === null" @click="addAttachments()">
									<md-icon>add</md-icon>
								</md-button>
							</div>
						</div>
						<div class="md-layout md-gutter">
							<div class="md-layout-item" v-for="attachment of attachments" :key="attachment.id">
								<table>
									<tr>
										<td><a :href="`${apiUrl}/attachment/${attachment.file}`" class="md-list-item-text">{{attachment.name}}</a></td>
										<td>
											<md-button class="md-icon-button md-accent" @click="deleteAttachment(attachment.id)">
												<md-icon>delete</md-icon>
											</md-button>
										</td>
									</tr>
								</table>
							</div>
						</div>
						<div class="md-layout">
							<div class="md-layout-item">
								<md-chips v-model="tags" md-placeholder="Tags"></md-chips>
							</div>
						</div>
						<div class="md-layout">
							<div class="md-layout-item">
								<dialog-link-table :links="release.links"/>
							</div>
						</div>
					</md-tab>
					<md-tab id="artifacts" md-label="Artifacts">
						<e-table singularTitle="Artifact" :data="release.artifacts" :columns="columns" :customActions="customActions" :readonly="true" :deletable="false" :editable="false"/>
					</md-tab>
					<md-tab id="conditions" md-label="Conditions">
						<rollout-tab v-if="release && release.consumers" @setConsumers="val => release.consumers = val" :consumers="release.consumers" :release="release"/>
					</md-tab>
				</md-tabs>
				<md-progress-bar md-mode="indeterminate" v-if="sending" />
				<md-dialog-actions>
					<md-button class="md-primary" @click="close()" :disabled="this.$v.release.$invalid">Close</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import RolloutTab from './RolloutTab.vue'
import Conditions from '../Conditions.vue'
import Table from '@/components/Common/Table'
import DialogLinkTable from '../../Common/DialogLinkTable.vue'
import debounce from '../../../mixins/debounce'

export default {
	name: 'Details',
	components: {
		'e-table': Table,
		'dialog-link-table': DialogLinkTable,
		'conditions': Conditions,
		'rollout-tab': RolloutTab,
	},
	mixins: [validationMixin, debounce],
	data: function() {
		return {
			release: null,
			sending: false,
			attachments: [],
			attachmentInput: null,
			columns: [
				{
					name: 'id',
					label: 'ID',
					render: (val) => val
				}, {
					name: 'version',
					label: 'Version',
					render: (val) => val
				}, {
					name: 'sWVariant',
					label: 'SW Variant',
					sortable: 'sWVariant.name',
					render: (val) => val.name
				}, {
					name: 'deliveryVariant',
					label: 'Delivery Variant',
					sortable: 'deliveryVariant.name',
					render: (val) => val.name
				}, {
					name: 'hWConfiguration',
					label: 'HW Configuration',
					sortable: 'hWConfiguration.name',
					render: (val) => val.name
				}
			],
			customActions: [{
				icon: 'download',
				tooltip: 'Download',
				handler: async (artifact) => {
					const token = await this.$apiService.artifact.getOneTimeToken(artifact)
					window.open(`${window.location.origin}/download?token=${token}`, '_blank')
				}
			}, {
				icon: 'code',
				tooltip: 'Go to source code',
				handler: async (artifact) => window.open(`${artifact.namespace.repository}/-/commit/${artifact.commit}`, '_blank')
			}],
		}
	},
	validations: {
		release: {
			notes: {
				required
			}
		}
	},
	watch: {
		'release.code': async function() {
			await this.updateData({ code: this.release.code })
		},
		'release.notes': async function() {
			await this.updateData({ notes: this.release.notes })
		},
		'release.links': async function() {
			await this.updateData({ links: this.release.links })
		},
		'release.consumers': async function() {
			await this.updateData({ consumerIds: this.release.consumers.map((c) => c.id) })
		},
	},
	computed: {
		tags: {
			get() {
				return this.release.tags.map((t) => t.tag)
			},
			async set(val) {
				const tagIds = await this.$apiService.createTagsIfNotExist(val)
				await this.updateData({ tagIds: tagIds })
			}
		}
	},
	async created() {
		try {
			let id
			if (this.$route.name === 'editRelease') {
				id = this.$route.params.eid
			} else {
				await this.$store.dispatch('loadReleases')
				const r = this.$store.getters.getReleaseByVersion(this.$route.params.version)
				id = r.id
			}
			this.release = await this.$apiService.release.getOne(id)
			await this.$store.dispatch('loadConditions')
			await this.$store.dispatch('loadRequests')
			this.release.consumers = this.release.consumers.map((c) => this.$store.getters.consumers.find((cc) => cc.id === c.id))
			this.attachments = await this.$apiService.attachment.getAttachments({ name: 'release', id: this.release.id })
			this.updateData = this.debounce(this.updateData)
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async close() {
			this.$router.push({ name: 'releases' })
			await this.$store.dispatch('loadReleases', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		},
		async updateData(data) {
			try {
				if (!this.$v.release.$invalid) {
					this.sending = true
					await this.$apiService.release.update(this.release.id, data)
				}
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		getInfoValidationClass(fieldName) {
			const field = this.$v.release[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async activateCondition(id, active) {
			await this.$apiService.condition.activate(id, active)
		},
		async deleteCondition(id) {
			this.sending = true
			try {
				await this.$apiService.condition.delete(id)
				this.release.conditions = this.release.conditions.filter((c) => c.id !== id)
				await this.$store.dispatch('loadReleases')
				this.$snackbar.showMessage('Condition deleted!')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async addCondition(rIds, condition) {
			try {
				const newC = await this.$apiService.condition.create({
					requestIds: rIds,
					condition: condition,
					releaseId: this.release.id
				})
				this.$snackbar.showMessage('Condition saved!')
				await this.$store.dispatch('loadReleases')
				this.release.conditions.push(newC)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async saveCondition(condition) {
			this.sending = true
			try {
				await this.$apiService.condition.update(condition.id, { condition: condition.condition })
				this.$snackbar.showMessage('Condition saved!')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async deleteAttachment(id) {
			this.sending = true
			try {
				await this.$apiService.attachment.delete(id)
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'release', id: this.release.id })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async addAttachments() {
			this.sending = true
			try {
				let i
				for (i = 0; i < document.querySelector('#attachmentInput').files.length; i++) {
					const data = new FormData()
					data.append('name', document.querySelector('#attachmentInput').files[i].name)
					data.append('data', document.querySelector('#attachmentInput').files[i])
					await this.$apiService.attachment.createAttachment({ name: 'release', id: this.release.id }, data)
				}
				this.attachments = await this.$apiService.attachment.getAttachments({ name: 'release', id: this.release.id })
				this.attachmentInput = null
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		}
	}
}
</script>
