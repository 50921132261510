var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-app',[_c('md-app-toolbar',{staticClass:"md-transparent",attrs:{"md-elevation":"1"}},[_c('toolbar')],1),_c('md-app-drawer',{attrs:{"md-permanent":"clipped"}},[_c('md-list',[_c('md-list-item',{attrs:{"to":{ name: 'CommitOverview' },"exact":""}},[_vm._v("Overview")]),(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'commitdetails';
}))?_c('md-list-item',{attrs:{"to":{ name: 'commitdetails' },"exact":"","event":""}},[_vm._v(_vm._s(_vm.commit))]):_vm._e()],1),_c('md-list',[_c('md-list-item',{attrs:{"to":{ name: 'CommitForReceivers' },"exact":""}},[_vm._v("For Receiver")]),(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'CommitForReceiversDetails';
}))?_c('md-list-item',{attrs:{"to":{ name: 'CommitForReceiversDetails' },"exact":"","event":""}},[_vm._v(_vm._s(_vm.receiver))]):_vm._e()],1)],1),_c('md-app-content',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }