<template>
	<div>
		<edit v-if="$route.name == 'editPipelineTrigger'"/>
		<create v-if="$route.name == 'createPipelineTrigger'"/>
		<e-table title="Pipeline Trigger" singularTitle="Pipeline Trigger" :data="$store.getters.pipelineTrigger" :filter="pipelineTrigger.filter" :columns="pipelineTrigger.columns" @delete="deletePipelineTrigger" :customActions="pipelineTrigger.customActions" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import CreatePipelineTrigger from '@/components/PipelineTrigger/Create.vue'
import EditPipelineTrigger from '@/components/PipelineTrigger/Edit.vue'

export default {
	name: 'PipelineTrigger',
	components: {
		'e-table': Table,
		'create': CreatePipelineTrigger,
		'edit': EditPipelineTrigger
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			pipelineAuthToken: '',
			pipelineTrigger: {
				columns: [
					{
						name: 'id',
						label: 'ID'
					}, {
						name: 'url',
						label: 'Url'
					}, {
						name: 'ref',
						label: 'Ref'
					}, {
						name: 'token',
						label: 'Token',
						render: () => '****************'
					}, {
						name: 'description',
						label: 'Description'
					}
				]
			}
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadPipelineTrigger', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deletePipelineTrigger(id) {
			try {
				await this.$apiService.pipelineTrigger.delete(id)
				await this.$store.dispatch('loadPipelineTrigger', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async showPipelineTrigger(pipelineTrigger) {
			this.selectedPipelineTrigger = pipelineTrigger
			this.showEdit = true
		}
	}
}
</script>