<template>
	<md-dialog :md-active="$route.name == 'editRequestField'" v-if="requestField" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="requestField.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="requestField.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-40">
					<md-field>
						<label>Type</label>
						<md-select v-model="requestField.type" name="type" id="type" required>
							<md-option v-for="(field, id) in types" :key="id" :value="field.value">{{field.text}}</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="default">Default</label>
						<md-input name="default" id="default" v-model="defaultVal" :disabled="requestField.array" />
					</md-field>
				</div>
				<div class="md-layout-item md-size-40">
					<md-field>
						<label>Origin</label>
						<md-select v-model="requestField.requestType" name="origin" id="origin" required>
							<md-option value="client">Client</md-option>
							<md-option value="server">Server</md-option>
						</md-select>
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-60">
					<md-field>
						<label for="allowedValues">Allowed values (comma separated)</label>
						<md-input name="allowedValues" id="allowedValues" v-model="allowedValues" :disabled="requestField.type === 'boolean' || requestField.type === 'Version' || requestField.array" />
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-checkbox v-model="requestField.optional">Optional</md-checkbox>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'
import { FieldType } from '@/@types/interfaces'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false,
		requestField: null,
		origAllowedValues: [],
		defaultVal: null,
		allowedValues: [],
		types: [
			{text: 'String', value: 'string'},
			{text: 'Number', value: 'number'},
			{text: 'Boolean', value: 'boolean'},
			{text: 'Version', value: 'Version'}
		]
	}),
	mixins: [debounce],
	watch: {
		requestField: {
			deep: true,
			async handler() {
				await this.update()
			}
		},
		defaultVal: {
			deep: true,
			async handler() {
				await this.update()
			}
		},
		allowedValues: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadRequestFields')
		this.requestField = this.$store.getters.getRequestFieldById(this.$route.params.eid)
		switch(this.requestField.type) {
			case FieldType.string:
				this.defaultVal = this.requestField.defaultString
				this.allowedValues = (this.requestField.allowedValuesString? this.requestField.allowedValuesString.join(',') : '')
				break
			case FieldType.number:
				this.defaultVal = this.requestField.defaultNumber
				this.allowedValues = (this.requestField.allowedValuesNumber? this.requestField.allowedValuesNumber.join(',') : '')
				break
			case FieldType.version:
				this.defaultVal = this.requestField.defaultVersion
				this.allowedValues = (this.requestField.allowedValuesVersion? this.requestField.allowedValuesVersion.join(',') : '')
				break
			case FieldType.boolean:
				this.defaultVal = this.requestField.defaultBoolean
				this.allowedValues = (this.requestField.allowedValuesBoolean? this.requestField.allowedValuesBoolean.join(',') : '')
				break
		}
		this.origAllowedValues = this.allowedValues
		this.update = this.debounce(this.update)
	},
	methods: {
		async updateRequestField() {
			this.sending = true
			try {
				const payload = {
					description: this.requestField.description,
					optional: this.requestField.optional,
					array: this.requestField.array,
					type: this.requestField.type
				}

				switch(this.requestField.type) {
					case FieldType.string:
						payload.defaultString = this.defaultVal
						payload.allowedValuesString = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.number:
						payload.defaultNumber = parseInt(this.defaultVal)
						payload.allowedValuesNumber = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => parseInt(val)): null)
						break
					case FieldType.version:
						payload.defaultVersion = this.defaultVal
						payload.allowedValuesVersion = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.boolean:
						payload.defaultBoolean = this.defaultVal
						payload.allowedValuesBoolean = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => val === 'true'): null)
						break
				}
				if (this.origAllowedValues.length > 0 && this.allowedValues.length === 0) {
					payload.allowedValuesString = null
					payload.allowedValuesNumber = null
					payload.allowedValuesVersion = null
					payload.allowedValuesBoolean = null
				}
				await this.$apiService.requestField.update(this.requestField.id, payload, true)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateRequestField()
		},
		async close() {
			try {
				await this.$store.dispatch('loadRequestFields', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
