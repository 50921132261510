<template>
	<md-dialog :md-active="$route.name == 'editSWVariant'" v-if="sWVariant" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="sWVariant.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="sWVariant.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label>Manifest fields</label>
						<md-select v-model="sWVariant.manifestFields" name="manifestFields" id="manifestFields" multiple>
							<md-option v-for="field in $store.getters.manifestFields" :key="field.id" :value="field.id">{{field.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<md-chips v-model="sWVariant.tags" md-placeholder="Tags"></md-chips>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
				<dialog-link-table :links="sWVariant.links"/>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false,
		sWVariant: null
	}),
	mixins: [debounce],
	watch: {
		sWVariant: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	created() {
		this.sWVariant = this.$store.getters.getSWVariantById(this.$route.params.eid)
		this.sWVariant.manifestFields = this.sWVariant.manifestFields.map((mF) => mF.id)
		this.sWVariant.tags = this.sWVariant.tags.map((tag) => tag.tag)
		this.update = this.debounce(this.update)
	},
	methods: {
		async updateSWVariant() {
			this.sending = true
			try {
				const tagIds = await this.$apiService.createTagsIfNotExist(this.sWVariant.tags)
				await this.$apiService.sWVariant.update(this.sWVariant.id, {
					description: this.sWVariant.description,
					tagIds,
					links: this.sWVariant.links,
					manifestFieldIds: this.sWVariant.manifestFields
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateSWVariant()
		},
		async close() {
			try {
				await this.$store.dispatch('loadSWVariants', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
