<template>
	<div>
		<edit v-if="$route.name == 'editHWConfiguration'"/>
		<create v-if="$route.name == 'createHWConfiguration'"/>
		<confirm-delete v-if="$route.name == 'deleteHWConfiguration'" @onCancel="$router.back()" @onConfirm="deleteHWConfiguration" :itemName="deleteItemName" />

		<e-table title="HW Configurations" singularTitle="HW Configuration" :data="$store.getters.hWConfigurations" :filter="filter" :columns="columns" @delete="confirmDelete" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/HWConfiguration/Edit'
import Create from '@/components/HWConfiguration/Create'
import Table from '@/components/Common/Table'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'

export default {
	name: 'HWConfigurations',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table,
		'confirm-delete': ConfirmDelete
	},
	data: () => ({
		sortBy: 'id',
		sortOrder: 'DESC',
		deleteItemName: null,
		filter: {
			name: {
				value: null,
				name: 'Name',
				property: 'name'
			},
			pcba: {
				value: null,
				name: 'PCBA',
				property: 'pcba.name'
			},
			tag: {
				value: [],
				name: 'Tag'
			}
		},
		columns: [
			{
				name: 'id',
				label: 'ID'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'description',
				label: 'Description'
			}, {
				name: 'tags',
				label: 'Tags',
				sortable: false
			}
		]
	}),
	async created() {
		try {
			await this.$store.dispatch('loadHWConfigurations', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadManifestFields')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async confirmDelete(id) {
			const hwc = await this.$store.getters.getHWConfigurationById(id)
			this.deleteItemName = `${hwc.name}`
			this.$router.push({name: 'deleteHWConfiguration', params: {eid: id}})
		},
		async deleteHWConfiguration() {
			try {
				await this.$apiService.hWConfiguration.delete(this.$route.params.eid)
				await this.$store.dispatch('loadHWConfigurations', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
