<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createRequestField'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item md-size-40">
							<md-field :class="getValidationClass('type')">
								<label>Type</label>
								<md-select v-model="type" name="type" id="type" required>
									<md-option v-for="(field, id) in types" :key="id" :value="field.value">{{field.text}}</md-option>
								</md-select>
								<span class="md-error" v-if="!$v.type.required">Type is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label for="default">Default</label>
								<md-input name="default" id="default" v-model="defaultVal" :disabled="sending" />
							</md-field>
						</div>
						<div class="md-layout-item md-size-40">
							<md-field :class="getValidationClass('origin')">
								<label>Origin</label>
								<md-select v-model="origin" name="origin" id="origin" required>
									<md-option value="client">Client</md-option>
									<md-option value="server">Server</md-option>
								</md-select>
								<span class="md-error" v-if="!$v.origin.required">Origin is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item md-size-60">
							<md-field>
								<label for="allowedValues">Allowed values (comma separated)</label>
								<md-input name="allowedValues" id="allowedValues" v-model="allowedValues" :disabled="sending || type === 'boolean' || type === 'Version'" />
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-checkbox v-model="optional">Optional</md-checkbox>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateRequestField()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import DialogLinkTable from '../Common/DialogLinkTable.vue'
import { FieldType } from '@/@types/interfaces'

export default {
	name: 'Create',
	mixins: [validationMixin],
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		name: null,
		description: null,
		type: null,
		optional: false,
		defaultVal: null,
		allowedValues: '',
		sending: false,
		types: [
			{text: 'String', value: 'string'},
			{text: 'Number', value: 'number'},
			{text: 'Boolean', value: 'boolean'},
			{text: 'Version', value: 'Version'}
		],
		origin: null
	}),
	validations: {
		name: {
			required
		},
		type: {
			required
		},
		origin: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.description = null
			this.type = null
			this.optional = null
			this.defaultVal = null
			this.origin = null
			this.allowedValues = ''
			this.sending = false
		},
		async createRequestField() {
			this.sending = true

			try {
				const payload = {
					name: this.name,
					description: this.description,
					optional: this.optional,
					requestType: this.origin,
					type: this.type
				}

				switch(this.type) {
					case FieldType.string:
						payload.defaultString = this.defaultVal
						payload.allowedValuesString = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.number:
						payload.defaultNumber = parseInt(this.defaultVal)
						payload.allowedValuesNumber = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => parseInt(val)): null)
						break
					case FieldType.version:
						payload.defaultVersion = this.defaultVal
						payload.allowedValuesVersion = (this.allowedValues.length > 0? this.allowedValues.split(','): null)
						break
					case FieldType.boolean:
						payload.defaultBoolean = this.defaultVal
						payload.allowedValuesBoolean = (this.allowedValues.length > 0? this.allowedValues.split(',').map((val) => val === 'true'): null)
						break
				}
				await this.$apiService.requestField.create(payload)
				this.$snackbar.showMessage('Request Field saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateRequestField() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createRequestField()
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			try {
				await this.$store.dispatch('loadRequestFields', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
