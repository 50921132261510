import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HWConfigurations from '../views/Namespace/HWConfigurations.vue'
import SWVariants from '../views/Namespace/SWVariants.vue'
import DeliveryVariants from '../views/Namespace/DeliveryVariants.vue'
import ManifestFields from '../views/Settings/ManifestFields.vue'
import RequestFields from '../views/Settings/RequestFields.vue'
import SkuGroups from '../views/SKUManagement/SkuGroup.vue'
import SkuTree from '../views/SKUManagement/SkuTree.vue'
import Requests from '../views/Settings/Requests.vue'
import Artifacts from '../views/Namespace/Artifacts.vue'
import ReleasesHome from '../views/Releases/Home.vue'
import SKUManagement from '../views/SKUManagement/Home.vue'
import Releases from '../views/Releases/Releases.vue'
import Test from '../views/Test.vue'
import Settings from '../views/Settings/Home.vue'
import Download from '../views/Download.vue'
import Upload from '../views/Upload.vue'
import Namespace from '../views/Namespace/Home.vue'
import Forbidden from '../views/Forbidden.vue'
import NotFound from '../views/NotFound.vue'
import PipelineTrigger from '../views/Settings/PipelineTrigger.vue'
import Namespaces from '../views/Settings/Namespaces.vue'
import Consumers from '../views/Settings/Consumers.vue'
import Token from '../views/Settings/Token.vue'
import Actions from '../views/Settings/Actions.vue'
import ProgrammingTypes from '../views/Settings/ProgrammingTypes.vue'
import PCBs from '../views/Namespace/PCBs.vue'
import PCBAs from '../views/Namespace/PCBAs.vue'
import CommitHome from '../views/Commits/Home.vue'
import CommitOverview from '../views/Commits/Overview.vue'
import CommitDetails from '../views/Commits/Details.vue'
import CommitForReceivers from '../views/Commits/Receivers.vue'
import CommitForReceiverDetails from '../views/Commits/ReceiverDetails.vue'
import ArtifactReceivers from '../views/Settings/ArtifactReceivers.vue'
import { authGuard, onlyAdmin } from '@/lib/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: 'releases',
		beforeEnter: authGuard
	},
	{
		path: '/forbidden',
		name: 'forbidden',
		component: Forbidden
	},
	{
		path: '/download',
		name: 'download',
		component: Download
	},
	{
		path: '/upload',
		name: 'upload',
		component: Upload,
		beforeEnter: async (to: any, from: any, next: any) => {
			return authGuard(to, from, async () => {
				onlyAdmin(to, from, next)
			})
		}
	},
	{
		path: '/skuManagement',
		name: 'skuManagement',
		component: SKUManagement,
		beforeEnter: async (to: any, from: any, next: any) => {
			return authGuard(to, from, async () => {
				onlyAdmin(to, from, next)
			})
		},
		children: [
			{
				path: '',
				redirect: 'skuGroups'
			},
			{
				path: 'skuGroups',
				name: 'skuGroups',
				beforeEnter: onlyAdmin,
				component: SkuGroups
			},
			{
				path: 'skuGroups/:eid/edit',
				name: 'editSkuGroup',
				component: SkuGroups
			},
			{
				path: 'skuGroups/create',
				name: 'createSkuGroup',
				component: SkuGroups
			},
			{
				path: 'skuTree',
				name: 'skuTree',
				beforeEnter: onlyAdmin,
				component: SkuTree
			},
			{
				path: 'skuTree/:eid/edit',
				name: 'editSkuTree',
				component: SkuTree
			},
			{
				path: 'skuTree/create',
				name: 'createSkuTree',
				component: SkuTree
			}
		]
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		beforeEnter: async (to: any, from: any, next: any) => {
			return authGuard(to, from, async () => {
				onlyAdmin(to, from, next)
			})
		},
		children: [
			{
				path: '',
				redirect: 'namespaces'
			},
			{
				path: 'pipelineTrigger',
				name: 'pipelineTrigger',
				component: PipelineTrigger
			},
			{
				path: 'pipelineTrigger/:eid/edit',
				name: 'editPipelineTrigger',
				component: PipelineTrigger
			},
			{
				path: 'pipelineTrigger/create',
				name: 'createPipelineTrigger',
				component: PipelineTrigger
			},
			{
				path: 'namespaces',
				name: 'namespaces',
				component: Namespaces
			},
			{
				path: 'namespaces/:eid/edit',
				name: 'editNamespace',
				component: Namespaces
			},
			{
				path: 'namespaces/create',
				name: 'createNamespace',
				component: Namespaces
			},
			{
				path: 'artifact-receivers',
				name: 'artifact-receivers',
				component: ArtifactReceivers,
			},
			{
				path: 'lrdToken',
				name: 'lrdToken',
				component: Token
			},
			{
				path: 'accessToken/create',
				name: 'createAccessToken',
				component: Token
			},
			{
				path: 'consumers',
				name: 'consumers',
				component: Consumers
			},
			{
				path: 'consumers/:eid/edit',
				name: 'editConsumer',
				component: Consumers
			},
			{
				path: 'consumers/create',
				name: 'createConsumer',
				component: Consumers
			},
			{
				path: 'programmingTypes',
				name: 'programmingTypes',
				component: ProgrammingTypes
			},
			{
				path: 'programmingTypes/:eid/edit',
				name: 'editProgrammingType',
				component: ProgrammingTypes
			},
			{
				path: 'manifestFields',
				name: 'manifestFields',
				beforeEnter: onlyAdmin,
				component: ManifestFields
			},
			{
				path: 'manifestFields/:eid/edit',
				name: 'editManifestField',
				component: ManifestFields
			},
			{
				path: 'manifestFields/create',
				name: 'createManifestField',
				component: ManifestFields
			},
			{
				path: 'requests',
				name: 'requests',
				beforeEnter: onlyAdmin,
				component: Requests
			},
			{
				path: 'requests/:eid/edit',
				name: 'editRequest',
				beforeEnter: onlyAdmin,
				component: Requests
			},
			{
				path: 'requests/create',
				name: 'createRequest',
				beforeEnter: onlyAdmin,
				component: Requests
			},
			{
				path: 'requestFields',
				name: 'requestField',
				beforeEnter: onlyAdmin,
				component: RequestFields
			},
			{
				path: 'requestFields/:eid/edit',
				name: 'editRequestField',
				beforeEnter: onlyAdmin,
				component: RequestFields
			},
			{
				path: 'requestFields/create',
				name: 'createRequestField',
				beforeEnter: onlyAdmin,
				component: RequestFields
			},
			{
				path: 'actions',
				name: 'actions',
				beforeEnter: onlyAdmin,
				component: Actions
			}
		]
	},
	{
		path: '/releases',
		name: 'releasesHome',
		component: ReleasesHome,
		beforeEnter: authGuard,
		children: [
			{
				path: '',
				redirect: 'releases'
			},
			{
				path: 'releases',
				name: 'releases',
				component: Releases
			},
			{
				path: 'releases/:eid/edit',
				name: 'editRelease',
				component: Releases
			},
			{
				path: 'releases/by-version/:version',
				name: 'editReleaseByVersion',
				component: Releases
			},
			{
				path: 'releases/create',
				name: 'createRelease',
				component: Releases
			},
			{
				path: 'releases/:eid/token/show',
				name: 'showReleaseToken',
				component: Releases
			},
			{
				path: 'releases/:eid/token/create',
				name: 'createReleaseToken',
				component: Releases
			},
			{
				path: 'releases/:eid/download',
				name: 'downloadRelease',
				component: Releases
			},
			{
				path: 'releases/:eid/delete',
				name: 'deleteRelease',
				component: Releases
			},
			{
				path: 'releases/:eid/disable-condition',
				name: 'disableConditionsForRelease',
				component: Releases
			}
		]
	},
	{
		path: '/namespace',
		name: 'namespace',
		component: Namespace,
		beforeEnter: async (to: any, from: any) => {
			return authGuard(to, from, async () => {
				if (store.getters.namespaces.length === 0) {
					try {
						await store.dispatch('loadNamespaces')
					} catch (e) {
						return
					}
				}

				if (store.getters.namespaces.length > 0) {
					window.location.href = `/namespace/${store.getters.namespaces[0].id}/artifacts`
				}
			})
		}
	},
	{
		path: '/namespace/:id',
		component: Namespace,
		beforeEnter: authGuard,
		children: [
			{
				path: '',
				redirect: 'artifacts'
			},
			{
				path: 'pcbs',
				name: 'pcbs',
				component: PCBs
			},
			{
				path: 'pcbs/:eid/edit',
				name: 'editPCB',
				component: PCBs
			},
			{
				path: 'pcbs/create',
				name: 'createPCB',
				component: PCBs
			},
			{
				path: 'pcbas',
				name: 'pcbas',
				component: PCBAs
			},
			{
				path: 'pcbas/:eid/edit',
				name: 'editPCBA',
				component: PCBAs
			},
			{
				path: 'pcbas/create',
				name: 'createPCBA',
				component: PCBAs
			},
			{
				path: 'hWConfigurations',
				name: 'HWConfigurations',
				component: HWConfigurations
			},
			{
				path: 'hWConfigurations/:eid/edit',
				name: 'editHWConfiguration',
				component: HWConfigurations
			},
			{
				path: 'hWConfigurations/:eid/delete',
				name: 'deleteHWConfiguration',
				component: HWConfigurations
			},
			{
				path: 'hWConfigurations/create',
				name: 'createHWConfiguration',
				component: HWConfigurations
			},
			{
				path: 'sWVariants',
				name: 'sWVariants',
				component: SWVariants
			},
			{
				path: 'sWVariants/:eid/edit',
				name: 'editSWVariant',
				component: SWVariants
			},
			{
				path: 'sWVariants/create',
				name: 'createSWVariant',
				component: SWVariants
			},
			{
				path: 'sWVariants/:eid/delete',
				name: 'deleteSWVariant',
				component: SWVariants
			},
			{
				path: 'deliveryVariants',
				name: 'deliveryVariants',
				component: DeliveryVariants
			},
			{
				path: 'deliveryVariants/:eid/edit',
				name: 'editDeliveryVariant',
				component: DeliveryVariants
			},
			{
				path: 'deliveryVariants/create',
				name: 'createDeliveryVariant',
				component: DeliveryVariants
			},
			{
				path: 'deliveryVariants/:eid/delete',
				name: 'deleteDeliveryVariant',
				component: DeliveryVariants
			},
			{
				path: 'artifacts',
				name: 'artifacts',
				component: Artifacts
			},
			{
				path: 'artifacts/:eid/details',
				name: 'artifactDetails',
				component: Artifacts
			},
			{
				path: 'artifacts/download-by-commit',
				name: 'downloadArtifactsByCommit',
				component: Artifacts
			},
			{
				path: 'artifacts/show-token',
				name: 'showDownloadToken',
				component: Artifacts
			},
			{
				path: 'test',
				name: 'test',
				beforeEnter: onlyAdmin,
				component: Test
			}
		]
	},
	{
		name: 'CommitHome',
		path: '/commit',
		component: CommitHome,
		beforeEnter: authGuard,
		children: [
			{
				name: 'CommitOverview',
				path: '/commit',
				component: CommitOverview,
				beforeEnter: authGuard,
			},
			{
				name: 'commitdetails',
				path: '/commit/:commit',
				component: CommitDetails,
				beforeEnter: authGuard,
			},
			{
				name: 'CommitForReceivers',
				path: '/commit/released-for',
				component: CommitForReceivers,
				beforeEnter: authGuard,
			},
			{
				name: 'CommitForReceiversDetails',
				path: '/commit/released-for/:receiver',
				component: CommitForReceiverDetails,
				beforeEnter: authGuard,
			},
		]
	},
	{
		path: "*",
		component: NotFound
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
