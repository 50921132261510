<template>
	<md-dialog :md-active="$route.name == 'editRequest'" v-if="request" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="request.name" readonly required/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label>Request fields</label>
						<md-select v-model="request.requestFields" name="requestFields" id="requestFields" multiple>
							<md-option v-for="field in $store.getters.requestFields" :key="field.id" :value="field.id">{{field.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label>Consumer</label>
						<md-select v-model="request.consumers" name="consumer" id="consumer" multiple>
							<md-option v-for="c in $store.getters.consumers" :key="c.id" :value="c.id">{{c.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>
			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Edit',
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false,
		request: null
	}),
	mixins: [debounce],
	watch: {
		request: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadRequests')
		this.request = this.$store.getters.getRequestById(this.$route.params.eid)
		this.request.requestFields = this.request.requestFields.map((rF) => rF.id)
		this.request.consumers = this.request.consumers.map((c) => c.id)
		this.update = this.debounce(this.update)
	},
	methods: {
		async updateRequest() {
			this.sending = true
			try {
				await this.$apiService.request.update(this.request.id, {
					requestFieldIds: this.request.requestFields,
					consumerIds: this.request.consumers
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateRequest()
		},
		async close() {
			try {
				await this.$store.dispatch('loadRequests', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
