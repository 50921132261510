<template>
	<div>
		<edit v-if="$route.name == 'editRequest'"/>
		<create v-if="$route.name == 'createRequest'"/>

		<e-table title="Requests" singularTitle="Request" :data="$store.getters.requests" :filter="filter" :columns="columns" @delete="deleteRequest" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/Request/Edit'
import Create from '@/components/Request/Create'
import Table from '@/components/Common/Table'

export default {
	name: 'Requests',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				}
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'consumers',
					label: 'Consumers',
					sortable: false,
					render: (val) => (val? val.map((c) => c.name).join(', ') : '')
				}, {
					name: 'tags',
					label: 'Tags',
					sortable: false
				}
			]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadRequests', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadRequestFields')
			await this.$store.dispatch('loadConsumers')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteRequest(id) {
			try {
				await this.$apiService.request.delete(id)
				await this.$store.dispatch('loadRequests', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
