<template>
	<div>
		<h1 class="md-title">
			<div>Artifact Receivers</div>
		</h1>
		<form novalidate class="md-layout">
			<md-dialog :md-active.sync="showCreate">
				<md-dialog-title>Create new artifact receiver in {{ activeTab }}</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required />
								<span class="md-error" v-if="!$v.name.required">Namespace name is required</span>
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="cancel">Cancel</md-button>
						<md-button type="submit" v-if="id !== null" @click="update" :disabled="sending || nameEmpty">Save</md-button>
						<md-button type="submit" v-else @click="create" :disabled="sending || nameEmpty">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
		<confirm-delete v-if="name" :showConfirm="showConfirmDelete" @onCancel="showConfirmDelete = false" @onConfirm="confirmedDelete" :itemName="name" />

		<md-tabs @md-changed="changeTab" :md-active-tab="activeTab">
			<md-tab v-for="track in tracks" :id="track" :md-label="track" :key="track">
				<md-table v-model="filteredData" :md-sort.sync="sortBy" :md-sort-order.sync="sortOrder">
					<md-table-toolbar v-if="allowedToEdit">
						<div class="md-toolbar-row">
							<div class="md-toolbar-section-end">
								<md-button @click="showCreateDialog" class="md-icon-button md-primary">
									<md-icon>add</md-icon>
								</md-button>
							</div>
						</div>
					</md-table-toolbar>
					<md-table-empty-state :md-label="`No artifact receivers found`" :md-description="`Try a different track or create one.`"> </md-table-empty-state>

					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<!-- <md-table-cell key="id" md-label="ID" md-sort-by="id" :md-numeric="true">
							{{ item.id }}
						</md-table-cell> -->
						<md-table-cell key="name" md-label="Name" md-sort-by="name">
							{{ item.name }}
						</md-table-cell>

						<md-table-cell md-label="Actions" v-if="allowedToEdit" style="white-space: nowrap">
							<md-button class="md-icon-button" @click="rename(item.id)">
								<md-icon>edit</md-icon>
								<md-tooltip md-direction="top">Rename</md-tooltip>
							</md-button>
							<md-button class="md-icon-button md-accent" @click="deleteButton(item.id)">
								<md-icon>delete</md-icon>
								<md-tooltip md-direction="top">Delete</md-tooltip>
							</md-button>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</md-tab>
		</md-tabs>
	</div>
</template>


<script>
import Toolbar from '@/components/Common/Toolbar.vue'
import { trackNames, Track } from '@/@types/database-enums'
import { getLocalUser } from '@/lib/auth'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'

export default {
	name: 'ArtifactReceivers',
	mixins: [validationMixin],
	components: { toolbar: Toolbar, 'confirm-delete': ConfirmDelete },
	data: function () {
		return {
			sortBy: 'name',
			sortOrder: 'asc',
			activeTab: Track.dev,
			filteredData: [],
			showCreate: false,
			showConfirmDelete: false,
			id: null,
			name: null,
			sending: false,
		}
	},
	validations: {
		name: {
			required,
		},
	},
	computed: {
		allowedToEdit() {
			return getLocalUser().roles.includes('admin')
		},
		receivers: function () {
			return this.$store.getters.artifactReceivers
		},
		tracks() {
			return trackNames
		},
		nameEmpty() {
			if (!this.name) {
				return true
			}
			return this.name.trim().length === 0
		},
	},
	watch: {
		receivers: {
			handler() {
				this.applyFilter()
			},
			deep: true,
		},
		activeTab: {
			handler() {
				this.applyFilter()
			},
		},
	},
	async created() {
		try {
			// TODO: fix pagination
			await this.$store.dispatch('loadArtifactReceivers', { all: true })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		changeTab(tab) {
			this.activeTab = tab
		},
		applyFilter() {
			this.filteredData = this.receivers.filter(x => x.track === this.activeTab)
		},
		showCreateDialog() {
			this.$v.$reset()
			this.id = null
			this.name = null
			this.showCreate = true
		},
		rename(id) {
			this.$v.$reset()
			this.id = id
			this.name = this.receivers.find(x => x.id === id).name
			this.showCreate = true
		},
		deleteButton(id) {
			this.id = id
			this.name = this.receivers.find(x => x.id === id).name
			this.showConfirmDelete = true
		},
		async confirmedDelete() {
			this.showConfirmDelete = false

			try {
				await this.$apiService.artifactReceiver.delete(this.id)
				await this.$store.dispatch('loadArtifactReceivers', { all: true })
				this.$snackbar.showMessage('Artifact Receiver deleted!')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		cancel() {
			this.showCreate = false
		},
		async create() {
			this.name = this.name.trim()

			this.$v.$touch()

			if (this.$v.$invalid) {
				return
			}

			this.sending = true

			try {
				const newEntry = await this.$apiService.artifactReceiver.create({ name: this.name, track: this.activeTab })
				await this.$store.commit('setArtifactReceivers', newEntry)

				this.$snackbar.showMessage('Artifact Receiver saved!')
				this.showCreate = false
			} catch (e) {
				this.$snackbar.showMessage(e)
				await this.$store.dispatch('loadArtifactReceivers', { all: true })
			}
			this.sending = false
		},
		async update() {
			this.name = this.name.trim()

			this.$v.$touch()

			if (this.$v.$invalid) {
				return
			}
			if (!this.$v.$dirty) {
				return
			}

			this.sending = true

			try {
				const updatedEntry = await this.$apiService.artifactReceiver.update(this.id, { name: this.name })
				await this.$store.commit('setArtifactReceivers', updatedEntry)

				this.$snackbar.showMessage('Artifact Receiver updated!')
				this.showCreate = false
			} catch (e) {
				this.$snackbar.showMessage(e)
				await this.$store.dispatch('loadArtifactReceivers', { all: true })
			}
			this.sending = false
		},
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty,
				}
			}
		},
	},
}
</script>
