<template>
	<div>
		<md-dialog :md-active="$route.name == 'disableConditionsForRelease'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('onCancel')" @keydown.esc="$emit('onCancel')">
			<md-dialog-title>Confirm</md-dialog-title>
			<md-dialog-content>
				You are about to deactivate all conditions on this release. This include the following requests:
				<div>
					<ul>
						<li v-for="r in requests" :key="r.id">{{r.name}}</li>
					</ul>
				</div>
				<md-dialog-actions>
					<md-button class="md-primary" @click="$emit('onCancel')">Abort</md-button>
					<md-button class="md-primary md-accent" @click="$emit('onConfirm', release)" >Proceed</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
export default {
	name: 'ConfirmDisabledConditions',
	data: () => ({
		release: null
	}),
	computed: {
		requests() {
			if (!this.release) {
				return []
			}
			const r = []
			for (let c of this.release.conditions) {
				r.push(c.request)
			}
			return r
		}
	},
	async created() {
		this.release = await this.$apiService.release.getOne(this.$route.params.eid)
	}
}
</script>