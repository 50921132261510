<template>
	<div>
		<form novalidate class="md-layout">
			<md-dialog :md-active="$route.name == 'createSkuGroup'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close" class="package-dialog">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<md-tabs class="md-transparent" @md-changed="(val) => currentPt = val">
						<md-tab id="information" md-label="Information">
							<div class="md-layout md-gutter">
								<div class="md-layout-item">
									<md-field :class="getValidationClass('name')">
										<label for="name">Name</label>
										<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
										<span class="md-error" v-if="!$v.name.required">Sku Group name is required</span>
									</md-field>
								</div>
								<div class="md-layout-item">
									<md-field>
										<label for="parents">Parents</label>
										<md-select v-model="parentIds" name="parentIds" id="parentIds" multiple>
											<md-option v-for="i in $store.getters.skuGroupParents" :key="i.id" :value="i.id">{{i.name}}</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout">
								<div class="md-layout-item">
									<md-field>
										<label>SKUs</label>
										<md-textarea v-model="skus" required @input="onSkuInputChange"></md-textarea>
									</md-field>
								</div>
							</div>
						</md-tab>
						<md-tab v-for="pT of $store.getters.programmingTypes" :key="pT.id" :id="`pt-${pT.id}`" :md-label="pT.name">
							<div class="md-layout md-gutter">
								<div class="md-layout-item md-size-30">
									<md-autocomplete v-model="selectedNamespace" :md-options="$store.getters.namespaces.map(x => x.name)" :md-fuzzy-search="false" required>
										<label>Namespace</label>
										<template slot="md-autocomplete-item" slot-scope="{ item, term }">
											<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
										</template>
										<template slot="md-autocomplete-empty">
											Not found!
										</template>
									</md-autocomplete>
								</div>
								<div class="md-layout-item md-size-30">
									<md-button class="md-primary" style="margin-top: 15px" :disabled="!selectedNamespace" @click="addAllTripplesForNamespace()">
										Add
										<md-tooltip md-direction="top">Add all existing tripples</md-tooltip>
									</md-button>
								</div>
							</div>
							<md-table>
								<md-table-row>
									<md-table-head>Namespace</md-table-head>
									<md-table-head>HW Configuration</md-table-head>
									<md-table-head>SW Variant</md-table-head>
									<md-table-head>Delivery Variant</md-table-head>
								</md-table-row>
								<md-table-row v-for="(tripple, i) of pTPackages.filter((p) => pT.id === p.programmingTypeId)" :key="i">
									<md-table-cell>
										{{tripple.namespace.name}}
									</md-table-cell>
									<md-table-cell>
										{{tripple.hWConfiguration.name}}
									</md-table-cell>
									<md-table-cell>
										{{tripple.sWVariant.name}}
									</md-table-cell>
									<md-table-cell>
										{{tripple.deliveryVariant.name}}
									</md-table-cell>
									<md-table-cell>
										<md-button class="md-icon-button md-accent" @click="deleteTripple(i)">
											<md-icon>delete</md-icon>
										</md-button>
									</md-table-cell>
								</md-table-row>
							</md-table>
						</md-tab>
					</md-tabs>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateSkuGroup()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	data: () => ({
		selectedNamespace: null,
		currentPt: null,
		name: null,
		skus: '',
		parentIds: [],
		pTPackages: [],
		sending: false,
	}),
	validations: {
		name: {
			required
		}
	},
	methods: {
		getHWConfigurations(namespace) {
			return this.$store.getters.hWConfigurations.filter((hw) => hw.namespace.name === namespace)
		},
		getSWVariants(namespace) {
			return this.$store.getters.sWVariants.filter((sw) => sw.namespace.name === namespace)
		},
		getDeliveryVariants(namespace) {
			return this.$store.getters.deliveryVariants.filter((dv) => dv.namespace.name === namespace)
		},
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.skus = ''
			this.parentIds = []
			this.pTPackages = []
		},
		async addAllTripplesForNamespace() {
			const ns = this.$store.getters.namespaces.find((n) => n.name === this.selectedNamespace)
			const tripples = await this.$apiService.artifact.getTripplesForNamespace(ns.id)
			const pt = parseInt(this.currentPt.split('-')[1])
			for (let t of tripples) {
				this.pTPackages.push({
					programmingTypeId: pt,
					namespace: t.namespace,
					sWVariant: t.sWVariant,
					hWConfiguration: t.hWConfiguration,
					deliveryVariant: t.deliveryVariant
				})
			}
			this.selectedNamespace = ''
		},
		async addSkuGroup() {
			let tripples = this.pTPackages.filter((tripple) => {
				return ('namespace' in tripple) &&
					('sWVariant' in tripple) &&
					('hWConfiguration' in tripple) &&
					('deliveryVariant' in tripple)
			})
			tripples = tripples.map((tripple) => ({
				namespaceId: tripple.namespace.id,
				sWVariantId: tripple.sWVariant.id,
				hWConfigurationId: tripple.hWConfiguration.id,
				deliveryVariantId: tripple.deliveryVariant.id,
				programmingTypeId: tripple.programmingTypeId
			}))
			try {
				if (this.skus !== '') {
					this.skus = this.skus.split('\n').filter((sku) => sku !== '')
				} else {
					this.skus = []
				}
				this.sending = true
				await this.$apiService.skuGroup.create({
					name: this.name,
					skus: this.skus,
					skuGroupParentIds: this.parentIds,
					packageTripples: tripples
				})
				this.$snackbar.showMessage('Sku Group saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateSkuGroup() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.addSkuGroup()
			}
		},
		async close() {
			this.clearForm()
			this.sending = true
			try {
				await this.$store.dispatch('loadSkuGroups', { sortBy: this.sortBy, sortOrder: this.sortOrder })
				await this.$store.dispatch('loadSkuGroupParents')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
			this.$router.back()
		},
		onSkuInputChange() {
			if (this.skus.includes(',')) {
				this.skus = this.skus.split(',').join('\n')
			}
			if (this.skus.includes(';')) {
				this.skus = this.skus.split(';').join('\n')
			}
		},
		deleteTripple(id) {
			this.pTPackages.splice(id, 1)
		}
	}
}
</script>
