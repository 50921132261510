<template>
	<div>
		<div class="md-layout md-gutter">
			<div class="md-layout-item md-size-30">
				<md-field :class="getValidationClass('version')">
					<label>Version</label>
					<md-input name="version" id="version" v-model="releaseInfo.version" required/>
					<span class="md-helper-text">Maybe {{versionHint}}?</span>
					<span class="md-error" v-if="!$v.releaseInfo.version.validVersion">Version not valid</span>
				</md-field>
			</div>
			<div class="md-layout-item">
				<md-field>
					<label for="code">Code</label>
					<md-input name="code" id="code" v-model="releaseInfo.code"/>
				</md-field>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<md-field :class="getValidationClass('notes')">
					<label for="notes">Notes</label>
					<md-textarea v-model="releaseInfo.notes" required></md-textarea>
					<span class="md-error" v-if="!$v.releaseInfo.notes.required">Notes are required</span>
				</md-field>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<md-field>
					<label for="attachments">Attachements</label>
					<md-file id="attachmentInput" multiple />
				</md-field>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<md-chips v-model="releaseInfo.tags" md-placeholder="Tags"></md-chips>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<dialog-link-table :links="releaseInfo.links"/>
			</div>
		</div>
	</div>
</template>

<script>
import DialogLinkTable from '@/components/Common/DialogLinkTable.vue'
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

function validVersion(version) {
	const regex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:\+([0-9]+(?:\.[0-9-]+)*))?(?:\+[0-9]+)?$/
	const res = regex.exec(version)

	if (!res) {
		return false
	}

	if (res[1] == null || res[2] == null || res[3] == null) {
		return false
	}

	return true
}

export default {
	name: 'InformationTab',
	mixins: [validationMixin],
	props: ['versionHint'],
	components: {
		'dialog-link-table': DialogLinkTable
	},
	data: function() {
		return {
			releaseInfo: {
				code: null,
				notes: null,
				links: [],
				tags: [],
				version: null
			}
		}
	},
	validations: {
		releaseInfo: {
			notes: {
				required
			},
			version: {
				validVersion
			}
		}
	},
	watch: {
		releaseInfo: {
			deep: true,
			handler() {
				this.$emit('setReleaseInfo', this.releaseInfo)
			}
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.releaseInfo[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		}
	}
}
</script>
