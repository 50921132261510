<template>
	<div>
		<edit v-if="$route.name == 'editPCB'"/>
		<create v-if="$route.name == 'createPCB'"/>

		<e-table title="PCBs" singularTitle="PCB" :data="$store.getters.pcbs" :filter="filter" :columns="columns" @delete="deletePCB" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/PCB/Edit'
import Create from '@/components/PCB/Create'
import Table from '@/components/Common/Table'

export default {
	name: 'PCBs',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table
	},
	data: () => ({
		sortBy: 'id',
		sortOrder: 'DESC',
		filter: {
			name: {
				value: null,
				name: 'Name',
				property: 'name'
			},
			tag: {
				value: [],
				name: 'Tag'
			}
		},
		columns: [
			{
				name: 'id',
				label: 'ID'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'description',
				label: 'Description'
			}, {
				name: 'tags',
				label: 'Tags',
				sortable: false
			}
		]
	}),
	async created() {
		try {
			await this.$store.dispatch('loadPCBs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deletePCB(id) {
			try {
				await this.$apiService.pcb.delete(id)
				await this.$store.dispatch('loadPCBs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
