import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { authHeader } from './auth'
import config from '../../config'
import {
	Entities,
	CommitEntities,
	INamespace,
	IRelease,
	IHWConfiguration,
	ISWVariant,
	IDeliveryVariant,
	IManifestField,
	IRequestField,
	IRequest,
	IArtifact,
	ICondition,
	IPipelineTrigger,
	IUser,
	IPCB,
	IPCBA,
	IPaginationOptions,
	ISkuGroup,
	IConsumer,
	ILrdToken,
	ISkuGroupParent,
	IProgrammingType,
	IAccessToken,
	IAttachment,
	ICommit,
	ICommitNotes,
	ICommitListEntry,
	IArtifactReceiver,
} from '@/@types/interfaces'
import store from '@/store'

axios.defaults.withCredentials = false
axios.defaults.baseURL = config.API_URL

axios.interceptors.response.use((response: any) => {
	store.commit('progressBar/show', false, { root: true })
	return response
}, (error: any) => {
	store.commit('progressBar/show', false, { root: true })
	if (error.response.status === 401) {
		window.location.href = config.AUTH_URL
		throw new Error('Session expired. Please login!')
	}
	throw new Error(error.response.data.message)
})

axios.interceptors.request.use((val: AxiosRequestConfig) => {
	val.headers = {...authHeader(), ...val.headers}
	store.commit('progressBar/show', true, { root: true })
	return val
})

export default class ApiService {
	namespace: NamespaceApi = new NamespaceApi(axios)
	skuGroup: SkuGroupsApi = new SkuGroupsApi(axios)
	pcb: PCBApi = new PCBApi(axios)
	pcba: PCBAApi = new PCBAApi(axios)
	release: ReleaseApi = new ReleaseApi(axios)
	hWConfiguration: HWConfigurationApi = new HWConfigurationApi(axios)
	sWVariant: SWVariantApi = new SWVariantApi(axios)
	deliveryVariant: DeliveryVariantApi = new DeliveryVariantApi(axios)
	manifestField: ManifestFieldApi = new ManifestFieldApi(axios)
	requestField: RequestFieldApi = new RequestFieldApi(axios)
	request: RequestApi = new RequestApi(axios)
	artifact: ArtifactApi = new ArtifactApi(axios)
	condition: ConditionApi = new ConditionApi(axios)
	pipelineTrigger: PipelineTriggerApi = new PipelineTriggerApi(axios)
	lrdToken: LrdTokenApi = new LrdTokenApi(axios)
	user: UserApi = new UserApi(axios)
	consumer: ConsumerApi = new ConsumerApi(axios)
	skuGroupParent: SkuGroupParentApi = new SkuGroupParentApi(axios)
	programmingType: ProgrammingTypeApi = new ProgrammingTypeApi(axios)
	accessToken: AccessTokenApi = new AccessTokenApi(axios)
	attachment: AttachmentApi = new AttachmentApi(axios)
	commit = new CommitApi(axios)
	commitNotes = new CommitNotesApi(axios)
	artifactReceiver = new ArtifactReceiverApi(axios)

	public async createTagsIfNotExist(tags: string[]): Promise<number[]> {
		const res = await axios.post(`/tags`, {tags})
		return res.data as number[]
	}

	public async postInChat(release: IRelease, token: string): Promise<void> {
		const url = `/release/${release.id}/postInChat`
		let data: any = {}
		if (token) {
			data.token = token
		}
		await axios.post(url, data)
	}

	public async evaluateTest(data: any) {
		const res = await axios.post(`/update/test`, data)
		return res.data as IArtifact[]
	}

	async getLTToken() {
		const res = await axios.get(`/auth/ltToken`)
		return res.data
	}

	async triggerTesterBuild(release: IRelease) {
		await axios.post(`/actions/build-tester`, { releaseId: release.id })
	}

	async getDownloadManifest(token: string, programmingType = null, sku = null) {
		const params: any = { token }
		if (programmingType) {
			params.programmingType = programmingType
		}
		if (sku) {
			params.sku = sku
		}
		const res = await axios.get(`/download`, { params })
		return res.data
	}
}

// TODO: pagination
class EntityApi<T extends Entities> {
	name: string
	url: string
	ax: typeof axios

	constructor(name: string, ax: typeof axios) {
		this.name = name
		this.url = `/${this.name}`
		this.ax = ax
	}

	public async getAll(queryOptions?: IPaginationOptions): Promise<T[]> {
		const res: AxiosResponse = await this.ax.get(this.url, { params: queryOptions })
		const entities: T[] = res.data
		return entities
	}

	public async getOne(id: number): Promise<T> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/${id}`)
		const entity: T = res.data as T
		return entity
	}

	public async getForNamespace(id: number, queryOptions?: IPaginationOptions): Promise<T[]> {
		if (!id) {
			return []
		}
		const res: AxiosResponse = await this.ax.get(`/namespace/${id}/${this.name}`, { params: queryOptions })
		const entities: T[] = res.data
		return entities
	}

	public async create(data: any): Promise<T> {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		return (await this.ax.post(this.url, {...data})).data
	}

	public async delete(id: number): Promise<void> {
		await this.ax.delete(`${this.url}/${id}`)
	}

	public async update(id: number, data: any, allowNull: boolean = false): Promise<T> {
		if (!allowNull) {
			Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		}
		return (await this.ax.put(`${this.url}/${id}`, {...data})).data
	}
}

// TODO: pagination
class CommitEntityApi<T extends CommitEntities> {
	name: string
	url: string
	ax: typeof axios

	constructor(name: string, ax: typeof axios) {
		this.name = name
		this.url = `/${this.name}`
		this.ax = ax
	}

	public async getAll(queryOptions?: IPaginationOptions): Promise<T[]> {
		const res: AxiosResponse = await this.ax.get(this.url, { params: queryOptions })
		const entities: T[] = res.data
		return entities
	}

	public async getOne(commit: string): Promise<T> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/${commit}`)
		const entity: T = res.data as T
		return entity
	}

	public async create(data: any): Promise<T> {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		return (await this.ax.post(this.url, {...data})).data
	}

	public async delete(commit: string): Promise<void> {
		await this.ax.delete(`${this.url}/${commit}`)
	}

	public async update(commit: string, data: any, allowNull: boolean = false): Promise<T> {
		if (!allowNull) {
			Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		}
		return (await this.ax.put(`${this.url}/${commit}`, {...data})).data
	}
}

export class NamespaceApi extends EntityApi<INamespace> {
	constructor(ax: typeof axios) {
		super('namespace', ax)
	}
}
export class SkuGroupsApi extends EntityApi<ISkuGroup> {
	constructor(ax: typeof axios) {
		super('skuGroup', ax)
	}
}
export class UserApi extends EntityApi<IUser> {
	constructor(ax: typeof axios) {
		super('user', ax)
	}
}
export class PCBApi extends EntityApi<IPCB> {
	constructor(ax: typeof axios) {
		super('pcb', ax)
	}
}
export class PCBAApi extends EntityApi<IPCBA> {
	constructor(ax: typeof axios) {
		super('pcba', ax)
	}
}
export class PipelineTriggerApi extends EntityApi<IPipelineTrigger> {
	constructor(ax: typeof axios) {
		super('pipelineTrigger', ax)
	}
}
export class HWConfigurationApi extends EntityApi<IHWConfiguration> {
	constructor(ax: typeof axios) {
		super('hWConfiguration', ax)
	}
}
export class SWVariantApi extends EntityApi<ISWVariant> {
	constructor(ax: typeof axios) {
		super('sWVariant', ax)
	}
}
export class DeliveryVariantApi extends EntityApi<IDeliveryVariant> {
	constructor(ax: typeof axios) {
		super('deliveryVariant', ax)
	}
}
export class ManifestFieldApi extends EntityApi<IManifestField> {
	constructor(ax: typeof axios) {
		super('manifestField', ax)
	}
}
export class RequestFieldApi extends EntityApi<IRequestField> {
	constructor(ax: typeof axios) {
		super('requestField', ax)
	}
}
export class RequestApi extends EntityApi<IRequest> {
	constructor(ax: typeof axios) {
		super('request', ax)
	}
}
export class LrdTokenApi extends EntityApi<ILrdToken> {
	constructor(ax: typeof axios) {
		super('lrdToken', ax)
	}

	public async getCommitToken(commit: string, data: any) {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		const ret = await this.ax.post(`${this.url}/by-commit/${commit}`, data)
		return ret.data.token
	}
}

export class AttachmentApi extends EntityApi<IAttachment> {
	constructor(ax: typeof axios) {
		super('attachment', ax)
	}

	public async createAttachment(e: { name: string, id: number}, data: any): Promise<IAttachment> {
		return (await this.ax.post(`${e.name}/${e.id}/attachment`, data, {
			headers: {
				'Content-Type': 'multipart/form-data', // eslint-disable-line
				...authHeader()
			}
		})).data
	}

	public async getAttachments(e: { name: string, id: number}): Promise<IAttachment[]> {
		const ret = await this.ax.get(`${e.name}/${e.id}/attachment`)
		return ret.data as IAttachment[]
	}
}

export class ReleaseApi extends EntityApi<IRelease> {
	constructor(ax: typeof axios) {
		super('release', ax)
	}

	public async createToken(release: IRelease, data: any) {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		const ret = await this.ax.post(`${this.url}/${release.id}/lrdToken`, data)
		return ret.data.token
	}
}
export class ConsumerApi extends EntityApi<IConsumer> {
	constructor(ax: typeof axios) {
		super('consumer', ax)
	}
}
export class SkuGroupParentApi extends EntityApi<ISkuGroupParent> {
	constructor(ax: typeof axios) {
		super('skuGroupParent', ax)
	}
}
export class ProgrammingTypeApi extends EntityApi<IProgrammingType> {
	constructor(ax: typeof axios) {
		super('programmingType', ax)
	}
}
export class AccessTokenApi extends EntityApi<IAccessToken> {
	constructor(ax: typeof axios) {
		super('accessToken', ax)
	}
}
export class ArtifactApi extends EntityApi<IArtifact> {
	constructor(ax: typeof axios) {
		super('artifact', ax)
	}

	public async getUploadUrl(): Promise<string> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/upload-url/by-user`)
		return res.data
	}

	public async create(data: any): Promise<IArtifact> {
		const uploadUrl = await this.getUploadUrl()
		await fetch(uploadUrl, {
			method: 'PUT',
			body: data.file,
			headers: {
				'Content-Type': 'application/octet-stream' // eslint-disable-line
			}
		})
		delete data.file
		data.uploadUrl = uploadUrl
		return (await this.ax.post(`${this.url}/by-user`, data)).data
	}

	public async getOneTimeToken(artifact: IArtifact) {
		const ret = await this.ax.get(`${this.url}/${artifact.id}/oneTimeToken`)
		return ret.data.token
	}

	public async getTripplesForNamespace(id: number) {
		const ret = await this.ax.get(`${this.url}/existingTripples?namespaceId=${id}`)
		return ret.data
	}
}
export class ConditionApi extends EntityApi<ICondition> {
	constructor(ax: typeof axios) {
		super('condition', ax)
	}

	public async test(condition: string, requestId: number): Promise<any> {
		const res: any = await this.ax.post(`${this.url}/test`, {condition, requestId})
		return res.data
	}

	public async eval(condition: string, input: string[]): Promise<any> {
		const res: any = await this.ax.post(`${this.url}/eval`, {condition, input})
		return res.data
	}

	public async activate(id: number, active: boolean): Promise<any> {
		await this.ax.put(`${this.url}/${id}/activate`, { active })
	}
}

export class CommitApi extends CommitEntityApi<ICommit> {
	constructor(ax: typeof axios) {
		super('commit', ax)
	}

	public async getAll(queryOptions?: IPaginationOptions): Promise<ICommit[]> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/with-details`, { params: queryOptions })
		const entities: ICommit[] = res.data
		return entities
	}

	public async getOverview(queryOptions?: IPaginationOptions): Promise<ICommitListEntry[]> {
		const res: AxiosResponse = await this.ax.get(this.url, { params: queryOptions })
		const entities: ICommitListEntry[] = res.data
		return entities
	}

	public async getTrackHistory(commit: string): Promise<string> {
		const res = await this.ax.get(`${this.url}/${commit}/history`)
		return res.data
	}

	public async getForArtifactReceiver(receiver: IArtifactReceiver | string, queryOptions?: IPaginationOptions): Promise<ICommit[]> {
		const name = typeof receiver === 'string' ? receiver : receiver.name
		const res: AxiosResponse = await this.ax.get(`${this.url}/released-for/${name}`, { params: queryOptions })
		const entities: ICommit[] = res.data
		return entities
	}
}

export class CommitNotesApi extends CommitEntityApi<ICommitNotes> {
	constructor(ax: typeof axios) {
		super('commit-notes', ax)
	}
}

export class ArtifactReceiverApi extends EntityApi<IArtifactReceiver> {
	constructor(ax: typeof axios) {
		super('artifact-receiver', ax)
	}

	public async getForCommit(commit: string): Promise<IArtifactReceiver[]> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/for-commit/${commit}`)
		return res.data as IArtifactReceiver[]
	}
}
