<template>
	<md-dialog :md-active="$route.name == 'editPipelineTrigger'" v-if="pipelineTrigger" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
					<div class="md-layout-item">
						<md-field>
							<label for="url">Url</label>
							<md-input name="url" id="url" v-model="pipelineTrigger.url" readonly required/>
						</md-field>
					</div>
				</div>
				<div class="md-layout md-gutter">
					<div class="md-layout-item md-size-70">
						<md-field>
							<label for="token">Token</label>
							<md-input name="token" id="token" v-model="pipelineTrigger.token" type="password" readonly required/>
						</md-field>
					</div>
					<div class="md-layout-item">
						<md-field :class="getValidationClass('ref')">
							<label for="ref">Ref</label>
							<md-input name="ref" id="ref" v-model="pipelineTrigger.ref" required/>
							<span class="md-error" v-if="!$v.pipelineTrigger.ref.required">Ref is required</span>
						</md-field>
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item">
						<md-field>
							<label for="description">Description</label>
							<md-input name="description" id="description" v-model="pipelineTrigger.description" />
						</md-field>
					</div>
				</div>

				<md-progress-bar md-mode="indeterminate" v-if="sending" />
		<md-dialog-actions>
			<md-button class="md-primary" @click="close" :disabled="sending || $v.$invalid">Close</md-button>
		</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	data: () => ({
		sending: false,
		pipelineTrigger: null
	}),
	mixins: [validationMixin, debounce],
	validations: {
		pipelineTrigger: {
			ref: {
				required
			}
		}
	},
	watch: {
		pipelineTrigger: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadPipelineTrigger')
		this.pipelineTrigger = this.$store.getters.getPipelineTriggerById(this.$route.params.eid)
		this.update = this.debounce(this.update)
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.pipelineTrigger[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async updatePipelineTrigger() {
			this.sending = true
			try {
				this.$apiService.pipelineTrigger.update(this.pipelineTrigger.id, { ref: this.pipelineTrigger.ref, description: this.pipelineTrigger.description })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updatePipelineTrigger()
			}
		},
		async close() {
			try {
				await this.$store.dispatch('loadPipelineTrigger', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
