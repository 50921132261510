<template>
	<div>
		<md-dialog :md-active="$route.name == 'downloadRelease'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
			<md-dialog-title>
				Download Release
			</md-dialog-title>
			<md-dialog-content>
				<div class="md-layout md-gutter md-alignment-center-center">
					<div class="md-layout-item md-size-50">
						<md-field>
							<label for="sku">SKU</label>
							<md-select v-model="sku" name="sku" id="sku" @md-selected="onSkuSelect">
								<md-option v-for="(sku, i) in skus" :key="i" :value="sku.sku">{{sku.text}}</md-option>
							</md-select>
						</md-field>
						<!-- <md-autocomplete v-model="skuText" :md-options="getSkus(release)">
							<label>Sku</label>
							<template slot="md-autocomplete-item" slot-scope="{ item, term }">
								<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
							</template>
							<template slot="md-autocomplete-empty">
								Not found!
							</template>
						</md-autocomplete> -->
					</div>
					<div class="md-layout-item md-size-30">
						<md-field>
							<label>Programming Type</label>
							<md-select v-model="programmingType" name="programmingType" id="programmingType" required>
								<md-option v-for="(type, id) in programmingTypes" :key="id" :value="type.name">{{type.name}}</md-option>
							</md-select>
						</md-field>
					</div>
					<div class="md-layout-item md-size-20">
						<md-button class="md-icon-button" @click="download()" :disabled="sku === '' || programmingType === null">
							<md-icon>download</md-icon>
						</md-button>
					</div>
				</div>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
export default {
	name: 'Download',
	data() {
		return {
			release: null,
			programmingType: null,
			skuText: '',
			sku: '',
			programmingTypes: []
		}
	},
	// computed: {
	// 	programmingTypes() {
	// 		const regRes = /(.*)\s\(.*\)$/.exec(this.skuText)
	// 		if (regRes && regRes[1]) {
	// 			this.sku = regRes[1]
	// 			const skuGroup = this.$store.getters.skuGroups.find((group) => group.skus.includes(this.sku))
	// 			const programmingTypes = []
	// 			for (let tripple of skuGroup.packageTripples) {
	// 				if (!programmingTypes.map((t) => t.id).includes(tripple.programmingType.id)) {
	// 					programmingTypes.push(tripple.programmingType)
	// 				}
	// 			}
	// 			return programmingTypes
	// 		}
	// 		return []
	// 	}
	// },
	computed: {
		skus() {
			if (!this.release) {
				return []
			}
			const ret = this.release.skuGroups
			const skus = []
			for (let group of ret) {
				skus.push(...group.skus.map((sku) => ({ sku, text: `${sku} (${group.name})` })))
			}
			return skus
		}
	},
	async created() {
		this.release = await this.$apiService.release.getOne(this.$route.params.eid)
	},
	methods: {
		async download() {
			try {
				const token = await this.$apiService.release.createToken(this.release, { oneTime: true })
				window.open(`${window.location.origin}/download?token=${token}&sku=${this.sku}&programmingType=${this.programmingType}`, '_blank')
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		close() {
			this.programmingType = null
			this.skuText = ''
			this.sku = ''
			this.release = null
			this.$router.back()
		},
		onSkuSelect() {
			const skuGroup = this.$store.getters.skuGroups.find((group) => group.skus.includes(this.sku))
			this.programmingTypes = []
			for (let tripple of skuGroup.packageTripples) {
				if (!this.programmingTypes.map((t) => t.id).includes(tripple.programmingType.id)) {
					this.programmingTypes.push(tripple.programmingType)
				}
			}
			if (this.programmingTypes.length > 0) {
				this.programmingType = this.programmingTypes[0].name
			}
		}
	}
}
</script>