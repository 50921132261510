<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createRequest'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label>Request fields</label>
								<md-select v-model="requestFields" name="requestFields" id="requestFields" multiple>
									<md-option v-for="field in $store.getters.requestFields" :key="field.id" :value="field.id">{{field.name}}</md-option>
								</md-select>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label>Consumer</label>
								<md-select v-model="consumers" name="consumer" id="consumer" multiple>
									<md-option v-for="c in $store.getters.consumers" :key="c.id" :value="c.id">{{c.name}}</md-option>
								</md-select>
							</md-field>
						</div>
					</div>
					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateRequest()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	data: () => ({
		name: null,
		requestFields: [],
		consumers: [],
		sending: false,
	}),
	validations: {
		name: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.requestFields = []
			this.consumers = []
			this.sending = false
		},
		async createRequest() {
			this.sending = true

			try {
				await this.$apiService.request.create({
					name: this.name,
					requestFieldIds: this.requestFields,
					consumerIds: this.consumers
				})
				this.$snackbar.showMessage('Request saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateRequest() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createRequest()
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			try {
				await this.$store.dispatch('loadRequests', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
