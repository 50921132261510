<template>
	<div>
		<div class="md-layout">
			<div class="md-layout-item">
				<prism-editor class="condition-editor" v-model="conditionCode" :highlight="highlighter" line-numbers :readonly="requestId === null || readonly" @input="validateInput"/>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<span class="md-error md-invalid" style="color: var(--md-theme-demo-light-fieldvariant,#ff1744)" v-if="showCondJsError">{{ condJsError }}</span>
			</div>
		</div>
		<details>
			<summary>
				<span class="md-subheading">Test</span>
			</summary>
			<div class="md-layout">
				<div class="md-layout-item">
					<prism-editor style="padding-bottom: 0px; padding-right: 0px" class="condition-editor" v-model="testCode" :highlight="highlighter" line-numbers :readonly="requestId === null"/>
				</div>
			</div>
			<div class="md-layout" style="padding-top: 5px">
				<div class="md-layout-item md-size-80">
					<span class="md-error md-invalid" style="color: var(--md-theme-demo-light-fieldvariant,#ff1744)" v-if="showTestJsError">{{ testJsError }}</span>
				</div>
				<div class="md-layout-item">
					<md-button type="submit" class="md-primary" @click="test()" :disabled="requestId === null || showCondJsError">Test</md-button>
				</div>
				<div class="md-layout-item" style="padding-top: 12px">
					<md-icon style="color: green" v-if="testSuccessfully">check</md-icon>
					<md-icon style="color: red" v-if="!testSuccessfully">close</md-icon>
				</div>
			</div>
		</details>
		<md-progress-bar md-mode="indeterminate" v-if="sending" />
	</div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/themes/prism-tomorrow.css'
import debounce from '../../mixins/debounce'


export default {
	name: 'Editor',
	components: { 'prism-editor': PrismEditor },
	mixins: [debounce],
	props: {
		requestId: {
			type: Number
		},
		readonly: {
			type: Boolean,
			default: false
		},
		condition: {
			type: String,
			default: ''
		}
	},
	data: function() {
		return {
			testCode: '',
			conditionCode: this.condition,
			sending: false,
			showCondJsError: false,
			condJsError: '',
			showTestJsError: false,
			testJsError: '',
			testSuccessfully: false
		}
	},
	async mounted() {
		this.validateInput = this.debounce(this.validateInput)
		await this.updateCode()
	},
	watch: {
		requestId: {
			async handler(anew, old) {
				if (anew !== old) {
					await this.updateCode()
				}
			}
		},
		conditionCode() {
			this.$emit('setCondition', this.conditionCode)
		}
	},
	methods: {
		close() {
			this.sending = false
			this.$emit('close')
		},
		async updateCode() {
			if (this.requestId === null) {
				return
			}
			const rs = this.$store.getters.requests.find((r) => this.requestId === r.id)
			if (!rs) {
				return
			}
			const rfs = rs.requestFields
			if (this.condition === '') {
				this.conditionCode = rfs.map((f) => `// ${f.name}: ${f.type}`).join('\n') + '\n\n'
			}
			this.testCode = rfs.map((f) => `const ${f.name}: ${f.type} = `).join('\n')
			await this.validateInput()
		},
		highlighter(code) {
			let res = highlight(code, languages.typescript)
			return res
		},
		async validateInput() {
			if (this.conditionCode === '') {
				return
			}
			try {
				this.sending = true
				const evalRes = await this.$apiService.condition.test(this.conditionCode, this.requestId)
				if (evalRes.error) {
					this.showCondJsError = true
					this.condJsError = evalRes.error.split('\n')[0]
				} else if (!('return' in evalRes)) {
					this.showCondJsError = true
					this.condJsError = 'Last line needs to be the condition'
				} else if (typeof evalRes.return !== 'boolean'){
					this.showCondJsError = true
					this.condJsError = ''
				} else {
					this.showCondJsError = false
					this.condJsError = ''
				}
				this.sending = false
				this.$emit('setShowCondJsError', this.showCondJsError)
			} catch(e) {
				this.sending = false
				console.log(e.message)
			}
		},
		async test() {
			if (this.testCode === '') {
				return
			}
			try {
				this.sending = true
				const evalRes = await this.$apiService.condition.eval(this.conditionCode, this.testCode)
				if (evalRes.error || !('return' in evalRes) || typeof evalRes.return !== 'boolean') {
					this.showTestJsError = true
					this.testSuccessfully = false
					if (evalRes.error) {
						this.testJsError = evalRes.error.split('\n')[0]
					}
				} else {
					if (evalRes.return) {
						this.showTestJsError = false
						this.testJsError = ''
						this.testSuccessfully = true
					} else {
						this.testSuccessfully = false
					}
				}
				this.sending = false
			} catch(e) {
				this.sending = false
				console.log(e.message)
			}
		}
	}
}
</script>
