<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item to="releases">Releases</md-list-item>
			</md-list>
		</md-app-drawer>
		<md-app-content>
			<router-view />
		</md-app-content>
	</md-app>
</template>

<script>
import Toolbar from '@/components/Common/Toolbar.vue'
export default {
	name: 'ReleasesHome',
	components: { toolbar: Toolbar }
}
</script>
