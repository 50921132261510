<template>
	<div>
		<div class="md-layout md-alignment-center-center">
			<div class="md-layout-item md-size-40">
				<md-field>
					<label for="consumerIds">Consumers</label>
					<md-select v-model="consumerIds" name="consumerIds" id="consumerIds" multiple @md-selected="onSelectConsumer">
						<md-option v-for="i in $store.getters.consumers" :key="i.id" :value="i.id">{{i.name}}</md-option>
					</md-select>
				</md-field>
			</div>
		</div>
		<div class="md-layout md-gutter md-alignment-center-center">
			<div class="md-layout-item md-size-30">
				<md-checkbox v-model="createToken">Create token</md-checkbox>
			</div>
			<div class="md-layout-item md-size-40" v-if="createToken">
				<md-datepicker v-model="tokenExpirationDate" md-immediately :md-model-type="String">
					<label>Expiration date</label>
				</md-datepicker>
			</div>
		</div>
		<div class="md-layout">
			<conditions :consumers="consumers" :conditions="conditions" @activateCondition="activateCondition" @deleteCondition="deleteCondition" @saveCondition="saveCondition" @addCondition="addCondition"/>
		</div>
	</div>
</template>
<script>
import Conditions from '../Conditions.vue'
export default {
	name: 'RolloutTab',
	components: {
		'conditions': Conditions,
	},
	data: function() {
		return {
			consumerIds: [],
			conditions: [],
			createToken: null,
			tokenExpirationDate: null
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadConsumers')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	computed: {
		consumers() {
			return this.$store.getters.consumers.filter((c) => this.consumerIds.includes(c.id))
		}
	},
	watch: {
		tokenExpirationDate() {
			if (!this.tokenExpirationDate) {
				this.createToken = false
			}
			this.$emit('setTokenExpirationDate', this.tokenExpirationDate)
		}
	},
	methods: {
		onSelectConsumer() {
			this.$emit('setConsumers', this.consumers)
		},
		activateCondition(i, active) {
			this.conditions[i].active = active
		},
		deleteCondition(i) {
			this.conditions.splice(i, 1)
		},
		saveCondition(condition, i) {
			this.conditions[i].condition = condition.condition
		},
		addCondition(rId, condition) {
			const request = this.$store.getters.requests.find((r) => r.id === rId)
			this.conditions.push({ request, condition, active: true })
			this.$emit('setConditions', this.conditions)
		},
	}
}
</script>
