<template>
	<div>
		<div class="md-layout">
			<div class="md-layout-item" style="padding-top: 22px">
				<tree-select class="tree-select" v-model="skuGroups" :multiple="true" :options="skuTree" :required="true" placeholder="Sku Groups...">
					<label slot="option-label" slot-scope="{ node }" :class="{ 'bold': node.raw.skus }">
						{{ node.label }}
					</label>
				</tree-select>
			</div>
		</div>
		<div class="md-layout md-gutter md-alignment-center-left" v-for="(ns, i) of namespaces" :key="ns.id" :set="v = $v.namespaces.$each[i]">
			<div class="md-layout-item md-size-20">
				<span class="md-body-2">{{ns.name}}</span>
			</div>
			<div class="md-layout-item md-size-40">
				<md-field :class="getArtifactValidationClass(v)">
					<label for="commit">Commit</label>
					<md-select v-model="ns.commit" name="commit" id="commit" @md-selected="setCommit(ns)" required>
						<md-option v-for="c in ns.commits" :key="c.commit" :value="c.commit">{{c.text}}</md-option>
					</md-select>
					<span class="md-error" v-if="!v.commit.required">Commit is required</span>
				</md-field>
			</div>
		</div>
		<div class="md-layout">
			<div class="md-layout-item">
				<e-table title="Artifacts" :data="tripples" :columns="columns" :editable="false" :deletable="false" :readonly="true" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import Table from '@/components/Common/Table'
import Treeselect from '@riophae/vue-treeselect'
import { getSkuTree } from '@/lib/helper'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
	name: 'ArtifactsTab',
	components: {
		'tree-select': Treeselect,
		'e-table': Table
	},
	mixins: [validationMixin],
	data: function() {
		return {
			tripples: [],
			namespaces: [],
			skuGroups: [],
			skuTree: [],
			sortBy: 'commit',
			sortOrder: 'DESC',
			columns: [
				{
					name: 'namespace',
					label: 'Namespace',
					sortable: 'namespace.name',
					render: (val) => val.name
				}, {
					name: 'commitText',
					label: 'Commit'
				}, {
					name: 'sWVariant',
					label: 'SW Variant',
					sortable: 'sWVariant.name',
					render: (val) => val.name
				}, {
					name: 'deliveryVariant',
					label: 'Delivery Variant',
					sortable: 'deliveryVariant.name',
					render: (val) => val.name
				}, {
					name: 'hWConfiguration',
					label: 'HW Configuration',
					sortable: 'hWConfiguration.name',
					render: (val) => val.name
				}
			]
		}
	},
	created() {
		this.skuTree = this.computeSkuTree()
	},
	validations: {
		namespaces: {
			$each: {
				commit: {
					required
				}
			}
		}
	},
	watch: {
		skuGroups(val) {
			const getTargetNode = (children, id) => {
				for (let p of children) {
					if (p.id === id) {
						return p
					} else if (p.children) {
						const res = getTargetNode(p.children, id)
						if (res) {
							return res
						}
					}
				}
				return null
			}

			const getSkuGroups = (node) => {
				if ('skus' in node) {
					return [node]
				}
				if (node.children) {
					const groups = []
					for (let c of node.children) {
						const res = getSkuGroups(c)
						if (res) {
							groups.push(...res)
						}
					}
					if (groups.length > 0) {
						return groups
					}
				}
			}

			const targetSkuGroups = []
			const inputSkuGroupRaw = []
			for (let id of val) {
				const targetNode = getTargetNode(this.skuTree, id)
				if (targetNode) {
					inputSkuGroupRaw.push(targetNode.name)
					const gs = getSkuGroups(targetNode)
					gs.forEach((g) => {
						if (!targetSkuGroups.map((g) => g.origId).includes(g.origId)) {
							targetSkuGroups.push(g)
						}
					})
				}
			}

			this.tripples = []
			const namespaces = []
			for (let skuGroup of targetSkuGroups) {
				skuGroup.packageTripples.forEach((t) => {
					if (!namespaces.map((n) => n.id).includes(t.namespace.id)) {
						let as = this.$store.getters.getArtifactsForNamespace(t.namespace)
						as.sort((a, b) => (a.id > b.id? -1 : 1))
						const commits = as.reduce((ar, a) => {
							if (!ar.map((c) => c.commit).includes(a.commit)) {
								ar.push({
									commit: a.commit,
									text: `${a.version} (${a.commit.substr(0, 7)})`
								})
							}
							return ar
						}, [])
						namespaces.push({
							...t.namespace,
							commits,
							commit: null
						})
					}
					if (!this.isTrippleIncluded(t)) {
						this.tripples.push({
							...t,
							commit: null
						})
					}
				})
			}
			this.namespaces = namespaces
			this.$emit('setTargetSkuGroupIds', targetSkuGroups.map((g) => g.origId))
			this.$emit('setInputSkuGroupRaw', inputSkuGroupRaw)
		}
	},
	methods: {
		getArtifactValidationClass(field) {
			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		computeSkuTree() {
			return getSkuTree(this.$store.getters.skuGroups, this.$store.getters.skuGroupParents)
		},
		setCommit(namespace) {
			const commit = namespace.commits.find((c) => c.commit === namespace.commit)
			this.tripples = this.tripples.map((t) => {
				if (t.namespace.id === namespace.id) {
					t.commit = commit.commit
					t.commitText = commit.text
				}
				return t
			})
			const version = this.$store.getters.artifacts.find((a) => a.commit === namespace.commit).version
			this.$emit('setVersion', version)
			this.$emit('setTripples', this.tripples)
		},
		isTrippleIncluded(tt) {
			return this.tripples.find((t) => {
				return (t.sWVariant.id === tt.sWVariant.id &&
					t.deliveryVariant.id === tt.deliveryVariant.id &&
					t.hWConfiguration.id === tt.hWConfiguration.id)
			})
		}
	}
}
</script>
