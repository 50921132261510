import { Track } from "./database-enums"

/* eslint-disable */
export enum UserRole {
	NO_PERMISSION = 'noPermission',
	ADMIN = 'admin',
	VIEWER = 'viewer'
}

export enum LRDTokenType {
	SINGLE = 'single',
	MULTIPLE = 'MULTIPLE',
	RELEASE = 'RELEASE',
	COMMIT = 'COMMIT'
}

export enum AccessTokenType {
	PIPELINE = 'pipeline',
	SERVICE = 'service'
}
/* eslint-enable */

export interface IPaginationOptions {
	sortBy: string
	sortOrder: 'ASC' | 'DESC'
	offset?: number
	limit?: number
	all?: boolean
	filter: {
		key: string,
		value: string
	}[]
}

export interface ICondition {
	id: number
	release: IRelease
	condition: string
	request: string
}

export interface INamespace {
	id: number
	name: string
}

export interface IProgrammingType {
	id: number
	name: string
	description: string
	manifestFields: IManifestField[]
}

export interface ILrdToken {
	id: number
	count: number
	description: string
	release?: IRelease
	user?: IUser
	expiresIn: Date
	type: LRDTokenType
	oneTime: boolean
}

export interface ISkuGroup {
	id: number
	name: string
	skus: string[]
	skuGroupParent: ISkuGroupParent
}

export interface ISkuGroupParent {
	id: number
	name: string
	skuGroupParent: ISkuGroupParent
}

export interface IPackageTripple {
	sWVariant: ISWVariant
	hWConfiguration: IHWConfiguration
	deliveryVariant: IDeliveryVariant
}

export interface IUser {
	email: string
	name: string
	roles: UserRole[]
}

export interface IAttachment {
	id: number
	name: string
	file: string
}

export interface IRelease {
	id: number
	code: string
	created: string
	version: string
	tags: ITag[]
	links: ILink[]
	artifacts: IArtifact[]
	conditions?: ICondition[]
}

export interface IPCB {
	id: number
	name: string
	description?: string
	designSource: string
	tags: ITag[]
	links: ILink[]
}

export interface IPCBA {
	id: number
	name: string
	description?: string
	bom: string
	hWConfigurations: IHWConfiguration[]
	pcb: IPCB
	tags: ITag[]
	links: ILink[]
}

export interface ITag {
	id: number
	tag: string
	description?: string
}

export interface IPipelineTrigger {
	id: number
	url: string
	token: string
	ref: string
	description?: string
}

export interface ILink {
	link: string
	description?: string
}

export interface IHWConfiguration {
	id: number
	name: string
	description: string
	namespace: INamespace
	tags: ITag[]
	links: ILink[]
	manifestFields: IManifestField[]
}

export interface ISWVariant {
	id: number
	name: string
	description: string
	namespace: INamespace
	tags: ITag[]
	links: ILink[]
	manifestFields: IManifestField[]
}

export interface IDeliveryVariant {
	id: number
	name: string
	description: string
	namespace: INamespace
	tags: ITag[]
	links: ILink[]
	manifestFields: IManifestField[]
}

export interface IField {
	id: number
	name: string
	optional: boolean
	allowedValues: string[]
	default: string
	description: string
	type: FieldType
}

export interface IRequest {
	id: number
	name: string
	requestFields: IRequestField[]
	consumers: IConsumer[]
}

export interface IConsumer {
	id: number
	name: string
}

export interface IArtifact {
	id: number,
	sWVariant: ISWVariant
	hWConfiguration: IHWConfiguration
	deliveryVariant: IDeliveryVariant
	file: string
	version: string
	commit?: string
	metadata: any
	releases?: IRelease[]
	namespace: INamespace
}

export interface IAccessToken {
	id: number
	name: string
	description: string
	token?: string
	type: AccessTokenType
}

export enum FieldType {
	string = 'string',
	number = 'number',
	boolean = 'boolean',
	version = 'Version'
}

export enum RequestType {
	client = 'client',
	server = 'server'
}

export interface IManifestField extends IField {}
export interface IRequestField extends IField {
	requestType: RequestType
}

export interface ICommit {
	commit: string
	track: Track
	artifact: IArtifact[]
}

export interface ICommitListEntry {
	commit: string
	lastUpdate: Date,
	track: Track
	version?: string
	artifactCount: number
	namespaceName?: string
	namespaceId?: number
	releaseForCount?: number,
}

export interface ICommitNotes {
	commit: string
	changeNotes?: string
	testNotes?: string
	releaseNotes?: string
}

export interface IArtifactReceiver {
	id: number
	name: string
	track: Track
	commits?: ICommit[]
}

export type Entities = ICondition | IRelease | INamespace | IPCB | IPCBA | ITag | IHWConfiguration | ISWVariant | IDeliveryVariant | IManifestField | IRequestField | IRequest | IArtifact | IPipelineTrigger | IUser | IConsumer | ILrdToken | IProgrammingType | IAccessToken | IArtifactReceiver
export type CommitEntities = ICommit | ICommitNotes
