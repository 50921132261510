<template>
	<md-dialog v-if="skuGroupParent" :md-active="$route.name == 'editSkuTree'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="skuGroupParent.name" required readonly/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="parents">Parent</label>
						<md-select v-model="skuGroupParent.skuGroupParent.id" name="parentId" id="parentId">
							<md-option v-for="i in $store.getters.skuGroupParents.filter(p => p.name !== skuGroupParent.name)" :key="i.id" :value="i.id">{{i.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	data: () => ({
		sending: false,
		skuGroupParent: null
	}),
	mixins: [debounce],
	watch: {
		skuGroupParent: {
			deep: true,
			async handler() {
				await this.updateSkuGroupParent()
			}
		}
	},
	created() {
		this.skuGroupParent = this.$store.getters.getSkuGroupParentById(this.$route.params.eid)
		this.updateSkuGroupParent = this.debounce(this.updateSkuGroupParent)
		if (!this.skuGroupParent.skuGroupParent) {
			this.skuGroupParent.skuGroupParent = { id: null }
		}
	},
	methods: {
		async updateSkuGroupParent() {
			try {
				await this.$apiService.skuGroupParent.update(this.skuGroupParent.id, {
					skuGroupParentId: this.skuGroupParent.skuGroupParent.id
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async close() {
			try {
				await this.$store.dispatch('loadSkuGroupParents', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>