<template>
	<div>
		<edit v-if="$route.name == 'editManifestField'"/>
		<create v-if="$route.name == 'createManifestField'"/>

		<e-table title="Manifest Fields" singularTitle="Manifest Field" :filter="filter" :data="$store.getters.manifestFields" :columns="columns" @delete="deleteManifestField" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/ManifestField/Edit'
import Create from '@/components/ManifestField/Create'
import Table from '@/components/Common/Table'
import { FieldType } from '@/@types/interfaces'

export default {
	name: 'ManifestFields',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table
	},
	data: () => ({
		sortBy: 'id',
		sortOrder: 'DESC',
		filter: {
			name: {
				value: null,
				name: 'Name',
				property: 'name'
			},
			tag: {
				value: [],
				name: 'Tag'
			}
		},
		columns: [
			{
				name: 'id',
				label: 'ID'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'description',
				label: 'Description'
			}, {
				name: 'type',
				label: 'Type'
			}, {
				name: 'name',
				label: 'Default',
				render: (id, item) => {
					switch(item.type) {
						case FieldType.string: return item.defaultString
						case FieldType.number: return item.defaultNumber
						case FieldType.boolean: return item.defaultBoolean
						case FieldType.version: return item.defaultVersion
					}
				}
			}, {
				name: 'name',
				label: 'Allowed Values',
				render: (id, item) => {
					switch(item.type) {
						case FieldType.string: return (item.allowedValuesString? item.allowedValuesString.join(', ') : '')
						case FieldType.number: return (item.allowedValuesNumber? item.allowedValuesNumber.join(', ') : '')
						case FieldType.boolean: return (item.allowedValuesBoolean? item.allowedValuesBoolean.join(', ') : '')
						case FieldType.version: return (item.allowedValuesVersion? item.allowedValuesVersion.join(', ') : '')
					}
				}
			}, {
				name: 'optional',
				label: 'Optional'
			}, {
				name: 'array',
				label: 'Array'
			}
		]
	}),
	async created() {
		try {
			await this.$store.dispatch('loadManifestFields', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteManifestField(id) {
			try {
				await this.$apiService.manifestField.delete(id)
				await this.$store.dispatch('loadManifestFields', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
