<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createRelease'" :md-click-outside-to-close=false :md-close-on-esc=false>
				<md-dialog-title>
					Create Release
				</md-dialog-title>
				<md-dialog-content>
					<md-steppers :md-active-step.sync="activeStep" md-linear>
						<md-step id="artifactsStep" md-label="Artifacts" :md-error="artifactStepError" :md-done.sync="aStepSync">
							<artifacts-tab ref="artifactsStep" @setTripples="val => tripples = val" @setVersion="val => versionHint = val" @setTargetSkuGroupIds="val => targetSkuGroupIds = val" @setInputSkuGroupRaw="val => inputSkuGroupRaw = val" />
						</md-step>
						<md-step id="informationStep" md-label="Information" :md-error="informationStepError" :md-done.sync="iStepSync">
							<information-tab ref="informationStep" @setReleaseInfo="val => releaseInfo = val" :versionHint="versionHint"/>
						</md-step>
						<md-step id="rolloutStep" md-label="Rollout" :md-done.sync="rStepSync">
							<rollout-tab @setConsumers="val => consumers = val" @setConditions="val => conditions = val" @setTokenExpirationDate="d => tokenExpireDate = d"/>
						</md-step>
					</md-steppers>
					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-checkbox v-if="activeStep === 'rolloutStep'" v-model="postInChat">Post in chat</md-checkbox>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button v-if="activeStep === 'rolloutStep'" type="submit" class="md-primary" @click="validateRelease()" :disabled="sending">Create</md-button>
						<md-button v-if="activeStep !== 'rolloutStep'" type="submit" class="md-primary" @click="nextStep()" :disabled="sending">Continue</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import InformationTab from './InformationTab.vue'
import ArtifactsTab from './ArtifactsTab.vue'
import RolloutTab from './RolloutTab.vue'

export default {
	name: 'CreateRelease',
	components: {
		'information-tab': InformationTab,
		'rollout-tab': RolloutTab,
		'artifacts-tab': ArtifactsTab
	},
	data: function() {
		return {
			inputSkuGroupRaw: [],
			targetSkuGroupIds: [],
			releaseInfo: {},
			tripples: [],
			versionHint: '',
			consumers: [],
			conditions: [],
			attachments: [],
			postInChat: false,
			tokenExpireDate: null,
			activeStep: 'artifactsStep',
			iStepSync: false,
			aStepSync: false,
			cStepSync: false,
			rStepSync: false,
			informationStepError: '',
			artifactStepError: '',
			sending: false
		}
	},
	async created() {
		await this.$store.dispatch('loadArtifacts')
	},
	methods: {
		clearForm() {
			this.releaseInfo = {}
			this.sending = false
			this.postInChat = false
			this.tokenExpireDate = null
			this.activeStep = 'artifactsStep'
		},
		nextStep() {
			switch (this.activeStep) {
				case 'artifactsStep':
					this.iStepSync = true
					this.activeStep = 'informationStep'
					break
				case 'informationStep':
					this.rStepSync = true
					this.activeStep = 'rolloutStep'
					break
			}
		},
		async createRelease() {
			this.sending = true

			try {
				// TODO: check if aritfacts have valid metadata/manifest fields
				const tagIds = await this.$apiService.createTagsIfNotExist(this.releaseInfo.tags)
				const as = this.tripples.map((t) => this.$store.getters.getArtifactByTrippleNCommit({
					sWVariant: t.sWVariant,
					deliveryVariant: t.deliveryVariant,
					hWConfiguration: t.hWConfiguration
				}, t.commit))
				const artifactIds = as.map((a) => a.id)
				const r = await this.$apiService.release.create({
					code: this.releaseInfo.code,
					notes: this.releaseInfo.notes,
					tagIds: tagIds,
					version: this.releaseInfo.version,
					links: this.releaseInfo.links,
					skuGroupIds: this.targetSkuGroupIds,
					skuTargetGroupsRaw: this.inputSkuGroupRaw,
					consumerIds: this.consumers.map((c) => c.id),
					artifactIds
				})
				for (let c of this.conditions) {
					c.requestId = c.request.id
					delete c.request
					await this.$apiService.condition.create({...c, releaseId: r.id})
				}
				let i
				for (i = 0; i < document.querySelector('#attachmentInput').files.length; i++) {
					const data = new FormData()
					data.append('name', document.querySelector('#attachmentInput').files[i].name)
					data.append('data', document.querySelector('#attachmentInput').files[i])
					await this.$apiService.attachment.createAttachment({ name: 'release', id: r.id }, data)
				}
				this.$snackbar.showMessage('Release created!')
				let token = null
				if (this.tokenExpireDate) {
					const t = await this.$apiService.release.createToken(r, { oneTime: false, expiresIn: this.tokenExpireDate })
					token = `${window.location.origin}/download?token=${t}`
				}
				if (this.postInChat) {
					await this.$apiService.postInChat(r, token)
				}
				await this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateRelease() {
			this.$refs.informationStep.$v.$touch()
			this.$refs.artifactsStep.$v.$touch()

			if (!this.$refs.informationStep.$v.$invalid && !this.$refs.artifactsStep.$v.$invalid) {
				this.informationStepError = ''
				this.artifactStepError = ''
				await this.createRelease()
			}

			if (this.$refs.informationStep.$v.releaseInfo.$invalid) {
				this.informationStepError = 'Error'
			}

			if (this.$refs.artifactsStep.$v.$invalid) {
				this.artifactStepError = 'Error'
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			await this.$store.dispatch('loadReleases')
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.bold {
	font-weight: bold;
}
.tree-select {
	font-size: 14px;
}
</style>