<template>
	<md-dialog :md-active="$route.name == 'showReleaseToken'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Token</md-dialog-title>
		<md-dialog-content>
			<table>
				<tr>
					<td><span class="LRDtoken">{{token}}</span></td>
					<td>
						<md-button class="md-icon-button" @click="copyToClipboard(token)">
							<md-icon>content_copy</md-icon>
							<md-tooltip md-direction="top">Copy</md-tooltip>
						</md-button>
					</td>
				</tr>
				<tr>
					<td><span class="LRDtoken"><a target="_blank" :href="`${origin}/download?token=${token}`">Direct Download</a></span></td>
					<td>
						<md-button class="md-icon-button" @click="copyToClipboard(`${origin}/download?token=${token}`)">
							<md-icon>content_copy</md-icon>
							<md-tooltip md-direction="top">Copy</md-tooltip>
						</md-button>
					</td>
				</tr>
			</table>
			<md-dialog-actions>
				<md-button class="md-primary" @click="close">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
export default {
	name: 'ShowToken',
	props: [ 'token'],
	computed: {
		origin() {
			return window.location.origin
		}
	},
	methods: {
		async copyToClipboard(text) {
			await navigator.clipboard.writeText(text)
		},
		close() {
			if (this.$listeners && this.$listeners.onClose) {
				this.$emit('onClose')
				return
			}
			this.$router.back()
		}
	}
}
</script>
