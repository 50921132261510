<template>
	<md-dialog v-if="skuGroup" :md-active="$route.name == 'editSkuGroup'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<md-tabs class="md-transparent">
				<md-tab id="information" md-label="Information">
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="skuGroup.name" requried readonly/>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label for="parents">Parents</label>
								<md-select v-model="skuGroup.skuGroupParents" name="parentIds" id="parentIds" multiple>
									<md-option v-for="i in $store.getters.skuGroupParents" :key="i.id" :value="i.id">{{i.name}}</md-option>
								</md-select>
							</md-field>
						</div>
					</div>
					<div class="md-layout" v-for="(sku, i) of skuGroup.skus" :key="i">
						<div class="md-layout-item md-size-90">
							<md-field>
								<label for="sku">SKU {{i+1}}</label>
								<md-input name="sku" id="sku" v-model="skuGroup.skus[i]" />
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-button class="md-icon-button md-accent" @click="deleteSku(i)">
								<md-icon>delete</md-icon>
							</md-button>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-button class="md-icon-button" @click="skuGroup.skus.push('')">
								<md-icon>add</md-icon>
								<md-tooltip md-direction="top">Add SKU</md-tooltip>
							</md-button>
						</div>
					</div>
				</md-tab>
				<md-tab v-for="pT of $store.getters.programmingTypes" :key="pT.id" :id="`pt-${pT.id}`" :md-label="pT.name">
					<md-table>
						<md-table-row v-for="(tripple, i) of pTPackages.filter((p) => pT.id === p.programmingTypeId)" :key="i">
							<md-table-cell>
								<md-autocomplete v-model="tripple.namespace" :md-options="$store.getters.namespaces.map(x => x.name)" :md-fuzzy-search="false" required>
									<label>Namespace</label>
									<template slot="md-autocomplete-item" slot-scope="{ item, term }">
										<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
									</template>
									<template slot="md-autocomplete-empty">
										Not found!
									</template>
								</md-autocomplete>
							</md-table-cell>
							<md-table-cell>
								<md-autocomplete v-model="tripple.hWConfiguration" :md-options="getHWConfigurations(tripple.namespace).map(x => x.name)" :md-fuzzy-search="false" required>
									<label>HW Configuration</label>
									<template slot="md-autocomplete-item" slot-scope="{ item, term }">
										<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
									</template>
									<template slot="md-autocomplete-empty">
										Not found!
									</template>
								</md-autocomplete>
							</md-table-cell>
							<md-table-cell>
								<md-autocomplete v-model="tripple.sWVariant" :md-options="getSWVariants(tripple.namespace).map(x => x.name)" :md-fuzzy-search="false" required>
									<label>SW Variant</label>
									<template slot="md-autocomplete-item" slot-scope="{ item, term }">
										<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
									</template>
									<template slot="md-autocomplete-empty">
										Not found!
									</template>
								</md-autocomplete>
							</md-table-cell>
							<md-table-cell>
								<md-autocomplete v-model="tripple.deliveryVariant" :md-options="getDeliveryVariants(tripple.namespace).map(x => x.name)" :md-fuzzy-search="false" required>
									<label>Delivery Variant</label>
									<template slot="md-autocomplete-item" slot-scope="{ item, term }">
										<md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
									</template>
									<template slot="md-autocomplete-empty">
										Not found!
									</template>
								</md-autocomplete>
							</md-table-cell>
							<md-table-cell>
								<md-button class="md-icon-button md-accent" @click="deleteTripple(tripple.i)">
									<md-icon>delete</md-icon>
								</md-button>
							</md-table-cell>
						</md-table-row>
					</md-table>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-button class="md-icon-button" @click="pTPackages.push({programmingTypeId: pT.id, i: pTPackages.length})">
								<md-icon>add</md-icon>
								<md-tooltip md-direction="top">Add Tripple</md-tooltip>
							</md-button>
						</div>
					</div>
				</md-tab>
			</md-tabs>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	mixins: [validationMixin, debounce],
	data: () => ({
		sending: false,
		skuGroup: null,
		pTPackages: []
	}),
	watch: {
		skuGroup: {
			deep: true,
			async handler() {
				await this.validateSkuGroup()
			}
		},
		pTPackages: {
			deep: true,
			async handler() {
				await this.updatePackageTripples()
			}
		}
	},
	validations: {
		skuGroup: {
			name: {
				required
			}
		}
	},
	created() {
		this.skuGroup = this.$store.getters.getSkuGroupById(this.$route.params.eid)
		this.skuGroup.skuGroupParents = this.skuGroup.skuGroupParents.map((p) => p.id)
		this.pTPackages = this.skuGroup.packageTripples.map((pT, i) => ({
			namespace: pT.namespace.name,
			sWVariant: pT.sWVariant.name,
			deliveryVariant: pT.deliveryVariant.name,
			hWConfiguration: pT.hWConfiguration.name,
			programmingTypeId: pT.programmingType.id,
			i
		}))
		this.updateSkuGroup = this.debounce(this.updateSkuGroup)
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.skuGroup[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async updatePackageTripples() {
			let tripples = this.pTPackages.filter((tripple) => {
				return ('namespace' in tripple) &&
					('sWVariant' in tripple) &&
					('hWConfiguration' in tripple) &&
					('deliveryVariant' in tripple)
			})
			let invalid = false
			tripples = tripples.map((tripple) => {
				const namespace = this.$store.getters.namespaces.find((n) => n.name === tripple.namespace)
				const sWvariant = this.$store.getters.sWVariants.find((n) => n.name === tripple.sWVariant)
				const hWConfiguration = this.$store.getters.hWConfigurations.find((n) => n.name === tripple.hWConfiguration)
				const deliveryVariant = this.$store.getters.deliveryVariants.find((n) => n.name === tripple.deliveryVariant)
				if (!namespace || !sWvariant || !hWConfiguration || !deliveryVariant) {
					invalid = true
					return
				}
				return {
					namespaceId: namespace.id,
					sWVariantId: sWvariant.id,
					hWConfigurationId: hWConfiguration.id,
					deliveryVariantId: deliveryVariant.id,
					programmingTypeId: tripple.programmingTypeId
				}
			})
			if (invalid) {
				return
			}
			this.sending = true
			try {
				await this.$apiService.skuGroup.update(this.skuGroup.id, {
					packageTripples: tripples
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async updateSkuGroup() {
			this.sending = true
			try {
				await this.$apiService.skuGroup.update(this.skuGroup.id, {
					skus: this.skuGroup.skus.filter((sku) => sku !== ''),
					skuGroupParentIds: this.skuGroup.skuGroupParents
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateSkuGroup() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updateSkuGroup()
			}
		},
		deleteSku(id) {
			if (this.skuGroup.skus.length === 1) {
				this.skuGroup.skus = ['']
				return
			}
			this.skuGroup.skus.splice(id, 1)
		},
		getHWConfigurations(namespace) {
			return this.$store.getters.hWConfigurations.filter((hw) => hw.namespace.name === namespace)
		},
		getSWVariants(namespace) {
			return this.$store.getters.sWVariants.filter((sw) => sw.namespace.name === namespace)
		},
		getDeliveryVariants(namespace) {
			return this.$store.getters.deliveryVariants.filter((dv) => dv.namespace.name === namespace)
		},
		deleteTripple(id) {
			this.pTPackages.splice(id, 1)
		},
		async close() {
			try {
				await this.$store.dispatch('loadSkuGroups', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>