<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1" style="padding-left: 32px">
			<a href="/namespace" class="md-headline"><span >LR Distribution</span></a>
		</md-app-toolbar>
		<md-app-content>
			<md-card class="download-card md-layout-item md-size-30 md-small-size-100">
				<progessBar/>
				<md-card-header style="text-align: center">
					<div class="md-title">Download Artifact</div>
				</md-card-header>
				<md-card-content style="text-align: center">
					<md-divider/>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label>Token...</label>
								<md-input v-model="token"></md-input>
							</md-field>
						</div>
					</div>
				</md-card-content>
				<md-card-actions>
					<md-button type="submit" class="md-primary" @click="downloadArtifact" :disabled="token === '' || sending">Download</md-button>
				</md-card-actions>
			</md-card>
		</md-app-content>
	</md-app>
</template>

<script>
import JsZip from 'jszip'
import FileSaver from 'file-saver'
import progessBar from '@/components/ProgressBar.vue'

export default {
	name: 'Download',
	components: { progessBar },
	data: () => ({
		token: '',
		programmingType: null,
		sku: null,
		sending: false
	}),
	mounted() {
		this.token = this.$route.query.token || ''
		this.programmingType = this.$route.query.programmingType || null
		this.sku = this.$route.query.sku || null
		if (this.token !== '') {
			this.downloadArtifact()
		}
	},
	methods: {
		async downloadArtifact() {
			this.sending = true
			this.$store.commit('progressBar/show', true, { root: true })
			try {
				const manifest = await this.$apiService.getDownloadManifest(this.token, this.programmingType, this.sku)
				const zip = JsZip()
				for (let artifact of manifest.artifacts) {
					const downloadUrl = artifact.downloadUrl
					delete artifact.downloadUrl
					zip.file(`${artifact.fileName}_manifest.json`, JSON.stringify(artifact))
					const data = await fetch(downloadUrl)
					zip.file(artifact.fileName, await data.blob())
				}
				zip.file('manifest.json', JSON.stringify(manifest))
				const content = await zip.generateAsync({type: 'blob'})
				FileSaver.saveAs(content, `${manifest.name}.zip`)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$store.commit('progressBar/show', false, { root: true })
			this.sending = false
		}
	}
}
</script>

<style scoped>
	.download-card {
		margin: auto;
		margin-top: 10%;
	}
</style>
