<template>
	<md-dialog :md-active="$route.name == 'editProgrammingType'" v-if="programmingType" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="programmingType.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="programmingType.description" />
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label>Manifest fields</label>
						<md-select v-model="programmingType.manifestFields" name="manifestFields" id="manifestFields" multiple>
							<md-option v-for="field in $store.getters.manifestFields" :key="field.id" :value="field.id">{{field.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	data: () => ({
		sending: false,
		programmingType: null
	}),
	mixins: [debounce],
	watch: {
		programmingType: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadProgrammingTypes')
		this.programmingType = this.$store.getters.getProgrammingTypeById(this.$route.params.eid)
		this.updateProgrammingType = this.debounce(this.updateProgrammingType)
		this.programmingType.manifestFields = this.programmingType.manifestFields.map((mF) => mF.id)
	},
	methods: {
		async updateProgrammingType() {
			this.sending = true
			try {
				await this.$apiService.programmingType.update(this.programmingType.id, {
					description: this.programmingType.description,
					manifestFieldIds: this.programmingType.manifestFields
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateProgrammingType()
		},
		async close() {
			try {
				await this.$store.dispatch('loadProgrammingTypes', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
