<template>
	<div>
		<progessBar/>
		<md-app-toolbar class="md-transparent" md-elevation="0">
			<span class="md-headline">LR Distribution</span>
			<md-tabs md-sync-route>
				<!-- <md-tab v-for="namespace in $store.getters.namespaces" :key="namespace.id" :id="namespace.id.toString()" :to="`/namespace/${namespace.id.toString()}/`" :md-label="namespace.name"/> -->
				<!-- <md-tab :md-template-data="{ namespaces: $store.getters.namespaces }"/> -->
				<md-tab to="/namespace" md-label="Artifacts"/>
				<md-tab to="/releases" md-label="Releases"/>
				<md-tab to="/skuManagement" md-label="SKU Management"/>
				<md-tab to="/commit" md-label="Commits" />
				<md-tab to="/settings" md-label="Settings"/>
			</md-tabs>
			<div style="text-align: right; padding-left: 20px">
				<md-menu md-direction="bottom-end" mdCloseOnSelect>
					<md-button class="md-icon-button md-dense md-raised md-primary" md-menu-trigger>
						<md-icon>more_horiz</md-icon>
						<md-tooltip md-direction="top">More</md-tooltip>
					</md-button>
					<md-menu-content>
						<md-menu-item to="/download">Download</md-menu-item>
						<md-menu-item to="/upload" v-if="currentUser.roles.includes('admin')">Upload</md-menu-item>
						<md-menu-item @click="logout">Logout</md-menu-item>
					</md-menu-content>
				</md-menu>
			</div>
		</md-app-toolbar>
	</div>
</template>

<script>
import { getLocalUser } from '@/lib/auth'
import progessBar from '../ProgressBar.vue'
import config from '@/../config'
export default {
	name: 'Toolbar',
	components: { progessBar },
	data() {
		return {
			namespace: 'Namespaces'
		}
	},
	computed: {
		currentUser() {
			return getLocalUser()
		}
	},
	async created() {
		await this.$store.dispatch('loadNamespaces')
	},
	methods: {
		logout() {
			localStorage.removeItem('user')
			window.location.href = config.LR_CLOUD_URL
		}
	}
}
</script>