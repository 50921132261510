<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createPCBA'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label>PCB</label>
								<md-select v-model="pcbId" name="pcb" id="pcb">
									<md-option v-for="p in $store.getters.pcbs" :key="p.id" :value="p.id">{{p.name}}</md-option>
								</md-select>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label>HW Configuration</label>
								<md-select v-model="hWConfigurationId" name="hWC" id="hWC">
									<md-option v-for="hW in $store.getters.hWConfigurations" :key="hW.id" :value="hW.id">{{hW.name}}</md-option>
								</md-select>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field :class="getValidationClass('bom')">
								<label for="bom">BOM</label>
								<md-input name="bom" id="bom" v-model="bom" :disabled="sending" required />
								<span class="md-error" v-if="!$v.bom.required">BOM is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="attachments">Attachements</label>
								<md-file id="attachmentInput" multiple />
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
						<md-chips v-model="tags" md-placeholder="Tags"></md-chips>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
						<dialog-link-table :links="links"/>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validatePCBA()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Create',
	mixins: [validationMixin],
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		name: null,
		description: null,
		pcbId: null,
		attachments: [],
		hWConfigurationId: null,
		tags: [],
		links: [],
		bom: null,
		sending: false,
	}),
	validations: {
		name: {
			required
		},
		bom: {
			required
		}
	},
	watch: {
		pcbId: {
			deep: true,
			handler(val) {
				if (!val) {
					return
				}
				const pcb = this.$store.getters.pcbs.find((p) => p.id === val)
				const pcbas = this.$store.getters.pcbas.filter((p) => p.pcb.id === val)
				this.name = `${pcb.name}-${pcbas.length}`
			}
		}
	},
	created() {
		this.$store.dispatch('loadPCBs')
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.pcbId = null
			this.hWConfigurationId = null
			this.description = null
			this.bom = null
			this.tags = []
			this.links = []
			this.sending = false
		},
		async createPCBA() {
			this.sending = true

			try {
				const tagIds = await this.$apiService.createTagsIfNotExist(this.tags)
				const pcba = await this.$apiService.pcba.create({
					name: this.name,
					description: this.description,
					bom: this.bom,
					pcbId: this.pcbId,
					hWConfigurationId: this.hWConfigurationId,
					tagIds,
					links: this.links,
					namespaceId: parseInt(this.$route.params.id)
				})
				let i
				for (i = 0; i < document.querySelector('#attachmentInput').files.length; i++) {
					const data = new FormData()
					data.append('name', document.querySelector('#attachmentInput').files[i].name)
					data.append('data', document.querySelector('#attachmentInput').files[i])
					await this.$apiService.attachment.createAttachment({ name: 'pcba', id: pcba.id }, data)
				}
				this.$snackbar.showMessage('PCBA saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validatePCBA() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createPCBA()
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			try {
				await this.$store.dispatch('loadPCBAs', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
