import {
	IPCB,
	IPCBA,
	IRelease,
	INamespace,
	IHWConfiguration,
	ISWVariant,
	IDeliveryVariant,
	IManifestField,
	IRequestField,
	IRequest,
	IArtifact,
	ICondition,
	IPipelineTrigger,
	IPaginationOptions,
	ISkuGroup,
	IPackageTripple,
	ILrdToken,
	IConsumer,
	ISkuGroupParent,
	IProgrammingType,
	IAccessToken,
	ICommit,
	ICommitNotes,
	ICommitListEntry,
	IArtifactReceiver
} from '@/@types/interfaces'
import router from '@/router'
import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from '../lib/ApiService'
import { snackbar } from './snackbar.module'
import { progressBar } from './progressBar.module'

Vue.use(Vuex)
const apiService: ApiService = new ApiService()

function updateCommitData<T extends { commit: string }>(current: T[], data: T | T[]): T[] {
	if (Array.isArray(data)) {
		return data
	} else {
		const d = data as T
		const foundIndex = current.findIndex(r => r.commit === d.commit)
		if (foundIndex >= 0) {
			current.splice(foundIndex, 1, d)
		} else {
			current.push(d)
		}
		return current
	}
}

function updateIdData<T extends { id: number }>(current: T[], data: T | T[]): T[] {
	if (Array.isArray(data)) {
		return data
	} else {
		const d = data as T
		const foundIndex = current.findIndex(r => r.id === d.id)
		if(foundIndex >= 0) {
			current.splice(foundIndex, 1, d)
		} else {
			current.push(d)
		}
		return current
	}
}

export default new Vuex.Store({
	modules: {
		snackbar,
		progressBar
	},
	state: {
		namespaces: [] as INamespace[],
		skuGroups: [] as ISkuGroup[],
		pcbs: [] as IPCB[],
		pcbas: [] as IPCBA[],
		releases: [] as IRelease[],
		hWConfigurations: [] as IHWConfiguration[],
		sWVariants: [] as ISWVariant[],
		deliveryVariants: [] as IDeliveryVariant[],
		manifestFields: [] as IManifestField[],
		requestFields: [] as IRequestField[],
		requests: [] as IRequest[],
		artifacts: [] as IArtifact[],
		conditions: [] as ICondition[],
		pipelineTrigger: [] as IPipelineTrigger[],
		lrdToken: [] as ILrdToken[],
		consumers: [] as IConsumer[],
		skuGroupParents: [] as ISkuGroupParent[],
		programmingTypes: [] as IProgrammingType[],
		accessToken: [] as IAccessToken[],
		commits: [] as ICommit[],
		commitOverview: [] as ICommitListEntry[],
		commitNotes: [] as ICommitNotes[],
		artifactReceivers: [] as IArtifactReceiver[],
	},
	mutations: {
		async setNamespaces(state, namespaces) {
			state.namespaces = namespaces
		},
		async setConsumers(state, consumers) {
			state.consumers = consumers
		},
		async setSkuGroups(state, skuGroups) {
			state.skuGroups = skuGroups
		},
		async setSkuGroupParents(state, parents) {
			state.skuGroupParents = parents
		},
		async setLrdToken(state, lrdToken) {
			state.lrdToken = lrdToken
		},
		async setAccessToken(state, token) {
			state.accessToken = token
		},
		async setPCBs(state, pcbs) {
			state.pcbs = pcbs
		},
		async setPCBAs(state, pcbas) {
			state.pcbas = pcbas
		},
		async setReleases(state, releases) {
			state.releases = releases
		},
		async setProgrammingTypes(state, programmingTypes) {
			state.programmingTypes = programmingTypes
		},
		async setRelease(state, release) {
			const foundIndex = state.releases.findIndex((r) => r.id === release.id)
			state.releases[foundIndex] = release
		},
		async setHWConfiguration(state, hWConfiguration) {
			const foundIndex = state.hWConfigurations.findIndex((r) => r.id === hWConfiguration.id)
			state.hWConfigurations.splice(foundIndex, 1, hWConfiguration)
		},
		async setHWConfigurations(state, hWConfigurations) {
			state.hWConfigurations = hWConfigurations
		},
		async setSWVariants(state, sWVariants) {
			state.sWVariants = sWVariants
		},
		async setDeliveryVariants(state, deliveryVariants) {
			state.deliveryVariants = deliveryVariants
		},
		async setManifestFields(state, manifestFields) {
			state.manifestFields = manifestFields
		},
		async setRequestFields(state, requestFields) {
			state.requestFields = requestFields
		},
		async setRequests(state, requests) {
			state.requests = requests
		},
		async setArtifacts(state, artifacts) {
			state.artifacts = artifacts
		},
		async setConditions(state, conditions) {
			state.conditions = conditions
		},
		async setPipelineTrigger(state, pipelineTrigger) {
			state.pipelineTrigger = pipelineTrigger
		},
		async setCommit(state, data) {
			state.commits = updateCommitData(state.commits, data)
		},
		async setCommitOverview(state, data) {
			state.commitOverview = updateCommitData(state.commitOverview, data)
		},
		async setCommitNotes(state, data) {
			state.commitNotes = updateCommitData(state.commitNotes, data)
		},
		async setArtifactReceivers(state, data) {
			state.artifactReceivers = updateIdData(state.artifactReceivers, data)
		}
	},
	getters: {
		namespaces: state => state.namespaces,
		programmingTypes: state => state.programmingTypes,
		lrdToken: state => state.lrdToken,
		accessToken: state => state.accessToken,
		consumers: state => state.consumers,
		skuGroups: state => state.skuGroups,
		skuGroupParents: state => state.skuGroupParents,
		pcbs: state => state.pcbs,
		pcbas: state => state.pcbas,
		releases: state => state.releases,
		hWConfigurations: state => state.hWConfigurations,
		sWVariants: state => state.sWVariants,
		deliveryVariants: state => state.deliveryVariants,
		manifestFields: state => state.manifestFields,
		requestFields: state => state.requestFields,
		requests: state => state.requests,
		artifacts: state => state.artifacts,
		conditions: state => state.conditions,
		pipelineTrigger: state => state.pipelineTrigger,
		commitNotes: state => state.commitNotes,

		commit: state => (commit: string) => {
			return state.commits.find(x => x.commit === commit)
		},
		commitOverview: state => state.commitOverview,
		commitNotesForCommit: state => (commit: string) => {
			return state.commitNotes.find(n => n.commit === commit)
		},
		artifactReceivers: state => state.artifactReceivers,

		getNamespaceById: state => (id: number) => {
			return state.namespaces.find((i: INamespace) => i.id === id)
		},
		getRequestById: (state) => (id: number) => {
			return state.requests.find((i: IRequest) => i.id === id)
		},
		getRequestFieldById: (state) => (id: number) => {
			return state.requestFields.find((i: IRequestField) => i.id === id)
		},
		getManifestFieldById: (state) => (id: number) => {
			return state.manifestFields.find((i: IManifestField) => i.id === id)
		},
		getProgrammingTypeById: (state) => (id: number) => {
			return state.programmingTypes.find((i: IProgrammingType) => i.id === id)
		},
		getAccessTokenById: (state) => (id: number) => {
			return state.accessToken.find((i: IAccessToken) => i.id === id)
		},
		getPipelineTriggerById: (state) => (id: number) => {
			return state.pipelineTrigger.find((i: IPipelineTrigger) => i.id === id)
		},
		getConsumerById: (state) => (id: number) => {
			return state.consumers.find((i: IConsumer) => i.id === id)
		},
		getHWConfigurationById: (state) => (id: number) => {
			return state.hWConfigurations.find((i: IHWConfiguration) => i.id === id)
		},
		getSWVariantById: (state) => (id: number) => {
			return state.sWVariants.find((i: ISWVariant) => i.id === id)
		},
		getSkuGroupById: (state) => (id: number) => {
			return state.skuGroups.find((i: ISkuGroup) => i.id === id)
		},
		getSkuGroupParentById: (state) => (id: number) => {
			return state.skuGroupParents.find((i: ISkuGroupParent) => i.id === id)
		},
		getDeliveryVariantById: (state) => (id: number) => {
			return state.deliveryVariants.find((i: IDeliveryVariant) => i.id === id)
		},
		getReleaseById: (state) => (id: number) => {
			return state.releases.find((i: IRelease) => i.id === id)
		},
		getReleaseByVersion: (state) => (version: string) => {
			return state.releases.find((i: IRelease) => i.version === version)
		},
		getArtifactById: (state) => (id: number) => {
			return state.artifacts.find((i: IArtifact) => i.id === id)
		},
		getPcbById: (state) => (id: number) => {
			return state.pcbs.find((i: IPCB) => i.id === id)
		},
		getPcbaById: (state) => (id: number) => {
			return state.pcbas.find((i: IPCBA) => i.id === id)
		},
		getArtifactsForTripple: (state) => (tripple: IPackageTripple) => {
			return state.artifacts.filter((artifact: IArtifact) => {
				return artifact.sWVariant.id === tripple.sWVariant.id &&
				artifact.deliveryVariant.id === tripple.deliveryVariant.id &&
				artifact.hWConfiguration.id === tripple.hWConfiguration.id
			})
		},
		getArtifactsForNamespace: (state) => (namespace: INamespace) => {
			return state.artifacts.filter((a: IArtifact) => a.namespace.id === namespace.id)
		},
		getArtifactByTrippleNCommit: (state) => (tripple: IPackageTripple, commit: string) => {
			return state.artifacts.find((artifact: IArtifact) => {
				return artifact.sWVariant.id === tripple.sWVariant.id &&
				artifact.deliveryVariant.id === tripple.deliveryVariant.id &&
				artifact.hWConfiguration.id === tripple.hWConfiguration.id &&
				artifact.commit === commit
			})
		},
	},
	actions: {
		async loadNamespaces(state, queryOptions?: IPaginationOptions) {
			const namespaces = await apiService.namespace.getAll(queryOptions)
			this.commit('setNamespaces', namespaces)
		},
		async loadProgrammingTypes(state, queryOptions?: IPaginationOptions) {
			const programmingTypes = await apiService.programmingType.getAll(queryOptions)
			this.commit('setProgrammingTypes', programmingTypes)
		},
		async loadLrdToken(state, queryOptions?: IPaginationOptions) {
			const token = await apiService.lrdToken.getAll(queryOptions)
			this.commit('setLrdToken', token)
		},
		async loadAccessToken(state, queryOptions?: IPaginationOptions) {
			const token = await apiService.accessToken.getAll(queryOptions)
			this.commit('setAccessToken', token)
		},
		async loadConsumers(state, queryOptions?: IPaginationOptions) {
			const consumers = await apiService.consumer.getAll(queryOptions)
			this.commit('setConsumers', consumers)
		},
		async loadSkuGroups(state, queryOptions?: IPaginationOptions) {
			const skuGroups = await apiService.skuGroup.getAll(queryOptions)
			this.commit('setSkuGroups', skuGroups)
		},
		async loadSkuGroupParents(state, queryOptions?: IPaginationOptions) {
			const parents = await apiService.skuGroupParent.getAll(queryOptions)
			this.commit('setSkuGroupParents', parents)
		},
		async loadPCBs(state, queryOptions?: IPaginationOptions) {
			const pcbs = await apiService.pcb.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			this.commit('setPCBs', pcbs)
		},
		async loadPCBAs(state, queryOptions?: IPaginationOptions) {
			const pcbas = await apiService.pcba.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			this.commit('setPCBAs', pcbas)
		},
		async loadPipelineTrigger(state, queryOptions?: IPaginationOptions) {
			const pipelineTrigger = await apiService.pipelineTrigger.getAll(queryOptions)
			this.commit('setPipelineTrigger', pipelineTrigger)
		},
		async loadReleases(state, queryOptions?: IPaginationOptions) {
			const releases = await apiService.release.getAll(queryOptions)
			this.commit('setReleases', releases)
		},
		async loadRelease(state, id) {
			const release = await apiService.release.getOne(id)
			this.commit('setRelease', release)
		},
		async updateRelease(state, release) {
			this.commit('setRelease', release)
		},
		async loadAllHWConfigurations(state, queryOptions?: IPaginationOptions) {
			const hWConfigurations = await apiService.hWConfiguration.getAll(queryOptions)
			this.commit('setHWConfigurations', hWConfigurations)
		},
		async loadAllSWVariants(state, queryOptions?: IPaginationOptions) {
			const sWVariants = await apiService.sWVariant.getAll(queryOptions)
			this.commit('setSWVariants', sWVariants)
		},
		async loadAllDeliveryVariants(state, queryOptions?: IPaginationOptions) {
			const deliveryVariants = await apiService.deliveryVariant.getAll(queryOptions)
			this.commit('setDeliveryVariants', deliveryVariants)
		},
		async loadHWConfigurations(state, queryOptions?: IPaginationOptions) {
			let hWConfigurations
			if (router.currentRoute.params.id) {
				hWConfigurations = await apiService.hWConfiguration.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			} else {
				hWConfigurations = await apiService.hWConfiguration.getAll(queryOptions)
			}
			this.commit('setHWConfigurations', hWConfigurations)
		},
		async loadSWVariants(state, queryOptions?: IPaginationOptions) {
			let sWVariants
			if (router.currentRoute.params.id) {
				sWVariants = await apiService.sWVariant.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			} else {
				sWVariants = await apiService.sWVariant.getAll(queryOptions)
			}
			this.commit('setSWVariants', sWVariants)
		},
		async loadDeliveryVariants(state, queryOptions?: IPaginationOptions) {
			let deliveryVariants
			if (router.currentRoute.params.id) {
				deliveryVariants = await apiService.deliveryVariant.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			} else {
				deliveryVariants = await apiService.deliveryVariant.getAll(queryOptions)
			}
			this.commit('setDeliveryVariants', deliveryVariants)
		},
		async loadManifestFields(state, queryOptions?: IPaginationOptions) {
			const manifestFields = await apiService.manifestField.getAll(queryOptions)
			this.commit('setManifestFields', manifestFields)
		},
		async loadRequestFields(state, queryOptions?: IPaginationOptions) {
			const requestFields = await apiService.requestField.getAll(queryOptions)
			this.commit('setRequestFields', requestFields)
		},
		async loadRequests(state, queryOptions?: IPaginationOptions) {
			const requests = await apiService.request.getAll(queryOptions)
			this.commit('setRequests', requests)
		},
		async loadArtifacts(state, queryOptions?: IPaginationOptions) {
			let artifacts
			if (router.currentRoute.params.id) {
				artifacts = await apiService.artifact.getForNamespace(parseInt(router.currentRoute.params.id), queryOptions)
			} else {
				artifacts = await apiService.artifact.getAll(queryOptions)
			}
			this.commit('setArtifacts', artifacts)
		},
		async loadConditions(state, queryOptions?: IPaginationOptions) {
			const conditions = await apiService.condition.getAll(queryOptions)
			this.commit('setConditions', conditions)
		},
		async setHWConfiguration(state, hWConfiguration) {
			this.commit('setHWConfiguration', hWConfiguration)
		},
		async loadCommits(state, queryOptions?: IPaginationOptions) {
			const commits = await apiService.commit.getAll(queryOptions)
			this.commit('setCommits', commits)
		},
		async loadCommitOverview(state, queryOptions?: IPaginationOptions) {
			const overview = await apiService.commit.getOverview(queryOptions)
			this.commit('setCommitOverview', overview)
		},
		async getCommit(state, commit: string) {
			const data = await apiService.commit.getOne(commit)
			this.commit('setCommit', data)
		},
		async loadCommitNotes(state, queryOptions?: IPaginationOptions) {
			const commitNotes = await apiService.commitNotes.getAll(queryOptions)
			this.commit('setCommitNotes', commitNotes)
		},
		async getCommitNote(state, commit: string) {
			const note = await apiService.commitNotes.getOne(commit)
			this.commit('setCommitNotes', note)
		},
		async loadArtifactReceivers(state, queryOptions?: IPaginationOptions) {
			const artifactReceivers = await apiService.artifactReceiver.getAll(queryOptions)
			this.commit('setArtifactReceivers', artifactReceivers)
		}
	},
})
