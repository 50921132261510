const snackbarPlugin = {
	install: (vue: any, { store }: any): any => {
		vue.prototype.$snackbar = {
			showMessage: function (content: any) {
				store.commit('snackbar/showMessage',
					content,
					{ root: true }
				)
			}
		}
	}
}
export default snackbarPlugin
