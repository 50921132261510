<template>
	<md-dialog :md-active="$route.name == 'editNamespace'" v-if="namespace" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="namespace.name" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="repository">Repository</label>
						<md-input name="repository" id="repository" v-model="namespace.repository" />
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="close" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'

export default {
	name: 'Edit',
	data: () => ({
		sending: false,
		namespace: null
	}),
	mixins: [debounce],
	watch: {
		namespace: {
			deep: true,
			async handler() {
				await this.updateNamespace()
			}
		}
	},
	async created() {
		await this.$store.dispatch('loadNamespaces')
		this.namespace = this.$store.getters.getNamespaceById(this.$route.params.eid)
		this.updateNamespace = this.debounce(this.updateNamespace)
	},
	methods: {
		async updateNamespace() {
			try {
				await this.$apiService.namespace.update(this.namespace.id, {
					repository: this.namespace.repository
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async close() {
			try {
				await this.$store.dispatch('loadNamespaces', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>