<template>
	<div>
		<edit v-if="$route.name == 'editSkuTree'"/>
		<create v-if="$route.name == 'createSkuTree'"/>
		<md-toolbar md-elevation="0" class="md-transparent">
			<div class="md-toolbar-row">
				<div class="md-title" style="font-size: 1.7rem">SKU Tree</div>
			</div>
		</md-toolbar>
		<div class="md-layout">
			<div class="md-layout-item">
				<div class="md-layout md-gutter">
					<div class="md-layout-item" v-for="node in tree" :key="node.id">
						<tree-node :node="node" :depth="0">{{node.name}}</tree-node>
					</div>
				</div>
			</div>
			<div class="md-layout-item">
				<e-table title="Sku Trees" singularTitle="Sku Tree" :data="$store.getters.skuGroupParents" :columns="columns" @delete="deleteSkuGroupParent" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
			</div>
		</div>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Create from '@/components/SkuGroupParent/Create.vue'
import Edit from '@/components/SkuGroupParent/Edit.vue'
import TreeNode from '@/components/SkuGroupParent/TreeNode.vue'
import { getSkuTree } from '@/lib/helper'
export default {
	name: 'SkuTree',
	components: {
		'e-table': Table,
		'create': Create,
		'edit': Edit,
		'tree-node': TreeNode
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'skuGroupParent',
					label: 'Parent',
					render: (parent) => (parent? parent.name : '')
				}
			]
		}
	},
	computed: {
		tree() {
			return getSkuTree(this.$store.getters.skuGroups, this.$store.getters.skuGroupParents)
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadSkuGroupParents', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadSkuGroups')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteSkuGroupParent(id) {
			try {
				await this.$apiService.skuGroupParent.delete(id)
				await this.$store.dispatch('loadSkuGroupParents', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>