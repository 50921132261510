<template>
	<div>
		<form novalidate>
			<md-dialog :md-active="$route.name == 'createPipelineTrigger'" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('url')">
								<label for="url">Url</label>
								<md-input name="url" id="url" v-model="url" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.url.required">Url is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item md-size-70">
							<md-field :class="getValidationClass('token')">
								<label for="token">Token</label>
								<md-input name="token" id="token" v-model="token" type="password" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.token.required">Token is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field :class="getValidationClass('ref')">
								<label for="ref">Ref</label>
								<md-input name="ref" id="ref" v-model="ref" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.ref.required">Ref is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validatePipelineTrigger()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Create',
	mixins: [validationMixin],
	components: { 'dialog-link-table': DialogLinkTable},
	data: () => ({
		url: null,
		token: null,
		ref: 'master',
		description: null,
		sending: false,
	}),
	validations: {
		url: {
			required
		},
		token: {
			required
		},
		ref: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.url = null
			this.token = null
			this.ref = 'master'
			this.description = null
			this.sending = false
		},
		async createPipelineTrigger() {
			this.sending = true

			try {
				await this.$apiService.pipelineTrigger.create({url: this.url, token: this.token, ref: this.ref, description: this.description})
				this.$snackbar.showMessage('Pipeline Trigger saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validatePipelineTrigger() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createPipelineTrigger()
			}
		},
		async close() {
			this.sending = false
			this.clearForm()
			try {
				await this.$store.dispatch('loadPipelineTrigger', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
