<template>
	<div>
		<md-card class="md-elevation-0">
			<md-card-header>
				<div class="md-title">Test</div>
			</md-card-header>
			<md-card-content>
				<div class="md-layout md-gutter">
					<div class="md-layout-item md-size-25">
						<md-field>
							<label for="requestId">Request</label>
							<md-select v-model="requestId" name="requestId" id="requestId" @md-selected="requestSelected">
								<md-option v-for="i in $store.getters.requests" :key="i.id" :value="i.id">{{i.name}}</md-option>
							</md-select>
						</md-field>
					</div>
					<div class="md-layout-item md-size-25">
						<md-field>
							<label for="sWVariantId">SW Variant</label>
							<md-select v-model="sWVariantId" name="sWVariantId" id="sWVariantId">
								<md-option v-for="i in $store.getters.sWVariants" :key="i.id" :value="i.id">{{i.name}}</md-option>
							</md-select>
						</md-field>
					</div>
				</div>
				<div class="md-layout md-gutter">
					<div class="md-layout-item md-size-25">
						<md-field>
							<label for="deliveryVariantId">Delivery Variant</label>
							<md-select v-model="deliveryVariantId" name="deliveryVariantId" id="deliveryVariantId">
								<md-option v-for="i in $store.getters.deliveryVariants" :key="i.id" :value="i.id">{{i.name}}</md-option>
							</md-select>
						</md-field>
					</div>
					<div class="md-layout-item md-size-25">
						<md-field>
							<label for="hWConfigurationId">HW Configuration</label>
							<md-select v-model="hWConfigurationId" name="hWConfigurationId" id="hWConfigurationId">
								<md-option v-for="i in $store.getters.hWConfigurations" :key="i.id" :value="i.id">{{i.name}}</md-option>
							</md-select>
						</md-field>
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item md-size-50">
						<prism-editor style="padding-bottom: 0px; padding-right: 0px" class="condition-editor" v-model="code" :highlight="highlighter" line-numbers :readonly="requestId === null"/>
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item md-size-50" style="text-align: right">
						<md-button class="md-raised" :disabled="requestId === null || sWVariantId === null || deliveryVariantId === null || hWConfigurationId === null" @click="evaluate">Evaluate</md-button>
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item">
						<e-table title="Artifacts" singularTitle="Artifact" :data="artifacts" :columns="columns" :editable="false" :deletable="false" :readonly="true"/>
					</div>
				</div>
			</md-card-content>
		</md-card>
	</div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
// import 'prismjs/components/prism-typescript'
import 'prismjs/themes/prism-tomorrow.css'
import Table from '@/components/Common/Table'
import Vue from 'vue'

export default {
	name: 'Test',
	components: { 'prism-editor': PrismEditor, 'e-table': Table },
	data: function() {
		return {
			requestId: null,
			sWVariantId: null,
			deliveryVariantId: null,
			hWConfigurationId: null,
			code: '',
			showCondJsError: false,
			artifacts: [],
			columns: [{
				name: 'id',
				label: 'ID',
			}, {
				name: 'version',
				label: 'Version'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'created',
				label: 'Created',
				render: (val) => Vue.formatDate(val)
			}, {
				name: 'channel',
				label: 'Channel'
			}, {
				name: 'artifacts',
				label: 'File',
				render: (val) => val[0].file
			}, {
				name: 'tags',
				label: 'Tags',
				sortable: false
			}]
		}
	},
	async created() {
		try {
			const id = parseInt(this.$route.params.id)
			await this.$store.dispatch('loadRequests', id)
			await this.$store.dispatch('loadSWVariants', id)
			await this.$store.dispatch('loadDeliveryVariants', id)
			await this.$store.dispatch('loadHWConfigurations', id)
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		highlighter(code) {
			let res = highlight(code, languages.js)
			return res
		},
		requestSelected() {
			const request = this.$store.getters.requests.find((r) => r.id === this.requestId)
			this.code = request.requestFields.map((f) => `const ${f.name}: ${f.type} = `).join('\n') + '\n'
		},
		async evaluate() {
			try {
				const res = await this.$apiService.evaluateTest({
					requestId: this.requestId,
					sWVariantId: this.sWVariantId,
					deliveryVariantId: this.deliveryVariantId,
					hWConfigurationId: this.hWConfigurationId,
					code: this.code,
				})

				this.artifacts = res
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>
