<template>
	<div>
		<edit v-if="$route.name == 'editSWVariant'"/>
		<create v-if="$route.name == 'createSWVariant'"/>
		<confirm-delete v-if="$route.name == 'deleteSWVariant'" @onCancel="$router.back()" @onConfirm="deleteSWVariant" :itemName="deleteItemName" />

		<e-table title="SW Variants" singularTitle="SW Variant" :data="$store.getters.sWVariants" :filter="filter" :columns="columns" @delete="confirmDelete" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Edit from '@/components/SWVariant/Edit'
import Create from '@/components/SWVariant/Create'
import Table from '@/components/Common/Table'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'

export default {
	name: 'SWVariants',
	components: {
		'edit': Edit,
		'create': Create,
		'e-table': Table,
		'confirm-delete': ConfirmDelete
	},
	data: () => ({
		sortBy: 'id',
		sortOrder: 'DESC',
		deleteItemName: null,
		filter: {
			name: {
				value: null,
				name: 'Name',
				property: 'name'
			},
			tag: {
				value: [],
				name: 'Tag'
			}
		},
		columns: [
			{
				name: 'id',
				label: 'ID'
			}, {
				name: 'name',
				label: 'Name'
			}, {
				name: 'description',
				label: 'Description'
			}, {
				name: 'tags',
				label: 'Tags',
				sortable: false
			}
		]
	}),
	async created() {
		try {
			await this.$store.dispatch('loadSWVariants', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadManifestFields')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async confirmDelete(id) {
			const swv = await this.$store.getters.getSWVariantById(id)
			this.deleteItemName = `${swv.name}`
			this.$router.push({name: 'deleteSWVariant', params: {eid: id}})
		},
		async deleteSWVariant() {
			try {
				await this.$apiService.sWVariant.delete(this.$route.params.eid)
				await this.$store.dispatch('loadSWVariants', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.$router.back()
		}
	}
}
</script>
