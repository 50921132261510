<template>
	<div>
		<create v-if="$route.name == 'createConsumer'"/>
		<edit v-if="$route.name == 'editConsumer'"/>

		<e-table title="Consumers" singularTitle="Consumer" :data="$store.getters.consumers" :filter="filter" :columns="columns" @delete="deleteConsumer" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Create from '@/components/Consumer/Create.vue'
import Edit from '@/components/Consumer/Edit.vue'

export default {
	name: 'Consumer',
	components: {
		'e-table': Table,
		'create': Create,
		'edit': Edit
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'programmingTypes',
					label: 'Programming Types',
					render: (val) => val.map((t) => t.name).join(', ')
				}
			]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadConsumers', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			await this.$store.dispatch('loadProgrammingTypes')
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteConsumer(id) {
			try {
				await this.$apiService.consumer.delete(id)
				await this.$store.dispatch('loadConsumers', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>